import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { WISH_LIST_QUERY_KEY } from '../constants/queryKeys';
import { deleteWishList } from '../services/wishlist-services';

function useDeleteWishlistItem() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: deleteWishListItem, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => deleteWishList(updateData),
        onSuccess: (data) => {
            queryClient.setQueryData([WISH_LIST_QUERY_KEY], data?.data?.data)
            queryClient.invalidateQueries(WISH_LIST_QUERY_KEY)

            // navigate("/my-account")
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        deleteWishListItem, isLoading, error, data
    }
}

export default useDeleteWishlistItem