import React from "react";
import LeftContent from "./LeftContent";
import RightContent from "./RightContent";
import { Drawer } from "@mui/material";

function ContentBodySection({
  type,
  colorList,
  brandList,
  dispatch,
  pageSize,
  pageNumber,
  totalRecord,
  filters,
  isFilterModalOpen,
  setIsFilterModalOpen,
  priceMax,
}) {
  return (
    <div className="flex w-[95%] mx-auto justify-between my-12 max-md:my-0 max-md:mt-2 max-md:mb-8 items-start gap-10 max-md:gap-6">
      <Drawer
        open={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        anchor="left"
        style={{ zIndex: "20000" }}
        // className=""
      >
        <div className="w-[300px] px-4 py-4">
          <LeftContent
            type={type}
            colorList={colorList}
            brandList={brandList}
            filters={filters}
            dispatch={dispatch}
            pageSize={pageSize}
            pageNumber={pageNumber}
            totalRecord={totalRecord}
            onClose={() => setIsFilterModalOpen(false)}
            isFilterModalOpen={isFilterModalOpen}
            priceMax={priceMax}
          />
        </div>
      </Drawer>
      <div className={`w-1/4 max-sm:hidden text-left`}>
        <LeftContent
          type={type}
          colorList={colorList}
          brandList={brandList}
          filters={filters}
          dispatch={dispatch}
          pageSize={pageSize}
          pageNumber={pageNumber}
          totalRecord={totalRecord}
          priceMax={priceMax}
        />
      </div>
      <div className={`w-3/4 max-sm:w-full text-left`}>
        <RightContent
          type={type}
          pageSize={pageSize}
          pageNumber={pageNumber}
          dispatch={dispatch}
          totalRecord={totalRecord}
          filters={filters}
        />
      </div>
    </div>
  );
}

export default ContentBodySection;
