import { Form, Formik } from "formik";
import React from "react";
import GetInTouchForm from "../../commons/GetInTouchForm";
import Layout from "../../commons/Layout";

function BookConsultation() {
  return (
    <Layout>
      <div className="w-[65%] max-lg:w-[80%] max-md:w-[90%] mx-auto py-10">
        <div className="text-[60px] max-lg:text-[50px] max-md:text-[32px] font-[600]">
          Book Consultation
        </div>
        <div
          className="font-[500] text-[16px] max-lg:text-[14px] w-[68%] max-lg:w-[80%] max-md:w-[90%] mx-auto text-center text-[#667185]"
          style={{ textAlign: "center" }}
        >
          Elevate your style with a personalized furniture consultation.
          Experience unique craftsmanship tailored to you. Book now and let's
          curate the perfect pieces for your space!
        </div>
        <GetInTouchForm />
      </div>
    </Layout>
  );
}

export default BookConsultation;
