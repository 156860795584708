import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { } from '../constants/queryKeys';
import { addToBuyNow } from "../services/buynow-services";

function useAddToBuyNow(productSlug) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { mutateAsync: userAddToBuyNow, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => addToBuyNow(updateData),
        onSuccess: (data) => {
            navigate(`/buy-now/${productSlug}`)
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        userAddToBuyNow, isLoading, error, data
    }
}

export default useAddToBuyNow