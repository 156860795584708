import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { getFileTypesFriendly, isFileType } from "../helpers/fileUpload";

function CustomInputField({
  label,
  type = "text",
  name,
  placeholder = "",
  required = true,
  disabled = false,
  setFieldValue,
  setFieldError,
  fileTypes,
  inputRef,
  fileCallback,
}) {
  const PASSWORD_TEXT = "password";
  const [isHidePassword, setIsHidePassword] = useState(type === PASSWORD_TEXT);

  return (
    <div className="mb-5 text-left">
      <div className="mb-2 text-[14px] max-md:text-[12px] font-[500]">
        {label}
        {!required && <span className="text-[#FF9D21] ml-2">(Optional)</span>}
      </div>
      <div>
        <div className="border rounded-md outline-none py-3 px-3 flex items-center">
          {type === "file" ? (
            <input
              name={name}
              type="file"
              ref={inputRef}
              className="w-full text-[14px] max-md:text-[12px] font-[400] outline-none"
              onChange={(event) => {
                if (isFileType(fileTypes, event.currentTarget.files[0])) {
                  setFieldValue(name, event.currentTarget.files[0]);
                  setFieldValue("fileText", "input");
                  fileCallback("");
                } else {
                  inputRef.current.value = null;
                  setFieldValue(name, null);
                  setFieldValue("fileText", "");
                  fileCallback("invalid");
                }
              }}
            />
          ) : (
            <Field
              className="w-full text-[14px] font-[400] outline-none"
              type={
                type === PASSWORD_TEXT ? (isHidePassword ? type : "text") : type
              }
              name={name}
              placeholder={placeholder}
              disabled={disabled}
            />
          )}

          {type === PASSWORD_TEXT && (
            <>
              {isHidePassword && (
                <IoEyeOffOutline
                  className="text-[20px] cursor-pointer"
                  onClick={() => setIsHidePassword(false)}
                />
              )}
              {!isHidePassword && (
                <IoEyeOutline
                  className="text-[20px] cursor-pointer"
                  onClick={() => setIsHidePassword(true)}
                />
              )}
            </>
          )}
        </div>
        <ErrorMessage
          className="text-xs text-red-400"
          name={name}
          component="div"
        />
      </div>
    </div>
  );
}

export default CustomInputField;
