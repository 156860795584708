import React, { useState } from "react";
import { CiSaveDown2 } from "react-icons/ci";
import { IoHeartOutline, IoHeartSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import useAddToWishlist from "../hooks/useAddToWishlist";
import useDeleteWishlistItem from "../hooks/useDeleteWishlistItem";
import useUser from "../hooks/useUser";
import { saveIntendedRoute } from "../local_store/intendedRouteLocalStore";
import CustomSnackBar from "./CustomSnackBar";
import CustomThreeDotsSpinner from "./spinners/CustomThreeDotsSpinner";
import ViewAllButton from "./ViewAllButton";
import { useHistory, useLocation } from "react-router-dom";

function AddToWishlistButton({
  color,
  productId,
  quantity,
  chooseColor,
  useIcon = false,
  isAdded = false,
  product,
}) {
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { user } = useUser();
  const { deleteWishListItem } = useDeleteWishlistItem(user);
  const { addToUserWishlist } = useAddToWishlist();
  const [added, setAdded] = useState(isAdded);

  const navigate = useNavigate();
  const location = useLocation();

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const details = {
    productId,
    color,
    quantity,
    userId: user?.id,
    accessToken: user?.accessToken,
  };

  // Add to wishlist
  const handleAddToWishlist = () => {
    if (user) {
      let modifiedDetails = details;
      if (quantity < 1) {
        modifiedDetails = { ...modifiedDetails, quantity: 1 };
      }

      setIsAdding(true);
      addToUserWishlist(modifiedDetails)
        .then((res) => {
          setIsError(false);
          setSnackBarMessage("Successfully added to wishlist");
        })
        .catch((err) => {
          setIsError(true);
          setSnackBarMessage(err?.message);
        })
        .finally(() => {
          setOpenSnackBar(true);
          setIsAdding(false);
        });
    } else {
      setOpenSnackBar(true);
      setIsError(true);
      setSnackBarMessage("Please sign in before adding to wishlist");
      saveIntendedRoute(location.pathname);
      setTimeout(() => {
        navigate("/auth/login");
      }, 3000);
    }
  };

  const handleDeleteItem = () => {
    setIsDeleting(true);
    deleteWishListItem({
      userId: user?.id,
      accessToken: user?.accessToken,
      id: product?.Wishlists.find((item) => item.productId === product?.id)?.id,
    })
      .then(() => {
        setOpenSnackBar(true);
        setSnackBarMessage("Successfully removed from wish list");
        setIsError(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <div>
      {!useIcon && (
        <ViewAllButton
          text={isAdding ? "" : `Save item`}
          onClick={handleAddToWishlist}
          disabled={isAdding}
          className="border-gray-400 text-gray-400 hover:text-white hover:bg-gray-400 border"
          icon={<CiSaveDown2 />}
        >
          {isAdding && (
            <CustomThreeDotsSpinner height="20" width="20" color="white" />
          )}
        </ViewAllButton>
      )}

      {useIcon && (
        <div>
          <div className="cursor-pointer">
            {isAdded && (
              <IoHeartSharp
                onClick={() => {
                  setAdded(false);
                  handleDeleteItem();
                }}
                className={"text-orange-400"}
                style={{ fontSize: "2.5em" }}
              />
            )}
            {!isAdded && (
              <IoHeartOutline
                onClick={() => {
                  setAdded(true);
                  handleAddToWishlist();
                }}
                className={""}
                style={{ fontSize: "2.5em" }}
              />
            )}
          </div>
        </div>
      )}

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default AddToWishlistButton;
