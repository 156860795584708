import React, { useReducer } from 'react'
import CustomSnackBar from '../commons/CustomSnackBar';
import { removeMessageInfo, setMessageInfo } from '../reducers/infoDisplayReducer.js/actions';
import { infoDisplayReducer } from '../reducers/infoDisplayReducer.js';

function useCustomInfoDisplay(message = "", isError = false, display = false) {

    const initialState = {
        message: message,
        display: message ? true : display,
        isError: isError,
    };

    const [messageState, messageDispatch] = useReducer(
        infoDisplayReducer,
        initialState
    );

    const setInfoDetails = ({ message, isError }) => {
        messageDispatch(setMessageInfo({ message, isError }))
    }

    const setCloseSnackBar = () => {
        messageDispatch(removeMessageInfo());
    };

    const InfoDisplayComponent = <CustomSnackBar
        isOpen={messageState.display}
        onClose={() => setCloseSnackBar()}
        message={messageState.message}
        isError={messageState.isError}
    />

    return {
        messageState, setCloseSnackBar, setInfoDetails, InfoDisplayComponent
    }
}

export default useCustomInfoDisplay