import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../commons/Layout";
import ViewAllButton from "../../commons/ViewAllButton";
import { MY_ORDERS } from "../../constants/data";

function OrderConfirmation({}) {
  const params = useParams();
  const ref = params?.reference;

  const navigate = useNavigate();

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="flex items-center justify-center py-10">
        <div className="p-5 rounded-lg bg-white shadow-md w-[35%] max-lg:w-3/5 max-md:w-4/5 mx-auto">
          <div className="flex justify-center">
            <img src="/assets/success-icon.png" alt="" loading="lazy" />
          </div>
          <div className="text-[28px] max-lg:text-[24px] max-md:text-[20px] font-[600] mt-4 py-2">
            Order Confirmed
          </div>
          <div className="text-[#667185] text-[14px] max-md:text-[12px] font-[500]">
            <div className="mt-1 mb-4">Thank you for shopping on Wudify</div>
            <div>
              <span className="bg-gray-200 rounded-md py-2 px-2">
                Order Number: #{ref}
              </span>
            </div>
            <div className="my-5 w-[70%] max-md:w-full mx-auto text-left">
              Congratulation! Your order was successfully submitted. A
              confirmation email has been sent to you.
            </div>
            <div className="my-3 w-[70%] max-md:w-full mx-auto text-left">
              You will receive an update about your order when it has been
              shipped.
            </div>
            <div className="flex items-center justify-center my-5">
              <ViewAllButton
                onClick={() => {
                  navigate(`/my-account?tab=${MY_ORDERS}`);
                }}
                text="Got it!"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default OrderConfirmation;
