import React, { useState } from "react";
import SubcategoryMiniItemLoader from "../../commons/loaders/SubcategoryMiniItemLoader";
import SlidingArrows from "../../commons/SlidingArrows";
import SubCategoryMiniItem from "../../commons/SubCategoryMiniItem";
import { SET_FILTER_CATEGORIES_ARRAY } from "../../reducers/productFilterReducer/types";

function CategorySection({ categories, isLoading, filters, dispatch }) {
  const [index, setIndex] = useState(0);
  const THUMBNAIL_PER_PAGE = 6;

  const changeIndex = (step) => {
    if (step > 0) {
      increment();
    } else {
      decrement();
    }
  };

  const increment = () => {
    if (index < categories?.length - THUMBNAIL_PER_PAGE) {
      setIndex((prev) => ++prev);
    }
  };

  const decrement = () => {
    if (index > 0) {
      setIndex((prev) => --prev);
    }
  };
  return (
    <div
      className="w-[96%] mx-auto my-4 relative"
      style={{ overflow: "hidden" }}
    >
      <div
        className={`flex gap-4 overflow-x-auto max-lg:justify-start ${
          categories?.length < THUMBNAIL_PER_PAGE && "justify-center"
        }`}
        style={{
          width: "100%",
          display: "flex",
          overflow: "",
          transition: "all 1.5s ease",
          transform: `translateX(${index * -210}px)`,
        }}
      >
        {!isLoading && categories && categories?.length > 0 && (
          <>
            {categories?.map((category) => {
              return (
                <SubCategoryMiniItem
                  selected={filters.categoryFiltersArray.includes(
                    category?.id?.toString()
                  )}
                  itemsList={filters.categoryFiltersArray}
                  imageKey={category?.imagePath}
                  subCategory={category}
                  dispatch={dispatch}
                  actionType={SET_FILTER_CATEGORIES_ARRAY}
                />
              );
            })}
          </>
        )}

        {isLoading && (
          <>
            <SubcategoryMiniItemLoader />
          </>
        )}
      </div>
      {categories?.length > 6 && (
        <SlidingArrows
          topPositionClass="top-[5vh]"
          widthClass="w-full"
          leftPositionClass="left-[0]"
          showLeftArrow={index > 0}
          showRightArrow={index < categories?.length - THUMBNAIL_PER_PAGE}
          onLeftArrowClick={() => changeIndex(-1)}
          onRightArrowClick={() => changeIndex(+1)}
        />
      )}
    </div>
  );
}

export default CategorySection;
