import { useEffect } from "react";
import Layout from "../../commons/Layout";
import useLogout from "../../hooks/useLogout";
import useUser from "../../hooks/useUser";
import CustomBackdrop from "../../commons/CustomBackdrop";

function Logout() {
  const { user } = useUser();
  const { logUserOut } = useLogout();

  const logUserOutLocal = () =>
    logUserOut({ id: user?.id, email: user?.email, guid: user?.guid });

  useEffect(() => {
    logUserOutLocal();
  }, []);

  return (
    <Layout>
      <CustomBackdrop open={true} text={"You will be logged out shortly..."} />
    </Layout>
  );
}

export default Logout;
