import React, { useEffect, useState } from "react";
import Layout from "../../commons/Layout";
import CartItemLoader from "../../commons/loaders/CartItemLoader";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import Pagination from "../../commons/Pagination";
import useUser from "../../hooks/useUser";
import useUserCart from "../../hooks/useUserCart";
import CartDetails from "./CartDetails";
import CartSummary from "./CartSummary";

function Cart() {
  const PAGE_SIZE = 10;
  const { user } = useUser();
  const [cartUpdated, setCartUpdated] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { userCartItems, isLoading } = useUserCart(
    user,
    cartUpdated,
    PAGE_SIZE,
    currentPage
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [PAGE_SIZE]);

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="w-[95%] mx-auto pt-8">
        <PageBreadCrumb
          mainText={"Home"}
          subText={"Cart"}
          otherText={""}
          isActiveIndex={1}
        />
        <div className="flex flex-wrap justify-between w-full gap-12 mt-6 pb-20">
          <div className="w-[60%] max-lg:w-[80%] max-md:w-full text-left">
            {isLoading && (
              <>
                <CartItemLoader />
                <CartItemLoader />
                <CartItemLoader />
              </>
            )}
            {!isLoading && (
              <>
                <CartDetails
                  cartItemsData={userCartItems}
                  setCartUpdated={setCartUpdated}
                />

                <Pagination
                  totalCount={userCartItems?.totalRecords ?? 0}
                  pageSize={PAGE_SIZE}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                  className="mt-5"
                />
              </>
            )}
          </div>
          <div className="w-[35%] max-lg:w-[50%] max-md:w-full text-left">
            <CartSummary cartItemsData={userCartItems} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Cart;
