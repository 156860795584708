import React from "react";

function AccountPendingReviewsItemLoader() {
  return (
    <>
      <div className="flex justify-between mb-6">
        <div className="flex gap-4">
          <div className="h-[120px] w-[120px] bg-gray-100"></div>
          <div className="flex flex-col justify-around items-start gap-2 py-1">
            <p className="text-[#475367] bg-gray-100 h-[14px] w-[12em]"></p>
            <p className="text-[#475367] bg-gray-100 h-[14px] w-[12em]"></p>

            <p className="font-[600] h-[14px] bg-gray-100 w-[12em]"></p>

            <p className="bg-gray-100 h-[14px] w-[12em]"></p>
          </div>
        </div>
        <div className="flex flex-col items-end mt-3">
          <div className="w-[8em] py-3 rounded-full h-[2.5em] bg-gray-100 mb-5"></div>
        </div>
      </div>
    </>
  );
}

export default AccountPendingReviewsItemLoader;
