import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { CART_ITEM, CART_ITEM_QUERY_KEY } from '../constants/queryKeys';
import { fetchUserCartItems } from '../services/cart-services';

function useUserCart(user, cartUpdated, pageSize, pageNumber, computeShipping = false) {
    const navigate = useNavigate()
    const { data: userCartItems, isLoading, error } = useQuery({
        queryKey: [CART_ITEM_QUERY_KEY, user, cartUpdated, pageSize, pageNumber, computeShipping],
        queryFn: async () => {
            const result = await fetchUserCartItems(user, pageSize, pageNumber, computeShipping)
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        userCartItems, isLoading, error
    }
}

export default useUserCart