import React, { useState } from "react";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import useAddToBuyNow from "../hooks/useAddToBuyNow";
import useAddToCart from "../hooks/useAddToCart";
import useUser from "../hooks/useUser";
import CustomSnackBar from "./CustomSnackBar";
import CustomThreeDotsSpinner from "./spinners/CustomThreeDotsSpinner";
import ViewAllButton from "./ViewAllButton";

function BuyNowButton({ color, slug, productId, quantity, chooseColor }) {
  const [isAdding, setIsAdding] = useState(false);
  const { user } = useUser();
  const { userAddToBuyNow } = useAddToBuyNow(slug, productId);

  const navigate = useNavigate();

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const details = {
    productSlug: slug,
    productId,
    color: chooseColor ? color : "",
    quantity,
    userId: user?.id,
    accessToken: user?.accessToken,
  };

  // Add to buy now
  const handleAddToBuyNow = () => {
    if (user) {
      if (quantity < 1) {
        setOpenSnackBar(true);
        setIsError(true);
        setSnackBarMessage("Please add quantity");
        return;
      }
      if (chooseColor && !color) {
        setOpenSnackBar(true);
        setIsError(true);
        setSnackBarMessage("Please select a color");
        return;
      }

      setIsAdding(true);
      userAddToBuyNow(details)
        .then((res) => {
          setIsError(false);
          // setSnackBarMessage("Successfully added to cart");
        })
        .catch((err) => {
          setIsError(true);
          setSnackBarMessage(err?.message);
        })
        .finally(() => {
          setOpenSnackBar(true);
          setIsAdding(false);
        });
    } else {
      setOpenSnackBar(true);
      setIsError(true);
      setSnackBarMessage("Please sign in before buying item");
      setTimeout(() => {
        navigate("/auth/login");
      }, 3000);
    }
  };

  return (
    <div>
      <ViewAllButton
        text={isAdding ? "" : `Buy now`}
        onClick={handleAddToBuyNow}
        disabled={isAdding}
        className="text-white hover:text-[#FF9D21] hover:bg-white bg-[#FF9D21] border-1 hover:border-orange-400 border"
        icon={<BiPurchaseTagAlt />}
      >
        {isAdding && (
          <CustomThreeDotsSpinner height="20" width="20" color="white" />
        )}
      </ViewAllButton>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default BuyNowButton;
