export const fetchSubCategories = (subcategories, category_id) => {
    return subcategories?.filter(s => s.prod_category == category_id);
}

export const fetchTypes = (subcategories, category_id) => {
    return subcategories?.filter(s => s.prod_category == category_id);
}

export const fetchSubCategoriesBySlug = (subcategories, slug) => {
    return subcategories?.filter(s => s.prod_slug == slug);
}

export const fetchCategoryBySlug = (categories, slug) => {
    return categories?.find(category => category.slug?.toLowerCase() === slug?.toLowerCase())
}

export const fetchSubcategoryBySlug = (subcategories, slug) => {
    return subcategories?.find(subcategory => subcategory.slug?.toLowerCase() === slug?.toLowerCase())

}
export const fetchTypeBySlug = (types, slug) => {
    return types?.find(type => type.slug?.toLowerCase() === slug?.toLowerCase())

}
export const fetchCategoryById = (categories, id) => {
    return categories?.find(category => category.id?.toString().toLowerCase() === id?.toString().toLowerCase())

}

export const fetchProductBySlug = (products, slug) => {
    return products?.find(product => product.slug?.toLowerCase() === slug?.toLowerCase())

}

export const fetchProductCategoryFromSubCategoryId = (subcategories, subcategoryId) => {
    return subcategories?.find(subcategory => subcategory.id === subcategoryId)
}

export const alreadyAdded = (cartItems = [], productId) => {
    if (!cartItems) {
        return false;
    }

    for (const item of cartItems) {
        if (item.productId === productId) {
            return true
        }
    }

    return false
}
