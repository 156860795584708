import React from "react";
import { MdOutlineSearchOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function ProductSearchItemNotFound() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex items-center border bg-white gap-4 h-[3em]">
        <div className="text-center w-full gap-1">
          <p className="text-center font-[500] flex items-center justify-center gap-2">
            <MdOutlineSearchOff className="text-[20px]" />
            <span>No Records Found</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default ProductSearchItemNotFound;
