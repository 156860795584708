import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const fetchUserBuyNow = async (data) => {
  let url = "/buynows/user";
  if (data?.computeShipping) {
    url += "?computeShipping=" + data?.computeShipping;
  }
  return axiosInstance(url, "POST", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};
export const fetchUserBuyNowByReference = async (data) => {
  let url = "/buynows/reference/" + data?.reference;

  return axiosInstance(url, "POST", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const addToBuyNow = async (data) => {
  return axiosInstance(`/buynows`, "POST", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const updateBuyNow = async (data) => {
  return axiosInstance(`/buynows/${data.id}`, "PUT", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};
