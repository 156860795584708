import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

function CustomBackdrop({open, text}) {
  return (
    <div className="" style={{ zIndex: "3000000" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 300000 }}
        open={open}
      >
        <div className="flex flex-col gap-4 justify-center items-center">
          <CircularProgress color="inherit" />
          <p className="mt-5">{text}</p>
        </div>
      </Backdrop>
    </div>
  );
}

export default CustomBackdrop;
