import React from "react";
import { IoCartOutline, IoClose } from "react-icons/io5";
import ViewAllButton from "../../commons/ViewAllButton";
import CartModalItem from "./CartModalItem";
import CartPriceItem from "../../commons/CartPriceItem";
import useUser from "../../hooks/useUser";
import useUserCart from "../../hooks/useUserCart";
import CustomNoRecordFound from "../../commons/CustomNoRecordFound";
import { useNavigate } from "react-router-dom";

function CartModal({ item, open, onClose, userCartItems, isLoading }) {
  const navigate = useNavigate();
  return (
    <div
      className="p-4 max-md:p-2 shadow-md rounded-lg"
      style={{ display: open ? "block" : "none" }}
    >
      <div className="flex gap-3 max-md:gap-2 items-center justify-between">
        <div className="flex gap-3 max-md:gap-2 items-center">
          <div className="font-[600] text-[16px] max-md:text-[13px]">
            My Cart
          </div>
          <div className="w-[30px] h-[30px] max-md:w-[20px] max-md:h-[20px] rounded-full text-white bg-[#FF9D21] p-2 flex items-center justify-center">
            {userCartItems?.cartItems?.length ?? 0}
          </div>
        </div>
        <div>
          <IoClose onClick={onClose} className="cursor-pointer text-lg" />
        </div>
      </div>

      {!isLoading && userCartItems?.cartItems?.length === 0 && (
        <div className="mt-12">
          <CustomNoRecordFound
            buttonText={"Shop now"}
            label={"No item has been added to cart yet."}
            moreLabel="Shop now and add items to cart"
            onButtonClick={() => navigate("/")}
          >
            <IoCartOutline style={{ fontSize: "7.5em" }} />
          </CustomNoRecordFound>
        </div>
      )}

      {/*  */}
      {!isLoading && userCartItems?.cartItems?.length > 0 && (
        <>
          <div className="mt-8 flex flex-col gap-4 max-md:gap-2">
            {userCartItems?.cartItems?.map((item) => {
              return <CartModalItem item={item} />;
            })}
          </div>

          <div className="mt-6 mb-4">
            <CartPriceItem
              title="Sub Total"
              price={`N${userCartItems?.subTotalPrice}`}
            />
          </div>
          <hr />

          <div className="mt-4">
            <a onClick={onClose} href="/my-cart">
              <ViewAllButton
                className="hover:text-white hover:border-white w-full text-center py-3 items-center hover:bg-[#FF9D21] text-[#FF9D21] bg-white border border-orange-400"
                text="View Cart"
              />
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default CartModal;
