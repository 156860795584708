import React from "react";

function ViewAllButton({
  text = "View All",
  children,
  onClick,
  disabled = false,
  className = "hover:text-white hover:bg-black border ",
  icon = null,
}) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`flex items-center justify-center gap-2 border-1 font-[600] text-[14px] max-lg:text-[12px] max-md:text-[10px] py-1.5 px-3.5 max-md:px-3 max-md:py-2 rounded-full ${className}`}
    >
      {icon}
      <p className="max-md:text-[12px]">{text}</p>
      {children}
    </button>
  );
}

export default ViewAllButton;
