import React from "react";

function CustomDiscountDisplay({ discountPrice, className }) {
  return (
    <>
      {discountPrice > 0 && (
        <div className={className}>
          <span className="text-[12px] max-md:text-[10px] text-white font-[500] p-2 bg-red-800">
            {discountPrice}% OFF
          </span>
        </div>
      )}
    </>
  );
}

export default CustomDiscountDisplay;
