import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { ADDRESS_QUERY_KEY } from '../constants/queryKeys';
import { fetchUserAddresses } from "../services/address-services";

function useUserAddress(user, selectedAddress, isDefault) {
    const navigate = useNavigate()
    const { data: userAddresses, isLoading, error } = useQuery({
        queryKey: [ADDRESS_QUERY_KEY, selectedAddress, isDefault],
        queryFn: async () => {
            const result = await fetchUserAddresses(user)
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        userAddresses, isLoading, error
    }
}

export default useUserAddress