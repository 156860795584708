import React from "react";
import { getFileUrl } from "../helpers/extractors";
import CustomDiscountDisplay from "./CustomDiscountDisplay";
import CustomOutOfStockDisplay from "./CustomOutOfStockDisplay";
import CustomPriceDisplay from "./CustomPriceDisplay";

function ProductItemMini({ product }) {
  return (
    <a className="my-4 max-md:my-2" href={`/products/${product?.slug}`}>
      <div className="cursor-pointer hover:opacity-70 relative">
        <div
          className="h-[319px] max-lg:h-[240px] max-md:h-[160px] max-sm:h-[120px]"
          style={{ overflow: "hidden" }}
        >
          <img
            src={getFileUrl(product?.imageOne)}
            className="h-full w-full rounded-md object-cover"
            alt=""
            loading="lazy"
          />
        </div>
        <div className="absolute top-0 max-sm:-top-[5px]">
          <CustomDiscountDisplay discountPrice={product?.discountPrice} />
        </div>
        <div className="absolute -top-[10px] right-0">
          <CustomOutOfStockDisplay quantity={product?.quantity} />
        </div>
        <div className="px-1">
          <div className="flex flex-wrap gap-1 items-center justify-between font-[500] text-[16px] max-lg:text-[14px] max-md:text-[12px]">
            <div>{product?.name}</div>
            <CustomPriceDisplay price={product?.priceAfterDiscount} />
          </div>
          <div className="flex flex-wrap gap-1 justify-between mt-1 font-[500] text-[12px]">
            <div className="overflow-x-auto">Color: {product?.colors}</div>
            <div
              className="text-gray-500"
              style={{ textDecoration: "line-through", opacity: "0.6" }}
            >
              {product?.discountPrice && (
                <CustomPriceDisplay price={product?.price} />
              )}
            </div>
          </div>
          <div className="mt-1 flex max-sm:flex-col max-sm:items-start gap-1 justify-between items-center">
            <a className="" href={`/product-types/${product?.Type?.slug}`}>
              <div className="font-[500] text-left">
                Type: {product?.Type?.name}
              </div>
            </a>
            <div className="">Brand: {product?.Vendor?.businessName}</div>
          </div>
        </div>
      </div>
    </a>
  );
}

export default ProductItemMini;
