import React from "react";
import RoundedMdButton from "./RoundedMdButton";

function CustomNoRecordFound({
  children,
  label,
  buttonText,
  onButtonClick,
  showButton = true,
  moreLabel = "",
}) {
  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-4">
        <div>{children}</div>
        <div className="text-center">
          <div className="font-[500] text-[14px] max-md:text-[12px]">
            {label}
          </div>
          <div className="font-[500] text-[14px] max-md:text-[12px] opacity-50">
            {moreLabel}
          </div>
        </div>
        {showButton && (
          <RoundedMdButton
            onClick={onButtonClick}
            className="py-1 border hover:bg-gray-100"
            label={buttonText}
          ></RoundedMdButton>
        )}
      </div>
    </div>
  );
}

export default CustomNoRecordFound;
