import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { ADDRESS_BOOK } from '../constants/data';
import { ADDRESS_QUERY_KEY } from '../constants/queryKeys';
import { newContactRequest } from "../services/contact-requests-services";

function useContactRequest() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: sendContactRequest, isLoading, error, data } = useMutation({
        mutationFn: (contactRequestDetails) => newContactRequest(contactRequestDetails),
        onSuccess: (data) => {
            return data.data
            
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        sendContactRequest, isLoading, error, data
    }
}

export default useContactRequest