import React from "react";
import BlogItem from "../../commons/BlogItem";
import BlogItemLoader from "../../commons/BlogItemLoader";
import Layout from "../../commons/Layout";
// import { decor_tips_blog_posts } from "../../mock_data/decor_tips_blog_posts";
import useDecorTips from "../../hooks/useDecorTips";

function DecorTips() {
  const { decorTips, isLoading } = useDecorTips();

  return (
    <Layout>
      <div className="flex max-sm:flex-col justify-between bg-[#FFFAF8]">
        <div className="w-[60%] max-sm:w-full text-left mx-auto py-24 max-lg:py-18 max-md:py-12">
          <div className="w-[70%] max-lg:w-[80%] max-md:w-[95%] mx-auto">
            <h4 className="text-[#667185] text-[16px] font-[500]">
              Decor Tips
            </h4>
            <div className="text-[54px] max-lg:text-[44px] max-md:text-[30px] font-[600]">
              Unveil the art of stylish living
            </div>
          </div>
        </div>
        <div className="w-[40%] max-sm:w-full min-h-full flex text-left">
          <div className="h-full">
            <div className="h-1/2">
              <img
                src="/assets/decor-tips-image-1.png"
                alt=""
                style={{ objectFit: "cover" }}
                className="h-full"
              />
            </div>
            <div className="h-1/2">
              <img
                src="/assets/decor-tips-image-2.png"
                alt=""
                style={{ objectFit: "cover" }}
                className="h-full"
              />
            </div>
          </div>
          <div className="h-full">
            <img
              src="/assets/decor-tips-image-3.png"
              alt=""
              style={{ objectFit: "cover" }}
              className="h-full"
            />
          </div>
        </div>
      </div>

      {/*  */}
      <div className="w-[82%] max-md:w-[95%] mx-auto">
        <h3 className="text-[#667185] text-[20px] max-lg:text-[17px] max-md:text-[13px] font-[500] text-left mt-24 mb-12 max-md:mt-12 max-md:mb-6 mb-12">
          Browse all decor tips
        </h3>

        {isLoading && (
          <>
            <BlogItemLoader />
            <div className="my-12"></div>
            <BlogItemLoader reversed />
          </>
        )}

        {decorTips?.map((blog, index) => {
          return (
            <>
              <div className=""></div>
              <BlogItem
                blog={blog}
                reversed={index % 2 !== 0}
                link={`/inspiration/decor-tips/${blog?.slug}`}
              />
              <div className="mb-28 max-md:mb-14"></div>
            </>
          );
        })}
      </div>
    </Layout>
  );
}

export default DecorTips;
