import React, { useState } from "react";
import ImageBuilder from "../../commons/ImageBuilder";
import Layout from "../../commons/Layout";
import { product_sub_categories } from "../../mock_data/product_categories";
import useProductSubcategory from "../../hooks/useProductSubcategory";
import { NEW_TO_OLD } from "../../constants/texts";
import CustomNoRecordFound from "../../commons/CustomNoRecordFound";
import NotFoundSVG from "../../commons/NotFoundSVG";
import ProductItemMiniLoaders from "../../commons/loaders/ProductItemMiniLoaders";

function BespokeGallery() {
  const [selectedTabIndex, setSelectedTabIndex] = useState("living-room");

  const { productSubcategory, isLoading } = useProductSubcategory(
    selectedTabIndex,
    10,
    1,
    [],
    [],
    [],
    [0, 100],
    NEW_TO_OLD
  );

  //    To track images being displayed
  let t = 0;
  //   {
  //     id: 1,
  //     path: "/assets/bespoke_gallery_1.png",
  //   },
  //   {
  //     id: 2,
  //     path: "/assets/bespoke_gallery_2.png",
  //   },
  //   {
  //     id: 3,
  //     path: "/assets/bespoke_gallery_3.png",
  //   },
  //   {
  //     id: 4,
  //     path: "/assets/bespoke_gallery_4.png",
  //   },
  //   {
  //     id: 5,
  //     path: "/assets/bespoke_gallery_5.png",
  //   },
  //   {
  //     id: 6,
  //     path: "/assets/bespoke_gallery_6.png",
  //   },
  //   {
  //     id: 7,
  //     path: "/assets/bespoke_gallery_7.png",
  //   },
  //   {
  //     id: 8,
  //     path: "/assets/bespoke_gallery_8.png",
  //   },
  //   {
  //     id: 9,
  //     path: "/assets/bespoke_gallery_9.png",
  //   },
  //   {
  //     id: 10,
  //     path: "/assets/bespoke_gallery_10.png",
  //   },
  //   {
  //     id: 11,
  //     path: "/assets/bespoke_gallery_11.png",
  //   },
  //   {
  //     id: 12,
  //     path: "/assets/bespoke_gallery_12.png",
  //   },
  //   {
  //     id: 13,
  //     path: "/assets/bespoke_gallery_13.png",
  //   },
  //   {
  //     id: 14,
  //     path: "/assets/bespoke_gallery_14.png",
  //   },
  //   {
  //     id: 15,
  //     path: "/assets/bespoke_gallery_15.png",
  //   },
  // ];

  return (
    <Layout>
      <div className="w-[65%] max-lg:w-[80%] max-md:w-[95%] mx-auto px-5 py-10">
        <div className="text-[60px] max-lg:text-[40px] max-[24px] font-[600]">
          Bespoke Gallery
        </div>
        <div
          className="font-[500] text-[16px] w-[68%] max-lg:w-[80%] max-md:w-[95%] mx-auto text-center text-[#667185]"
          style={{ textAlign: "center" }}
        >
          Based on the classical designs and is made from a single-mould poly
          carbonate plastic. The chairs have become extremely popular in recent
          years and are perfect fit for relaxed and more formal space.
        </div>
      </div>

      <div className="w-[95%] mx-auto mt-12 max-lg:mt-6">
        {/* tabs */}
        <div className="flex w-full max-md:gap-4 justify-between overflow-x-auto">
          {product_sub_categories.map((subcategory, index) => {
            return (
              <div
                key={index}
                className={`w-full py-3 font-[500] text-[14px] ${
                  selectedTabIndex === subcategory.slug
                    ? "border-[#F56630] text-[#F56630]"
                    : ""
                } border-1 border-b hover:border-[#F56630] hover:text-[#F56630] cursor-pointer whitespace-nowrap`}
                onClick={() => setSelectedTabIndex(subcategory.slug)}
              >
                {subcategory.name}
              </div>
            );
          })}
        </div>

        <div className="mt-4">
          {!isLoading && productSubcategory?.record?.Products?.length === 0 && (
            <div className="flex items-center justify-center h-[70vh] max-md:h-[30vh]">
              <CustomNoRecordFound
                showButton={false}
                buttonText={"Got it"}
                label={"No Records Found"}
              >
                <NotFoundSVG />
              </CustomNoRecordFound>
            </div>
          )}

          {isLoading && (
            <>
              <ProductItemMiniLoaders number={3} />
            </>
          )}

          {!isLoading && productSubcategory?.record?.Products?.length > 0 && (
            <div className="">
              {productSubcategory?.record?.Products?.map(
                ({ id, imageOne }) => ({
                  id,
                  path: imageOne,
                })
              )?.map((image, index) => {
                let numberOfImages = index % 2 === 0 ? 3 : 2;
                let m = t;
                t = t + numberOfImages;
                return (
                  <ImageBuilder
                    numberOfImages={numberOfImages}
                    imageList={[
                      ...productSubcategory?.record?.Products?.map(
                        ({ id, imageOne }) => ({ id, path: imageOne })
                      )?.slice(m, m + numberOfImages),
                    ]}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default BespokeGallery;
