import React from "react";
import { getFileUrl } from "../helpers/extractors";

function ImageBuilder({ numberOfImages = 3, imageList = [] }) {
  return (
    <div className={`flex gap-3 max-md:gap-1 mb-3 max-md:mb-1`}>
      {imageList.map((image) => {
        return (
          <div
            style={{ width: numberOfImages == 3 ? "33.3333%" : "50%" }}
            className={`bg-red-100`}
          >
            <img
              className={"w-full h-full object-cover"}
              src={getFileUrl(image?.path)}
              alt="subcategory image"
            />
          </div>
        );
      })}
    </div>
  );
}

export default ImageBuilder;
