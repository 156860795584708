import { addPaginationUrlQuery } from "../helpers/extractors";
import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const fetchUserCartItems = async (
  data,
  pageSize,
  pageNumber,
  computeShipping
) => {
  let url = "/carts";

  url = addPaginationUrlQuery(url, pageSize, pageNumber);
  let querySymbol = "&";
  if (url === "/carts") {
    // this means no query parameter added
    querySymbol = "?";
  }
  url += querySymbol + "computeShipping=" + computeShipping;
  
  return axiosInstance(url, "GET", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const addToCart = async (data) => {
  return axiosInstance(`/carts`, "POST", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const updateCart = async (data) => {
  return axiosInstance(`/carts/${data.id}`, "PUT", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const deleteCart = async (data) => {
  return axiosInstance(`/carts/${data.id}`, "DELETE", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};
