import React, { useState } from "react";
import { PiShoppingCartSimple } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import useAddToCart from "../hooks/useAddToCart";
import useUser from "../hooks/useUser";
import { saveIntendedRoute } from "../local_store/intendedRouteLocalStore";
import CustomSnackBar from "./CustomSnackBar";
import CustomThreeDotsSpinner from "./spinners/CustomThreeDotsSpinner";
import ViewAllButton from "./ViewAllButton";
import { useHistory, useLocation } from "react-router-dom";

function AddToCartButton({ color, productId, quantity, chooseColor }) {
  const [isAdding, setIsAdding] = useState(false);
  const { user } = useUser();
  const { addToUserCart } = useAddToCart();

  const navigate = useNavigate();
  const location = useLocation();

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const details = {
    productId,
    color: chooseColor ? color : "",
    quantity,
    userId: user?.id,
    accessToken: user?.accessToken,
  };

  // Add to cart
  const handleAddToCart = () => {
    if (user) {
      if (quantity < 1) {
        setOpenSnackBar(true);
        setIsError(true);
        setSnackBarMessage("Please add quantity");
        return;
      }
      if (chooseColor && !color) {
        setOpenSnackBar(true);
        setIsError(true);
        setSnackBarMessage("Please select a color");
        return;
      }

      setIsAdding(true);
      addToUserCart(details)
        .then((res) => {
          setIsError(false);
          setSnackBarMessage("Successfully added to cart");
        })
        .catch((err) => {
          setIsError(true);
          setSnackBarMessage(err?.message);
        })
        .finally(() => {
          setOpenSnackBar(true);
          setIsAdding(false);
        });
    } else {
      saveIntendedRoute(location.pathname);
      setOpenSnackBar(true);
      setIsError(true);
      setSnackBarMessage("Please sign in before adding to cart");
      setTimeout(() => {
        navigate("/auth/login");
      }, 3000);
    }
  };

  return (
    <div>
      <ViewAllButton
        text={isAdding ? "" : `Add to cart`}
        onClick={handleAddToCart}
        disabled={isAdding}
        className="border-orange-400 text-[#FF9D21] hover:text-white hover:bg-[#FF9D21] border max-md:!py-1"
        icon={<PiShoppingCartSimple />}
      >
        {isAdding && (
          <CustomThreeDotsSpinner height="20" width="20" color="white" />
        )}
      </ViewAllButton>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default AddToCartButton;
