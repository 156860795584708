import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { BUY_NOW_ITEM_QUERY_KEY } from '../constants/queryKeys';
import { updateBuyNow } from "../services/buynow-services";

function useUpdateBuyNowItem() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: updateBuyNowItem, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => updateBuyNow(updateData),
        onSuccess: (data) => {
            queryClient.invalidateQueries(BUY_NOW_ITEM_QUERY_KEY);            
            // navigate("/my-account")
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        updateBuyNowItem, isLoading, error, data
    }
}

export default useUpdateBuyNowItem