import React, { useState } from "react";
import CardTitle from "../../commons/CardTitle";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CustomInputField from "../../commons/CustomInputField";
import CustomTextField from "../../commons/CustomTextField";
import FullRoundedButton from "../../commons/FullRoundedButton";
import ReviewStar from "../../commons/ReviewStar";
import useAddReview from "../../hooks/useAddReview";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import useUser from "../../hooks/useUser";
import { getFileUrl } from "./../../helpers/extractors";

function AccountRatingReview({
  onBackClick,
  orderItem,
  setIsError,
  setSnackBarMessage,
  setOpenSnackBar,
}) {
  const [rateValue, setRateValue] = useState(0);

  const { user } = useUser();
  const { addToReview } = useAddReview();

  return (
    <div className="shadow-md rounded-lg w-[70%] bg-white p-6 text-left">
      <div className="flex justify-between items-start">
        <div className="">
          <CardTitle title="Rate this product" />
        </div>
        <div
          onClick={onBackClick}
          className=" flex gap-2 text-[16px] font-[600] items-center text-[#318EC9] cursor-pointer"
        >
          <div>
            <MdOutlineArrowBackIos />
          </div>
          <div>Back</div>
        </div>
      </div>

      <div className="flex gap-4 items-center">
        <div className="h-[120px] w-[120px]">
          <img
            src={getFileUrl(orderItem?.Product?.imageOne)}
            className="h-full w-full"
            alt=""
            style={{ objectFit: "cover" }}
            loading="lazy"
          />
        </div>
        <div className="flex flex-col justify-start items-start gap-2 py-1">
          <p className="text-[18px] font-[600]">{orderItem?.Product?.name}</p>
          <div className="md:mb-2">
            <ReviewStar
              value={rateValue}
              adjustable={true}
              onChange={setRateValue}
            />
          </div>
        </div>
      </div>

      {/* Form */}
      <div className="mt-8">
        <Formik
          initialValues={{
            title: "",
            comment: "",
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            addToReview({
              ...values,
              orderId: orderItem?.id,
              productId: orderItem?.Product?.id,
              rating: rateValue,
              userId: user?.id,
            })
              .then((res) => {
                setIsError(false);
                resetForm();
                onBackClick();
              })
              .catch((err) => {
                setSnackBarMessage(err?.message);
                setIsError(true);
                setOpenSnackBar(true);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
          validate={(values) => {
            const errors = {};
            if (!values.title) {
              errors.title = "Review title is required";
            }
            if (values.title.length < 2) {
              errors.title =
                "Review title should contain at least two(2) characters";
            }
            if (!values.comment) {
              errors.comment = "Detailed review is required";
            }
            if (values.comment.length < 10) {
              errors.comment =
                "Detailed review should contain at least ten(10) characters";
            }
            return errors;
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="">
                <CustomInputField
                  label={"Review title"}
                  type="text"
                  placeholder="Please enter a title for your review"
                  name="title"
                  disabled={isSubmitting}
                />
              </div>
              <CustomTextField
                label={"Detailed review"}
                type="text"
                placeholder="Please enter your detailed review"
                name="comment"
                disabled={isSubmitting}
              />

              {/* Submit button */}
              <FullRoundedButton
                label={isSubmitting ? "" : "Submit your review"}
                disabled={isSubmitting}
                className={"text-white bg-[#FF9D21] hover:bg-[#FF9D21] mt-3"}
                type="submit"
              >
                {isSubmitting && (
                  <CustomThreeDotsSpinner
                    height="20"
                    width="20"
                    color="white"
                  />
                )}
              </FullRoundedButton>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mb-10"></div>
    </div>
  );
}

export default AccountRatingReview;
