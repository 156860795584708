import React, { useEffect, useState } from "react";
import CardTitle from "../../commons/CardTitle";
import CustomNoRecordFound from "../../commons/CustomNoRecordFound";
import CustomSnackBar from "../../commons/CustomSnackBar";
import Pagination from "../../commons/Pagination";
import AccountSavedItem from "./AccountSavedItem";
import { CiSaveDown2 } from "react-icons/ci";
import CartItemLoader from "../../commons/loaders/CartItemLoader";
import useAddToCart from "../../hooks/useAddToCart";
import useUserWishList from "../../hooks/useUserWishlist";
import useUser from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";

function AccountSavedItems({}) {
  const navigate = useNavigate();

  const PAGE_SIZE = 1;

  const [currentPage, setCurrentPage] = useState(1);
  const [updated, setUpdated] = useState(0);

  const { user } = useUser();
  const { addToUserCart, isLoading: isAddingToCart } = useAddToCart();
  const [isAdding, setIsAdding] = useState(false);
  const { userWishListItems, isLoading } = useUserWishList(
    user,
    updated,
    PAGE_SIZE,
    currentPage
  );

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  // Add to cart
  const handleAddToCart = (details) => {
    setIsAdding(true);
    addToUserCart(details)
      .then((res) => {
        setIsError(false);
        setSnackBarMessage("Successfully added to cart");
        setUpdated((prev) => ++prev);
      })
      .catch((err) => {
        setIsError(true);
        setSnackBarMessage(err?.message);
      })
      .finally(() => {
        setOpenSnackBar(true);
        setIsAdding(false);
      });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [PAGE_SIZE]);

  return (
    <div className="shadow-md rounded-lg w-[70%] max-lg:w-full bg-white p-6 text-left">
      <CardTitle title="My Saved Items" />

      {userWishListItems?.wishlistItems?.length === 0 && (
        <div className="mt-12">
          <CustomNoRecordFound
            buttonText={"Shop now"}
            label={"No item has been added to wishlist yet."}
            moreLabel={"Shop now and add items to wish list"}
            onButtonClick={() => navigate("/")}
          >
            <CiSaveDown2 style={{ fontSize: "15em" }} />
          </CustomNoRecordFound>
        </div>
      )}
      {isLoading && (
        <>
          <CartItemLoader />
          <div className="my-4"></div>
          <CartItemLoader />
        </>
      )}

      <div className="mb-10">
        {userWishListItems?.wishlistItems?.map((wishlist) => {
          return (
            <AccountSavedItem
              wishlist={wishlist}
              setIsError={setIsError}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              addToCart={handleAddToCart}
              isLoading={isAdding}
            />
          );
        })}
      </div>
      <Pagination
        totalCount={userWishListItems?.totalRecords ?? 0}
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        className="mt-5"
      />

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default AccountSavedItems;
