import React from "react";
import ViewAllButton from "../../commons/ViewAllButton";
import CartPriceItem from "../../commons/CartPriceItem";
import CustomShippingFeeNotAdded from "../../commons/CustomShippingFeeNotAdded";

function CartSummary({ cartItemsData, isLoading }) {
  return (
    <>
      {!isLoading && (
        <div className="p-5 rounded-lg bg-white shadow-md h-full">
          <div className="mb-8">
            <div className="font-[600] text-[20px] max-lg:text-[17px] max-md:text-[14px]">
              Cart Summary
            </div>
          </div>

          <hr />

          <CartPriceItem
            title="Sub Total"
            price={`${cartItemsData?.subTotalPrice ?? 0}`}
          />

          <hr />

          <CustomShippingFeeNotAdded />
          <hr />

          {/* <CartPriceItem
            title="Total"
            price={`${cartItemsData?.totalPrice ?? 0}`}
          /> */}

          <div className="mt-8">
            <a href="/my-cart/checkout">
              <ViewAllButton
                className="hover:text-white w-full text-center py-3 items-center hover:bg-[#FF9D21] text-[#FF9D21] bg-white border border-orange-400"
                text="Checkout"
              />
            </a>
          </div>
        </div>
      )}
    </>
  );
}

export default CartSummary;
