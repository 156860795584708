import "./App.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import Home from "./pages/home/Home";
import Category from "./pages/product_category/Category";
import SubCategory from "./pages/product_sub_category/SubCategory";
import Type from "./pages/product_type/Type";
import Sale from "./pages/sales/Sale";
import Product from "./pages/product/Product";
import Cart from "./pages/cart/Cart";
import BuyNow from "./pages/buy_now/BuyNow";
import OrderConfirmation from "./pages/order/OrderConfirmation";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import Signup from "./pages/auth/Signup";
import ResetPassword from "./pages/auth/ResetPassword";
import Checkout from "./pages/checkout/Checkout";
import BuyNowCheckout from "./pages/buy_now_checkout/BuyNowCheckout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Account from "./pages/account/Account";
import BookConsultation from "./pages/book_consultation/BookConsultation";
import Faq from "./pages/faq/Faq";
import PrivacyPolicy from "./pages/privacy_policy/PrivacyPolicy";
import ShippingReturns from "./pages/shipping_returns/ShippingReturns";
import ContactUs from "./pages/contact_us/ContactUs";
import Bespoke from "./pages/bespoke/Bespoke";
import DecorTips from "./pages/inspiration/DecorTips";
import FurnitureGuide from "./pages/inspiration/FurnitureGuide";
import DecorTipDetails from "./pages/inspiration/DecorTipDetails";
import FurnitureGuideDetails from "./pages/inspiration/FurnitureGuideDetails";
import BespokeGallery from "./pages/bespoke/BespokeGallery";
import AboutUs from "./pages/about_us/AboutUs";
import useUser from "./hooks/useUser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect, useLayoutEffect } from "react";
import { getIntendedRoute } from "./local_store/intendedRouteLocalStore";
import ConsultationBookConfirmation from "./pages/book_consultation/ConsultationBookConfirmation";
import ContactConfirmation from "./pages/contact_us/ContactConfirmation";
import ResetPasswordToken from "./pages/auth/ResetPasswordToken";

function App() {
  // Create a client
  const queryClient = new QueryClient();

  const ProtectedRoute = ({ children }) => {
    const { user } = useUser();
    const location = useLocation();

    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    if (!user) {
      return <Navigate to="/auth/login" replace />;
    }

    return <>{children}</>;
  };

  const AuthenticatedRoute = ({ children }) => {
    const { user } = useUser();
    const location = useLocation();

    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    if (!user) {
      return <>{children}</>;
    }

    const intendedRoute = getIntendedRoute();
    return <Navigate to={intendedRoute} replace />;
  };

  /// Zoho sale IQ script
  useEffect(() => {
    // Create the first inline script element
    const zohoScript = document.createElement("script");
    zohoScript.text = `window.$zoho=window.$zoho || {}; $zoho.salesiq=$zoho.salesiq||{ready:function(){}};`;
    document.body.appendChild(zohoScript);

    // Create the second script element with the Zoho SalesIQ widget URL
    const script = document.createElement("script");
    script.id = "zsiqscript";
    script.src =
      "https://salesiq.zohopublic.com/widget?wc=siq1eadd48df997eef665b439afa82c868696429f6768a1a15a26d692f6e7fddf16";
    script.defer = true;
    document.body.appendChild(script);

    // Clean up the scripts when the component unmounts
    return () => {
      document.body.removeChild(zohoScript);
      document.body.removeChild(script);
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/auth/login",
      element: (
        <AuthenticatedRoute>
          <Login />
        </AuthenticatedRoute>
      ),
    },
    {
      path: "/auth/logout",
      element: (
        <ProtectedRoute>
          <Logout />
        </ProtectedRoute>
      ),
    },
    {
      path: "/auth/signup",
      element: (
        <AuthenticatedRoute>
          <Signup />
        </AuthenticatedRoute>
      ),
    },
    {
      path: "/auth/forgot-password",
      element: <ResetPassword />,
    },
    {
      path: "/auth/reset-password/:token",
      element: <ResetPasswordToken />,
    },
    {
      path: "/my-account",
      element: (
        <>
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/my-cart",
      element: (
        <>
          <ProtectedRoute>
            <Cart />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/buy-now/checkout/:slug",
      element: (
        <>
          <ProtectedRoute>
            <BuyNowCheckout />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/buy-now/:slug",
      element: (
        <>
          <ProtectedRoute>
            <BuyNow />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/order-confirmation/:reference",
      element: (
        <>
          <ProtectedRoute>
            <OrderConfirmation />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/book-consultation/:requestNumber",
      element: (
        <>
          <ProtectedRoute>
            <ConsultationBookConfirmation />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/my-cart/checkout",
      element: (
        <>
          <ProtectedRoute>
            <Checkout />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/categories/:slug",
      element: <Category />,
    },
    {
      path: "/sub-categories/:slug",
      element: <SubCategory />,
    },
    {
      path: "/product-types/:slug",
      element: <Type />,
    },
    {
      path: "/sales",
      element: <Sale />,
    },
    {
      path: "/products/:slug",
      element: <Product />,
    },
    {
      path: "/book-consultation",
      element: <BookConsultation />,
    },
    {
      path: "/book-consultation/:requestNumber",
      element: (
        <>
          <ConsultationBookConfirmation />
        </>
      ),
    },
    {
      path: "/bespoke-services",
      element: <Bespoke />,
    },
    {
      path: "/bespoke-gallery",
      element: <BespokeGallery />,
    },
    {
      path: "/inspiration/decor-tips",
      element: <DecorTips />,
    },
    {
      path: "/inspiration/decor-tips/:slug",
      element: <DecorTipDetails />,
    },
    {
      path: "/inspiration/furniture-guides",
      element: <FurnitureGuide />,
    },
    {
      path: "/inspiration/furniture-guides/:slug",
      element: <FurnitureGuideDetails />,
    },
    {
      path: "/frequently-asked-questions",
      element: <Faq />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/shipping-and-returns",
      element: <ShippingReturns />,
    },
    {
      path: "/about-wudify",
      element: <AboutUs />,
    },
    {
      path: "/contact-us",
      element: <ContactUs />,
    },
    {
      path: "/contact-us/:requestNumber",
      element: (
        <>
          <ContactConfirmation />
        </>
      ),
    },
  ]);

  const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

  {
    /* Provide the client to your App */
  }
  return (
    <div className="App">
      <GoogleOAuthProvider clientId={clientId}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router}></RouterProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
