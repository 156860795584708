import React from "react";

function CardTitleBig({
  title,
  marginBottomClass = "mb-8",
  className = "font-[600] text-[28px] max-lg:text-[23px] max-md:text-[18px]",
}) {
  return (
    <div>
      <div className={`${marginBottomClass}`}>
        <div className={`${className}`}>{title}</div>
      </div>
    </div>
  );
}

export default CardTitleBig;
