import React from "react";
import ReviewStar from "./ReviewStar";
import AddToCartButton from "./AddToCartButton";
import { alreadyAdded } from "../helpers/products";
import AddedToCartButton from "./AddedToCartButton";
import { getColorArray } from "../helpers/extractors";
import CustomPriceDisplay from "./CustomPriceDisplay";
import { getFileUrl } from "./../helpers/extractors";

function RelatedProductItem({ product, mainProduct }) {
  return (
    <div className="cursor-pointer hover:opacity-70 mb-2">
      <a href={`/products/${product?.slug}`}>
        <div
          className="h-[250px] max-md:h-[130px]"
          style={{ overflow: "hidden" }}
        >
          <img
            src={getFileUrl(product?.imageOne)}
            className="h-full w-full rounded-md cover"
            alt="image"
            loading="lazy"
          />
        </div>
        <div className="mt-4"></div>
        <div className="px-1" style={{ marginTop: "-1.5em" }}>
          <div className="flex flex-wrap gap-2 items-center justify-between font-[500] text-[14px] max-md:text-[12px]">
            <div>{product?.name}</div>
            <CustomPriceDisplay price={product?.price} />
          </div>
          <div className="text-left mt-1">{product?.color}</div>
          <div className="flex gap-2 items-center my-2">
            <div className="w-1/3">
              <ReviewStar value={parseInt(product?.averageRating ?? 0)} />
            </div>
            <div className="text-left font-[500]">
              ({product?.Reviews?.length})
            </div>
          </div>
        </div>
      </a>
      {!alreadyAdded(product?.Carts, product?.id) ? (
        <AddToCartButton
          chooseColor={false}
          color={getColorArray(product?.colors)[0]}
          quantity={1}
          productId={product?.id}
        />
      ) : (
        <AddedToCartButton />
      )}
    </div>
  );
}

export default RelatedProductItem;
