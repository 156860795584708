import axiosInstance from "../utils/axiosInstance";


export const getFurnitureGuides = async (data) => {
    return axiosInstance(`/furniture-guides`, "GET", data).then(response => {
        return response;
    })
}

export const getFurnitureGuideBySlug = async (data) => {
    return axiosInstance(`/furniture-guides/by/${data.slug}`, "GET", data).then(response => {
        return response;
    })
}