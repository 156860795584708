import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../commons/Layout";
import CartItemLoader from "../../commons/loaders/CartItemLoader";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import useUser from "../../hooks/useUser";
import useUserBuyNow from "../../hooks/useUserBuyNow";
import BuyNowDetails from "./BuyNowDetails";
import BuyNowSummary from "./BuyNowSummary";

function BuyNow() {
  const params = useParams();
  const { slug } = params;

  const { user } = useUser();
  const { userBuyNowItem, isLoading } = useUserBuyNow({
    ...user,
    userId: user?.id,
    productSlug: slug,
    computeShipping: false,
  });

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="w-[85%] max-md:w-[95%] mx-auto pt-8">
        <PageBreadCrumb
          mainText={"Home"}
          subText={"Buy now"}
          otherText={""}
          isActiveIndex={1}
        />

        <div className="flex max-md:flex-col justify-between w-full gap-12 mt-6 pb-20">
          <div className="w-[60%] max-md:w-full text-left">
            {isLoading && (
              <>
                <CartItemLoader />
              </>
            )}
            {!isLoading && (
              <>
                <BuyNowDetails item={userBuyNowItem} />
              </>
            )}
          </div>
          <div className="w-[35%] max-md:w-full text-left">
            <BuyNowSummary item={userBuyNowItem} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BuyNow;
