import React, { useState } from "react";
import CardTitle from "../../commons/CardTitle";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CustomInputField from "../../commons/CustomInputField";
import CustomTextField from "../../commons/CustomTextField";
import FullRoundedButton from "../../commons/FullRoundedButton";
import useUpdateAddress from "../../hooks/useUpdateAddress";
import CustomSnackBar from "../../commons/CustomSnackBar";
import useUser from "../../hooks/useUser";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";

function AccountEditAddress({
  onBackClick,
  address,
  setSelectedAddress,
  setSnackBarMessage,
  setOpenSnackBar,
  setIsError,
  updateAddress,
  user,
}) {
  return (
    <div className="shadow-md rounded-lg w-[100%] bg-white p-6 text-left">
      <div className="flex justify-between items-start">
        <div className="">
          <CardTitle title="Edit Address" />
        </div>
        <div
          onClick={onBackClick}
          className=" flex gap-2 text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[600] items-center text-[#318EC9] cursor-pointer"
        >
          <div>
            <MdOutlineArrowBackIos cla />
          </div>
          <div>Back</div>
        </div>
      </div>

      {/* Form */}
      <div>
        <Formik
          initialValues={{
            id: address?.id,
            firstName: address?.firstName,
            lastName: address?.lastName,
            phoneNumber: address?.phoneNumber,
            address: address?.address,
            city: address.city,
            zipCode: address.zipCode,
            email: address?.email,
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            updateAddress({
              ...values,
              userId: user?.id,
              accessToken: user?.accessToken,
            })
              .then((res) => {
                setSnackBarMessage("Address updated successfully");
                setIsError(false);
                setOpenSnackBar(true);
                resetForm();
                setSelectedAddress(null);
              })
              .catch((err) => {
                setSnackBarMessage(err?.message);
                setIsError(true);
                setOpenSnackBar(true);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.lastName) {
              errors.lastName = "Last name is required";
            }
            if (!values.firstName) {
              errors.firstName = "First name is required";
            }
            if (!values.phoneNumber) {
              errors.phoneNumber = "Mobile number is required";
            }
            if (!values.address) {
              errors.address = "Address is required";
            }
            if (!values.zipCode) {
              errors.zipCode = "Zip code is required";
            }
            if (!values.city) {
              errors.city = "City is required";
            }
            return errors;
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="flex max-md:flex-col items-center justify-between gap-4 max-md:gap-0">
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"First Name"}
                    type="text"
                    placeholder="Please enter your first name"
                    name="firstName"
                    disabled={isSubmitting}
                  />
                </div>
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"Last Name"}
                    type="text"
                    placeholder="Please enter your last name"
                    name="lastName"
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <CustomTextField
                label={"Address"}
                type="text"
                placeholder="Please enter your address"
                name="address"
                disabled={isSubmitting}
              />

              {/* City and Zip code */}
              <div className="flex max-md:flex-col items-center justify-between gap-4 max-md:gap-0">
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"City/Town"}
                    type="text"
                    placeholder="Please enter your city/town"
                    name="city"
                    disabled={isSubmitting}
                  />
                </div>
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"Zip Code"}
                    type="text"
                    placeholder="Please enter your zip code"
                    name="zipCode"
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              {/* Mobile Number and Email Address */}
              <div className="flex max-md:flex-col items-center justify-between gap-4 max-md:gap-0">
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"Mobile Number"}
                    type="text"
                    placeholder="Please enter your mobile number"
                    name="phoneNumber"
                    disabled={isSubmitting}
                  />
                </div>
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"Email Address"}
                    type="email"
                    placeholder="Please enter your email address"
                    name="email"
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              {/* Submit button */}
              <FullRoundedButton
                label={isSubmitting ? "Please wait" : "Update address"}
                className={"text-white bg-[#FF9D21] hover:bg-[#FF9D21] mt-3"}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <CustomThreeDotsSpinner
                    height="20"
                    width="20"
                    color="white"
                  />
                )}
              </FullRoundedButton>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mb-10 max-md:gap-5"></div>
    </div>
  );
}

export default AccountEditAddress;
