import { ErrorMessage, Field } from "formik";
import React from "react";

function CustomTextField({
  label,
  type = "text",
  disabled = false,
  name,
  placeholder = "",
  rows = 3,
}) {
  return (
    <div className="mb-5 text-left">
      <div className="mb-2 text-[14px] font-[500]">{label}</div>
      <div>
        <Field
          as="textarea"
          rows={rows}
          name={name}
          disabled={disabled}
          className="w-full text-[14px] font-[400] py-3 px-3 border rounded-md outline-none"
          type={type}
          placeholder={placeholder}
          style={{ resize: "none" }}
        />
        <ErrorMessage
          className="text-xs text-red-400"
          name={name}
          component="div"
        />
        
      </div>
    </div>
  );
}

export default CustomTextField;
