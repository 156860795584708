export const bespoke_items = [
    {
        id: 1,
        title: "Personalisation",
        description: "Elevate your space with Wudify's bespoke service. Tailor furniture to your taste, style, and specifications, creating a one-of-a-kind expression of your unique personality.",
        images: [
            "/assets/personalisation-image-1.png",
            "/assets/personalisation-image-2.png",
            "/assets/personalisation-image-3.png"
        ]
    },
    {
        id: 2,
        title: "Quality Craftsmanship",
        description: "Immerse yourself in unparalleled quality with Wudify's bespoke furniture. Each piece is expertly crafted, marrying exquisite design with the finest materials for enduring beauty and functionality.",
        images: [
            "/assets/quality-craftmanship-image-1.png",
            "/assets/quality-craftmanship-image-2.png",
            "/assets/quality-craftmanship-image-3.png"
        ]
    },
    {
        id: 3,
        title: "Exclusive Design",
        description: "Own a piece of true exclusivity with Wudify. Our bespoke service ensures your furniture stands apart, reflecting individuality and setting your space apart from the ordinary.",
        images: [
            "/assets/exclusive-design-image-1.png",
            "/assets/exclusive-design-image-2.png",
        ]
    },

]