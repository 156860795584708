import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { PENDING_REVIEWS_QUERY_KEY } from '../constants/queryKeys';
import { fetchUserPendingReviews } from '../services/order-services';

function useUserPendingReviews(user, pageSize, pageNumber) {
    const navigate = useNavigate()
    const { data: userPendingReviewItems, isLoading, error } = useQuery({
        queryKey: [PENDING_REVIEWS_QUERY_KEY, pageSize, pageNumber],
        queryFn: async () => {
            const result = await fetchUserPendingReviews(user, pageSize, pageNumber)
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        userPendingReviewItems, isLoading, error
    }
}

export default useUserPendingReviews