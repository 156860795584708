import LeftContent from "./LeftContent";
import RightContent from "./RightContent";
import { Drawer } from "@mui/material";

function ContentBodySection({
  subcategory,
  category,
  totalRecord,
  pageSize,
  pageNumber,
  setPageNumber,
  setPageSize,
  dispatch,
  filters,
  brandList,
  colorList,
  typeList,
  priceMax,
  isLoading,
  isFilterModalOpen,
  setIsFilterModalOpen,
}) {
  return (
    <div className="flex w-[95%] mx-auto justify-between my-12 max-md:my-0 max-md:mt-2 max-md:mb-8 items-start gap-10 max-md:gap-6">
      <Drawer
        open={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        anchor="left"
        style={{ zIndex: "20000" }}
        // className=""
      >
        <div className="w-[300px] px-4 py-4">
          <LeftContent
            subcategory={subcategory}
            category={category}
            dispatch={dispatch}
            filters={filters}
            colorList={colorList}
            brandList={brandList}
            typeList={typeList}
            priceMax={priceMax}
            isLoading={isLoading}
            onClose={() => setIsFilterModalOpen(false)}
            isFilterModalOpen={isFilterModalOpen}
          />
        </div>
      </Drawer>
      <div className={`w-1/4 max-sm:hidden text-left`}>
        <LeftContent
          subcategory={subcategory}
          category={category}
          dispatch={dispatch}
          filters={filters}
          colorList={colorList}
          brandList={brandList}
          typeList={typeList}
          priceMax={priceMax}
          isLoading={isLoading}
        />
      </div>
      <div className={`w-3/4 max-sm:w-full text-left`}>
        <RightContent
          subcategory={subcategory}
          totalRecord={totalRecord}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          dispatch={dispatch}
          filters={filters}
          priceMax={priceMax}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default ContentBodySection;
