import React from "react";
import { getFileUrl } from "./../../helpers/extractors";

function BespokeItem({ title, description, images, reverse }) {
  return (
    <div
      className={`flex text-left ${
        reverse ? "flex-row-reverse gap-20" : "gap-4"
      } max-md:flex-col`}
    >
      <div className="w-1/2 max-md:w-full flex flex-col justify-center gap-4">
        <h1 className="font-[600] text-[38px] max-lg:text-[30px] max-md:text-[24px]">
          {title}
        </h1>
        <p className="w-[80%] font-[500] text-[13px] max-md:text-[10px] text-[#828282]">
          {description}
        </p>
      </div>
      {images.length === 3 && (
        <div
          className={`w-1/2 max-md:w-full flex gap-4 max-md:gap-2 max-md:h-[300px]`}
        >
          <div className="flex justify-between flex-col h-full gap-4 max-md:gap-2">
            <div className="w-full h-1/2">
              <img
                src={getFileUrl(images[0])}
                alt=""
                className="h-full w-full object-cover"
              />
            </div>
            <div className="w-full h-1/2">
              <img
                src={getFileUrl(images[1])}
                alt=""
                className="h-full w-full object-cover"
              />
            </div>
          </div>
          <div className="w-full">
            <img
              src={getFileUrl(images[2])}
              alt=""
              className="h-full w-full object-cover"
            />
          </div>
        </div>
      )}
      {images.length === 2 && (
        <div
          className={`w-1/2 max-md:w-full max-md:h-[300px] flex gap-4 max-md:gap-2`}
        >
          <div className="w-full">
            <img
              src={getFileUrl(images[0])}
              alt=""
              className="h-full w-full object-cover"
            />
          </div>

          <div className="w-full">
            <img
              src={getFileUrl(images[1])}
              alt=""
              className="h-full w-full object-cover"
            />
          </div>
        </div>
      )}
      {images.length === 1 && (
        <div className={`w-1/2 max-md:w-full bg-red-100`}>
          <div className="max-md:h-[200px]">
            <img
              className="h-full w-full object-cover"
              src={getFileUrl(images[0])}
              alt=""
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default BespokeItem;
