import React from "react";
import { getFileUrl } from "./../helpers/extractors";

function PhotoDescriptionItem({
  title,
  description,
  imagePath,
  reversed,
  link,
  children,
}) {
  return (
    <div
      className={`flex ${
        reversed ? "flex-row-reverse" : ""
      } gap-[7em] max-lg:gap-[5em] max-md:gap-[3em] max-sm:flex-col`}
    >
      <div className="w-[50%] max-sm:w-full text-left">
        <div className="">
          <h2
            className="text-[42px] font-[600]"
            style={{ lineHeight: "50.4px" }}
          >
            {title}
          </h2>
          <p className="text-[14px] font-[500] text-[#828282] my-5">
            {description}
          </p>
          {children}
        </div>
      </div>
      <div className="w-[50%] h-[350px] max-md:h-[250px] max-sm:w-full shadow-md">
        <img
          src={getFileUrl(imagePath)}
          alt=""
          className="h-full w-full"
          style={{ objectFit: "cover" }}
        />
      </div>
    </div>
  );
}

export default PhotoDescriptionItem;
