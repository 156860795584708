import { useQuery } from '@tanstack/react-query';
import { getDecorTips } from "../services/decor-tips-services";
import { DECOR_TIPS_QUERY_KEY } from "../constants/queryKeys";



function useDecorTips() {
    let decor_tips_blog_posts = [];

    const decorTips = useQuery({
        queryKey: [DECOR_TIPS_QUERY_KEY],
        queryFn: async () => {
            const result = await getDecorTips();
            if (result.data.error) {
            }
            return result;
        },
        onError: (error) => {
            console.log("error when fetching decor tips", error);
        },
    });

    decor_tips_blog_posts = decorTips?.data?.data?.data ?? [];

    return {
        decorTips: decor_tips_blog_posts,
        isLoading: decorTips.isLoading
    }
}

export default useDecorTips