import React from "react";
import CardTitleBig from "../../commons/CardTitleBig";
import Layout from "../../commons/Layout";
import PhotoDescriptionItem from "../../commons/PhotoDescriptionItem";
import BrandSection from "../home/BrandSection";

function AboutUs() {
  return (
    <Layout>
      <div className="w-[90%] max-md:w-[95%] mx-auto px-5 py-10">
        <div className="text-[60px] max-lg:text-[50px] max-md:text-[40px] font-[600] mb-5">
          About Wudify
        </div>
        <div
          className="font-[500] text-[14px] max-md:text-[12px] w-[100%] mx-auto text-center text-[#667185]"
          style={{ textAlign: "center" }}
        >
          Wudify is a cutting-edge e-commerce furniture platform dedicated to
          elevating living spaces across Africa. We curate a bespoke selection
          of fine furniture and accessories, harmonizing aesthetics, comfort,
          and culture. Our mission is to revolutionize the furniture industry in
          Africa by creating a dynamic e-commerce platform that seamlessly
          connects and empowers all stakeholders along the furniture value
          chain.
        </div>
        <div className="my-28 max-md:my-12">
          <PhotoDescriptionItem
            title="Our Story"
            reversed={true}
            imagePath={"/assets/about_our_story.png"}
            description={
              "At Wudify, our story is fueled by a passion for transforming spaces throughout Africa into hubs of style and comfort. Born from a vision to curate the finest furniture and accessories, we embarked on a mission to elevate environments with a perfect blend of aesthetics and functionality. Our narrative unfolds in each thoughtfully selected piece, uniting contemporary designs with timeless classics. Wudify isn't just an e-commerce platform – it's a celebration of artistry, culture, and the essence of living well. Join us on this exciting journey as we redefine spaces across various sectors, one elegant piece at a time."
            }
          />
        </div>

        <div className="my-28 max-md:my-12">
          <CardTitleBig
            title="Learn About Our Luxury brand"
            className="font-[600] text-[40px] max-lg:text-[32px] max-md:text-[24px]"
            marginBottomClass="mb-1"
          />
          <div className="mt-[-2em]">
            <BrandSection />
          </div>
        </div>

        <div className="my-28 max-md:my-12">
          <PhotoDescriptionItem
            title="Custom Design"
            imagePath={"/assets/about_custom_design.png"}
            reversed={false}
            description={
              "If you're looking for fine furniture that you can customise to your own design, look no further than Wudify. Their impressive range of bespoke furniture pieces and design options make them an ideal choice for anyone seeking to make their home feel special and unique."
            }
          >
            <div className="flex gap-5">
              <button className="bg-orange-400 text-white rounded-md py-3 px-6 font-semibold hover:opacity-80">
                <a href={"/bespoke-services"}>{"Start Now"}</a>
              </button>
              <button className="bg-orange-400 text-white rounded-md py-3 px-6 font-semibold hover:opacity-80">
                <a href={"/book-consultation"}>{"Enquiry"}</a>
              </button>
            </div>
          </PhotoDescriptionItem>
        </div>
      </div>
    </Layout>
  );
}

export default AboutUs;
