import React, { useEffect, useState } from "react";
import CardTitle from "../../commons/CardTitle";
import AccountPendingReviewsItemLoader from "../../commons/loaders/AccountPendingReviewsItemLoader";
import Pagination from "../../commons/Pagination";
import AccountRatingReview from "./AccountRatingReview";
import AccountPendingReviewsItem from "./AccountPendingReviewsItem";
import useUserPendingReviews from "../../hooks/useUserPendingReviews";
import CustomNoRecordFound from "../../commons/CustomNoRecordFound";
import { MdOutlineRateReview } from "react-icons/md";
import useUser from "../../hooks/useUser";
import CustomSnackBar from "../../commons/CustomSnackBar";
import { useNavigate } from "react-router-dom";

function AccountPendingReviews({ onViewClick }) {
  const navigate = useNavigate();

  const PAGE_SIZE = 3;
  const { user } = useUser();

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const { userPendingReviewItems, isLoading } = useUserPendingReviews(
    user,
    PAGE_SIZE,
    currentPage
  );

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [currentPage, selectedItem]);

  return (
    <>
      {!selectedItem && (
        <div className="shadow-md rounded-lg w-[70%] max-lg:w-full bg-white p-6 text-left">
          <CardTitle
            title={`Pending Reviews (${
              userPendingReviewItems?.orders?.length ?? 0
            })`}
          />
          {isLoading && <AccountPendingReviewsItemLoader />}

          {!isLoading && userPendingReviewItems?.orders?.length === 0 && (
            <div className="mt-20">
              <CustomNoRecordFound
                buttonText={"Shop now"}
                label={"You don't have any pending reviews."}
                moreLabel="Shop now, make orders and add your reviews."
                onButtonClick={() => navigate("/")}
              >
                <MdOutlineRateReview style={{ fontSize: "12em" }} />
              </CustomNoRecordFound>
            </div>
          )}
          {!isLoading &&
            userPendingReviewItems?.orders?.map((orderItem) => {
              return (
                <AccountPendingReviewsItem
                  orderItem={orderItem}
                  onViewClick={onViewClick}
                  setSelectedItem={(e) => {
                    setSelectedItem(e);
                  }}
                />
              );
            })}

          <Pagination
            totalCount={userPendingReviewItems?.totalRecords ?? 0}
            pageSize={PAGE_SIZE}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </div>
      )}

      {selectedItem && (
        <>
          <AccountRatingReview
            orderItem={selectedItem}
            onBackClick={() => setSelectedItem(null)}
            setIsError={setIsError}
            setSnackBarMessage={setSnackBarMessage}
            setOpenSnackBar={setOpenSnackBar}
          />
        </>
      )}

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </>
  );
}

export default AccountPendingReviews;
