import React, { useCallback, useEffect, useState } from "react";
import { IoTrashBinOutline } from "react-icons/io5";
import FullRoundedButton from "../../commons/FullRoundedButton";
import QuantityAdjuster from "../../commons/QuantityAdjuster";
import { debounce } from "lodash";

import ViewAllButton from "../../commons/ViewAllButton";
import useUser from "../../hooks/useUser";
import useUpdateCartItem from "../../hooks/useUpdateCartItem";
import useDeleteCartItem from "../../hooks/useDeleteCartItem";
import { getFileUrl } from "./../../helpers/extractors";

function CartModalItem({
  item,
  setIsError,
  setSnackBarMessage,
  setOpenSnackBar,
}) {
  const [quantity, setQuantity] = useState(0);
  const { user } = useUser();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { updateCartItem, isLoading } = useUpdateCartItem();
  const { deleteCartItem } = useDeleteCartItem();

  const handleSetQuantity = (value) => {
    setQuantity(value);
    setIsUpdating(true);
    updateCartItem({
      userId: user?.id,
      accessToken: user?.accessToken,
      quantity: value,
      productId: item?.Product.id,
      id: item?.id,
    })
      .then(() => {})
      .catch((err) => {})
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const handleDeleteItem = () => {
    setIsDeleting(true);
    deleteCartItem({
      userId: user?.id,
      accessToken: user?.accessToken,
      id: item?.id,
    })
      .then(() => {
        setOpenSnackBar(true);
        setSnackBarMessage("Successfully deleted");
        setIsError(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const debounced = useCallback(debounce(handleSetQuantity, 500), []);

  useEffect(() => {
    setQuantity(item?.quantity);
  }, [item]);

  return (
    <div className="flex mb-2">
      <div className="flex gap-8 max-lg:gap-4 max-md:gap-2">
        <div className="h-[96px] w-[96px] max-md:h-[60px] max-md:w-[60px] max-sm:h-[40px]">
          <img
            className="w-full h-full rounded-md object-cover"
            src={getFileUrl(item?.Product?.imageOne)}
            alt={item?.Product?.name}
            loading="lazy"
          />
        </div>
        <div className="flex flex-col justify-around text-left">
          <p className="font-[600] text-[12px] max-md:text-[10px]">
            {item?.Product?.name}
          </p>
          {isUpdating ? (
            <p className="flex text-right">
              <p className="h-[30px] w-[60%] bg-gray-100 opacity-60"></p>
            </p>
          ) : (
            <p className="font-[600] text-[18px] max-lg:text-[15px] max-md:text-[12px]">
              Amount: N{item?.Product ? item.Product.price : 0}
            </p>
          )}
          {item?.color && (
            <p className="font-[400] text-[10px] text-[#475367]">
              Color: {item?.color}
            </p>
          )}
          <QuantityAdjuster
            setQuantityValue={debounced}
            quantityValue={quantity}
            className={`font-[500] !text-[14px] max-md:!text-[12px] w-1/2 max-sm:w-4/5 py-1 px-2`}
          />
        </div>
      </div>

      <div className="flex-col flex " onClick={handleDeleteItem}>
        <IoTrashBinOutline className="text-[20px] max-lg:text-[16px] max-md:text-[12px] mt-10 cursor-pointer text-red-600" />
      </div>
    </div>
  );
}

export default CartModalItem;
