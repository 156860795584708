import React, { useState, useEffect } from "react";
import { ErrorMessage } from "formik";
import "./css/add_product.css";
import { getFileTypesFriendly, isFileType } from "../helpers/fileUpload";
import { GENERAL_GRAY_HEX } from "../constants/texts";
import { imageOnlyFileTypes } from "../constants/data";

function ProductImageUploaderUI({
  label,
  name,
  required,
  setFieldError,
  onChange,
  setErrors,
  errors,
  file,
  fileTypes = imageOnlyFileTypes,
  fileSize = 5000000,
  disabled = false,
  readOnly = false,
}) {
  const [imageUrl, setImageUrl] = useState(null);

  const handleFilePreview = (file) => {
    if (file) {
      if (typeof file == "string") {
        setImageUrl(file);
      } else {
        setImageUrl(URL.createObjectURL(file));
      }
    } else {
      setImageUrl(null);
    }
  };

  const handleFileChange = (event) => {
    if (disabled) return;

    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }
    const isType = isFileType(fileTypes, selectedFile);
    if (!isType) {
      const errorMessage = "File should be " + getFileTypesFriendly(fileTypes);
      setFieldError(name, errorMessage);
      setErrors({ ...errors, [name]: errorMessage });
    } else if (selectedFile.size > fileSize) {
      const errorMessage =
        "File size should not be more than " + fileSize / 1000000 + " MB";
      setFieldError(name, errorMessage);
      setErrors({
        ...errors,
        [name]: errorMessage,
      });
    } else {
      onChange(selectedFile);
    }
  };

  const handleBlur = (event) => {
    if (disabled) return;

    if (required) {
      if (!file) {
        setFieldError(name, label + " is required");
      }
    }
  };

  useEffect(() => {
    handleFilePreview(file);
  }, [file]);

  return (
    <div className="w-full cursor-pointer">
      <p className="font-[500] text-[14px] my-1.5 text-left">
        {label} {required ? <span className="text-red-300">*</span> : ""}
      </p>
      <div className="flex w-full gap-3 border p-3 rounded-md items-center justify-between">
        <div className="flex items-center gap-3">
          <div>
            <img src="/assets/file-upload-icon.png" />
          </div>
          <div className={`text-[${GENERAL_GRAY_HEX}] file-upload relative`}>
            <div className="font-[500] text-[14px]">Click to upload</div>
            <div className="font-[400] text-[12px] text-[#667085]">
              PNG or JPG (max. 5 MB)
            </div>
            {!disabled && (
              <input
                name={name}
                type="file"
                onChange={handleFileChange}
                onBlur={handleBlur}
              />
            )}
            <div className="text-xs text-[#318EC9]" name={name} component="div">
              {file?.name}
            </div>
            <div className="text-xs text-red-400" name={name} component="div">
              {errors[name]}
            </div>
          </div>
        </div>
        {imageUrl && (
          <div className="w-[50px] h-[50px] border bg-red-100">
            <img
              className="w-[50px] h-[50px]"
              style={{ objectFit: "cover" }}
              src={imageUrl}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductImageUploaderUI;
