import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";


export const newContactRequest = async (data) => {
    return axiosInstance(`/contacts`, "POST", data, data.accessToken).then(response => {
        return response;
    }).catch(err => {
        // return err
        getNetworkErrorMessage(err)
    })
}


