import { useMutation, useQueryClient } from '@tanstack/react-query'
import { USER_QUERY_KEY } from '../constants/queryKeys';
import { register } from "../services/auth-service";

function useSignup() {
    const queryClient = useQueryClient();
    const { mutateAsync: signUserUp, isLoading, error, data } = useMutation({
        mutationFn: (registerData) => register(registerData),
        onSuccess: (data) => {
            
            queryClient.setQueryData([USER_QUERY_KEY], data?.data?.data)
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        signUserUp, isLoading, error, data
    }
}

export default useSignup