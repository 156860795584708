import React, { useState } from "react";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import DividedPage from "../../commons/DividedPage";
import CardTitle from "../../commons/CardTitle";
import CustomRadioButton from "../../commons/CustomRadioButton";
import FullRoundedButton from "../../commons/FullRoundedButton";
import CartPriceItem from "../../commons/CartPriceItem";
import { PaystackButton } from "react-paystack";
import { useNavigate } from "react-router-dom";
import AccountNewAddress from "../account/AccountNewAddress";
import AccountAddressBook from "../account/AccountAddressBook";
import useUser from "../../hooks/useUser";
import useUserCart from "../../hooks/useUserCart";
import { handleCartCheckout } from "../../helpers/checkout";
import CustomBackdrop from "../../commons/CustomBackdrop";
import { paymentTypes } from "../../constants/data";
import CustomSnackBar from "../../commons/CustomSnackBar";

function Checkout() {
  const [alternative, setAlternative] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [paymentType, setPaymentType] = useState(paymentTypes[0]);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();

  const { user } = useUser();

  const { userCartItems, isLoading } = useUserCart(
    user,
    null,
    null,
    null,
    true
  );

  const paymentTypeExists = (paymentType) => {
    for (const pType of paymentTypes) {
      if (
        pType.label === paymentType.label &&
        pType.value === paymentType.value
      ) {
        return true;
      }
    }
  };

  const safeToCheckout = (userAddresses) => {
    if (
      paymentTypeExists(paymentType) &&
      userCartItems &&
      userCartItems?.cartItems.length > 0 &&
      userAddresses?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const leftSectionAlternative = () => {
    return (
      <div>
        <AccountNewAddress
          onAddressAdded={() => setAlternative(false)}
          onBackClick={() => {
            setAlternative(false);
          }}
        />
      </div>
    );
  };

  const rightSection = () => {
    return (
      <div>
        <CardTitle title="Order Summary" />
        <div className="my-4">
          <hr />
        </div>
        <div>
          <div className="font-[600] text-[16px] max-lg:text-[14px] max-md:text-[12px] mb-3 mt-5">
            Pay with
          </div>
          {paymentTypes.map((pType) => {
            return (
              <div className="flex gap-2 items-center mb-2">
                <CustomRadioButton
                  accentColor="black"
                  radioName={"payment-type"}
                  checked={paymentType.label === pType.label}
                  onClick={() => {
                    setPaymentType(pType);
                  }}
                ></CustomRadioButton>
                <div className="font-[500] text-[16px] max-lg:text-[14px] max-md:text-[12px]">
                  {pType.label}
                </div>
              </div>
            );
          })}
        </div>
        <div className="my-7">
          <hr />
        </div>
        <div className="font-[600] text-[16px] max-lg:text-[14px] max-md:text-[12px] mb-3 mt-5">
          Apply discount
        </div>
        <div className="flex max-md:flex-col items-center gap-2">
          <div className="w-[60%] max-md:w-full">
            <input
              className="py-3 px-5 max-md:py-2 max-md:px-3 text-[14px] max-md:text-[12px] font-[400] rounded-full w-full bg-gray-100 outline-none"
              placeholder="Enter Coupon Code"
            />
          </div>
          <div className="w-[30%] max-md:w-full">
            <FullRoundedButton
              className={
                "bg-white text-[#FF9D21] hover:bg-[#FF9D21] hover:text-white w-full"
              }
              label={"Apply"}
            />
          </div>
        </div>
        <div className="my-7">
          <hr />
        </div>

        {/*  */}
        <CartPriceItem
          title="Sub Total"
          price={`N${userCartItems?.subTotalPrice}`}
        />
        <CartPriceItem title="Shipping" price={`N${userCartItems?.shipping}`} />
        <div className="my-7">
          <hr />
        </div>
        <CartPriceItem title="Total" price={`N${userCartItems?.totalPrice}`} />

        {safeToCheckout(user?.Addresses) ? (
          <PaystackButton
            className={
              "hover:opacity-90 bg-[#FF9D21] text-white w-full mt-10 max-md:mt-5 py-3 rounded-full font-[500] text-[16px] max-md:text-[12px]"
            }
            {...handleCartCheckout(
              userCartItems,
              user,
              paymentType.label,
              (url) => {
                navigate(url, { replace: true });
              },
              () => {
                setOpenBackdrop(true);
              },
              () => {
                setOpenBackdrop(false);
              },
              (err) => {
                console.log("There was an error", err);
                setOpenSnackBar(true);
                setSnackBarMessage(err?.response?.data?.message);
                setIsError(true);
              },
              () => {}
            )}
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  if (!userCartItems && !isLoading) {
    navigate("/my-cart");
  }

  return (
    <Layout bgClassName="bg-gray-100">
      <>
        <CustomSnackBar
          isOpen={openSnackBar}
          onClose={() => setOpenSnackBar(false)}
          message={snackBarMessage}
          isError={isError}
        />
        {!openBackdrop && (
          <div className="w-[95%] mx-auto pt-8">
            <PageBreadCrumb
              mainText={"Home"}
              subText={"Cart"}
              otherText={"Checkout"}
              isActiveIndex={2}
            />

            {/* <DividedPage
          leftSection={alternative ? leftSectionAlternative() : leftSection()}
          rightSection={rightSection()}
          // removeParent={alternative ? true : false}
        ></DividedPage> */}
            <DividedPage
              leftSection={
                alternative ? (
                  leftSectionAlternative()
                ) : (
                  <AccountAddressBook
                    onNewClick={() => setAlternative(true)}
                    onEditClick={() => {
                      setAlternative(false);
                    }}
                    widthClass={"w-[100%]"}
                  />
                )
              }
              rightSection={rightSection()}
              removeParent={true}
            ></DividedPage>
          </div>
        )}

        <CustomBackdrop open={openBackdrop} text={"Please wait..."} />
      </>
    </Layout>
  );
}

export default Checkout;
