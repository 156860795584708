import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { CART_ITEM_QUERY_KEY, WISH_LIST_QUERY_KEY } from '../constants/queryKeys';
import { addToCart } from "../services/cart-services";

function useAddToCart() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { mutateAsync: addToUserCart, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => addToCart(updateData),
        onSuccess: (data) => {
            queryClient.setQueryData([CART_ITEM_QUERY_KEY], data?.data?.data)
            queryClient.invalidateQueries(WISH_LIST_QUERY_KEY);
            // navigate("/my-account")
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        addToUserCart, isLoading, error, data
    }
}

export default useAddToCart