import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { PRODUCT_CATEGORY_QUERY_KEY } from '../constants/queryKeys';
import { fetchProductCategoryBySlug } from '../services/product_categories-services';

function useProductCategory(slug) {
    const navigate = useNavigate()
    const { data: productCategory, isLoading, error } = useQuery({
        queryKey: [PRODUCT_CATEGORY_QUERY_KEY],
        queryFn: async () => {
            const result = await fetchProductCategoryBySlug(slug)
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        productCategory, isLoading, error
    }
}

export default useProductCategory