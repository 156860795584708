import React, { useState } from "react";
// import { IoLogoTiktok } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { IoMdCopy } from "react-icons/io";
import { Tooltip } from "@mui/material";
import CustomSnackBar from "./CustomSnackBar";

function SocialLinks() {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [isError, setIsError] = useState(false);

  return (
    <div>
      <div className="flex gap-4 max-lg:gap-2 items-center">
        <Tooltip title="Copy this link">
          <div
            onClick={() => {
              navigator.clipboard
                .writeText(window.location.href)
                .then(() => {
                  setIsError(false);
                  setSnackBarMessage("Link copied");
                  setOpenSnackBar(true);
                })
                .catch(() => {
                  setIsError(true);
                  setSnackBarMessage("Error copying this link");
                  setOpenSnackBar(true);
                });
            }}
            o
            className="cursor-pointer flex items-center justify-center w-[3em] h-[3em] border border-[#EAECF0] bg-white rounded-full"
          >
            <IoMdCopy className="w-[1.5em] h-[1.5em]" />
          </div>
        </Tooltip>
        <a
          href={`https://wa.me/2348024567858?text=Hello%20Wudify,%20I%20am%20interested%20in%20this%20product%20${window.location.href}`}
        >
          <div className="flex items-center justify-center w-[3em] h-[3em] border border-[#EAECF0] bg-white rounded-full">
            <FaWhatsapp className="w-[1.5em] h-[1.5em]" />
          </div>
        </a>
        <a
          href={`https://twitter.com/intent/tweet?text=Hey,%20Checkout%20this%20amazing%20product%20at%20Wudify%20Africa&url=${window.location.href}&hashtags=Wudify&via=WudifyAfrica`}
        >
          <img className="cursor-pointer" src="/assets/x_icon.png" alt="" />
        </a>
        <a
          href={`https://www.facebook.com/dialog/share?_encoding=UTF8&app_id=61551922751663&display=popup&href=${window.location.href}&redirect_uri=${window.location.href}&hashtag=%23WudifyAfrica`}
        >
          <img
            className="cursor-pointer"
            src="/assets/facebook_icon.png"
            alt=""
            loading="lazy"
          />
        </a>
        {/* <a
          href={`https://www.instagram.com/wudify.africa?igsh=Zm1icmpocG1uY25k`}
        >
          <img
            className="cursor-pointer"
            src="/assets/instagram_icon.png"
            alt=""
            loading="lazy"
          />
        </a> */}
        {/* <a href={window.location.href}>
          <div className="flex items-center justify-center w-[3em] h-[3em] border border-[#EAECF0] bg-white rounded-full">
            <IoLogoTiktok
              className="w-[1.5em] h-[1.5em]"
              style={{ color: "black" }}
            />
          </div>
        </a> */}
      </div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default SocialLinks;
