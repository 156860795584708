import React, { useState } from "react";
import { PiCaretDownBold, PiCaretUpBold } from "react-icons/pi";
import { LIGHT_BLUE_COLOR } from "../constants/texts";
import SearchInput from "./SearchInput";
import { CustomScroll } from "react-custom-scroll";
import { SET_COLORS_ARRAY } from "../reducers/productFilterReducer/types";
import { capitalizeFirstLetter } from "../helpers/strings";
import { addOrRemoveFromList } from "../helpers/extractors";

function ProductFilterItem({
  name,
  items,
  inputPlaceholder,
  inputOnChange,
  itemKey,
  itemValue,
  value,
  checkedItems,
  setCheckedItems,
  dispatch,
  actionType,
}) {
  const [show, setShow] = useState(true);
  // const [checkedItems, setCheckedItems] = useState(selectedItems);
  const [style, setStyle] = useState({
    maxHeight: "250px",
    transition: "max-height 0.5s ease-out",
  });

  const filteredItems = () => {
    if (value === "" || !value) {
      return items;
    }
    return items?.filter((item) => {
      if (typeof item == "object") {
        return item[itemKey].toLowerCase().includes(value.toLowerCase());
      }
      return item.toLowerCase().includes(value.toLowerCase());
    });
  };

  const addToCheckedItems = (e) => {
    let list = checkedItems;
    let newList = [];
    if (e.target.checked) {
      newList = addOrRemoveFromList(e.target.value, list, 1);
    } else {
      newList = addOrRemoveFromList(e.target.value, list, -1);
    }

    setCheckedItems((prev) => newList);
    dispatch({ type: actionType, payload: newList });
  };

  return (
    <div className="mb-16 max-md:mb-8">
      <div
        className={`cursor-pointer flex justify-between items-center text-[20px] max-lg:text-[17px] max-md:text-[14px] font-[500] text-[${LIGHT_BLUE_COLOR}] mt-8 mb-4`}
        onClick={() => {
          setShow(!show);
          if (show) {
            setStyle({
              maxHeight: "0",
              overflowY: "hidden",
              transition: "max-height 0.5s ease-in",
            });
          } else {
            setStyle({
              maxHeight: "250px",
              transition: "max-height 0.5s ease-out",
            });
          }
        }}
        style={{ color: LIGHT_BLUE_COLOR }}
      >
        <div>{name}</div>
        <div>
          {!show && <PiCaretDownBold className="text-lg font-bold" />}
          {show && <PiCaretUpBold className="text-lg font-bold" />}
        </div>
      </div>
      {
        <div className="" style={style}>
          <div className="mb-3">
            <SearchInput
              placeholder={inputPlaceholder}
              onChange={inputOnChange}
              value={value}
            />
          </div>
          {/* Filter Items */}
          <div>
            <CustomScroll className="" style={{ maxHeight: "200px" }}>
              <div style={{ maxHeight: "200px" }}>
                {(filteredItems() ?? []).map((item) => {
                  return (
                    <div className="flex items-center justify-between ">
                      <div className="w-[90%] border-b-2 border-gray-100 py-3 text-[14px] max-md:text-[12px] font-[500]">
                        {capitalizeFirstLetter(
                          typeof item == "object" ? item[itemKey] : item
                        )}
                      </div>
                      <div className="pr-4">
                        <input
                          className="border border-r-4 border-black"
                          type={"checkbox"}
                          value={
                            typeof item == "object" ? item[itemValue] : item
                          }
                          onChange={(e) => addToCheckedItems(e)}
                          checked={checkedItems.includes(
                            item[itemValue].toString() ?? item.toString()
                          )}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </CustomScroll>
          </div>
        </div>
      }
    </div>
  );
}

export default ProductFilterItem;
