import React from "react";

function SubcategoryMiniItemLoader() {
  return (
    <div className="flex w-[100%] mx-auto justify-between section-thumbnail-loader-container">
      <div className="">
        <div className="h-[120px] w-[200px] bg-gray-100"></div>
        <p className="w-[12em] mx-auto h-[2em] bg-gray-100 mt-4"></p>
      </div>
      <div>
        <div className="h-[120px] w-[200px] bg-gray-100"></div>
        <p className="w-[12em] mx-auto h-[2em] bg-gray-100 mt-4"></p>
      </div>
      <div>
        <div className="h-[120px] w-[200px] bg-gray-100"></div>
        <p className="w-[12em] mx-auto h-[2em] bg-gray-100 mt-4"></p>
      </div>
      <div>
        <div className="h-[120px] w-[200px] bg-gray-100"></div>
        <p className="w-[12em] mx-auto h-[2em] bg-gray-100 mt-4"></p>
      </div>
      <div>
        <div className="h-[120px] w-[200px] bg-gray-100"></div>
        <p className="w-[12em] mx-auto h-[2em] bg-gray-100 mt-4"></p>
      </div>
      <div>
        <div className="h-[120px] w-[200px] bg-gray-100"></div>
        <p className="w-[12em] mx-auto h-[2em] bg-gray-100 mt-4"></p>
      </div>
    </div>
  );
}

export default SubcategoryMiniItemLoader;
