import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { ADDRESS_BOOK } from '../constants/data';
import { ADDRESS_QUERY_KEY, SELECTED_ADDRESS_QUERY_KEY } from '../constants/queryKeys';
import { addNewAddress, updateUserAddress } from "../services/address-services";

function useUpdateAddress(callback) {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: updateAddress, isLoading, error, data } = useMutation({
        mutationFn: (addressData) => updateUserAddress(addressData),
        onSuccess: (data) => {
            queryClient.setQueryData([SELECTED_ADDRESS_QUERY_KEY], data?.data?.data)
            queryClient.invalidateQueries(ADDRESS_QUERY_KEY);
            setTimeout(() => {
                if (callback) {
                    callback()
                } else {
                    navigate(`/my-account?tab=${ADDRESS_BOOK}`)
                }
            }, 3000)
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        updateAddress, isLoading, error, data
    }
}

export default useUpdateAddress