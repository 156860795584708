import React from "react";
import GetInTouchForm from "../../commons/GetInTouchForm";
import Layout from "../../commons/Layout";
import PageTopDescription from "../../commons/PageTopDescription";
import ContactCard from "./ContactCard";

function ContactUs() {
  return (
    <Layout>
      <div className="w-[70%] max-lg:w-[80%] max-md:w-[90%] mx-auto py-12">
        <div className="w-[80%] max-md:w-full mx-auto">
          <PageTopDescription
            heading={""}
            subHeading="Contact Us"
            description="Please enter the details of your request. A member of our support staff will respond as soon as possible."
          />

          {/* Contact cards */}
          <div className="flex max-sm:flex-wrap gap-6 my-8">
            <div className="bg-[#F9F9F9] w-1/2 max-sm:w-full">
              <ContactCard
                title={"Send us an email"}
                description={
                  "Our support team is available to assist you with any inquiries you may have."
                }
                contact={"contact@wudify.com"}
              />
            </div>
            <div className="bg-[#F9F9F9] w-1/2 max-sm:w-full">
              <ContactCard
                title={"Talk to our support"}
                description={
                  "For immediate assistance, feel free to give us a call."
                }
                contact={"+2348135130567"}
              />
            </div>
          </div>
        </div>
        <GetInTouchForm isConsultation={false} />
      </div>
    </Layout>
  );
}

export default ContactUs;
