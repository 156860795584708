import React from "react";

function QuestionAndAnswerLoaderItem() {
  return (
    <div className="mt-4">
      <div className="flex gap-2 text-[24px] font-[600] mb-4 question-and-answer-loader-container">
        <div className="w-[20px] h-[24px] bg-gray-200"></div>
        <div className="w-[80%] h-[24px] bg-gray-200"></div>
      </div>
      <div className="text-[#52525B] bg-gray-200 text-[16px] font-[400] text-left mb-4 h-[24px] w-[80%]">
        
      </div>
      <hr />
    </div>
  );
}

export default QuestionAndAnswerLoaderItem;
