import React from "react";
import { getFileUrl } from "../../helpers/extractors";

function ProductThumbnailImage({ imagePath, onSelect, selected }) {
  return (
    <div
      className={`${
        selected ? "border border-orange-400" : ""
      } hover:border-orange-400 hover:border hover:opacity-80 cursor-pointer w-[100%]`}
      onClick={() => onSelect(imagePath)}
    >
      <img
        className="w-full h-[8em] max-md:h-[4em] object-cover"
        src={getFileUrl(imagePath)}
        alt=""
        loading="lazy"
      />
    </div>
  );
}

export default ProductThumbnailImage;
