import { addPaginationUrlQuery } from "../helpers/extractors";
import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const fetchUserOrderItems = async (data, pageSize, pageNumber) => {
    let url = "/orders"

    url = addPaginationUrlQuery(url, pageSize, pageNumber);
    return axiosInstance(url, "GET", data, data.accessToken).then(response => {
        return response;
    }).catch(err => {
        getNetworkErrorMessage(err)
    })
}

export const fetchUserPendingReviews = async (data, pageSize, pageNumber) => {
    let url = "/orders/pending-reviews"

    url = addPaginationUrlQuery(url, pageSize, pageNumber);
    return axiosInstance(url, "GET", data, data.accessToken).then(response => {
        return response;
    }).catch(err => {
        getNetworkErrorMessage(err)
    })
}

export const fetchUserOrderItem = async (data) => {
    let url = "/orders/" + data?.id

    return axiosInstance(url, "GET", data, data.accessToken).then(response => {
        return response;
    }).catch(err => {
        getNetworkErrorMessage(err)
    })
}

export const addReview = async (data) => {
    return axiosInstance(`/reviews`, "POST", data, data.accessToken).then(response => {
        return response;
    }).catch(err => {
        // return err
        getNetworkErrorMessage(err)
    })
}
