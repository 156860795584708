import React from "react";

function AccountLinkItem({
  children,
  label,
  className = "hover:text-[#FF9D21]",
  onClick,
  selectedIndex,
  index,
  altIndex = index,
  icon,
}) {
  return (
    <div
      onClick={onClick}
      className={`${
        index === selectedIndex || altIndex === selectedIndex
          ? "text-[#FF9D21]"
          : ""
      } flex gap-3 max-md:gap-1 font-[500] text-[15px] max-md:text-[12px] items-center cursor-pointer ${className}`}
    >
      <div>{children}</div>
      <div className="flex items-center gap-1">
        <span className="whitespace-nowrap">{label}</span>
        {icon && (
          <span className="flex items-center justify-center">{icon}</span>
        )}
      </div>
    </div>
  );
}

export default AccountLinkItem;
