import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "../services/auth-service";
import { useNavigate } from "react-router-dom";

function useResetPassword(openSnackbar) {
  const navigate = useNavigate();

  const {
    mutateAsync: resetUserPassword,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (password) => resetPassword(password),
    onSuccess: () => {
      openSnackbar(false, "Password reset successful. Please login");
      setTimeout(() => {
        navigate("/auth/login");
      }, 2000);
    },
    onError: (error) => {
      openSnackbar(true, error?.message);
    },
  });
  return {
    resetUserPassword,
    isLoading,
    error,
    data,
  };
}

export default useResetPassword;
