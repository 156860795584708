import React from "react";
import QuestionAndAnswerLoaderItem from "./QuestionAndAnswerLoaderItem";

function QuestionAndAnswerLoaders() {
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => {
        return <QuestionAndAnswerLoaderItem />;
      })}
    </>
  );
}

export default QuestionAndAnswerLoaders;
