import axiosInstance from "../utils/axiosInstance";

export const getStateList = async (data) => {
  return axiosInstance(`/states/get-states`, "GET", data).then((response) => {
    return response;
  });
};

export const getTownList = async (data) => {
  return axiosInstance(`/states/get-towns/${data.stateCode}`, "GET", data).then(
    (response) => {
      return response;
    }
  );
};

export const getCountryList = async (data) => {
  return axiosInstance(`/states/get-countries`, "GET", data).then(
    (response) => {
      return response;
    }
  );
};
