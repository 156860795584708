import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { SEARCHED_PRODUCTS_QUERY_KEY } from '../constants/queryKeys';
import { searchProducts } from '../services/products-services';

function useSearchProducts(searchTerm) {
    const navigate = useNavigate()
    const { data: products, isLoading, error } = useQuery({
        queryKey: [SEARCHED_PRODUCTS_QUERY_KEY, searchTerm],
        queryFn: async () => {
            if (searchTerm && searchTerm.length > 2) {
                const result = await searchProducts(searchTerm)
                return result?.data?.data
            }

            return []
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        products: products ?? [], isLoading, error
    }
}

export default useSearchProducts