import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import ScrollToTop from "../commons/ScrollToTop";

function Layout({ children, bgClassName = "bg-transparent" }) {
  return (
    <div className="relative">
      <ScrollToTop />
      <Header />
      <div className={`${bgClassName} lg:min-h-[100vh]`}>{children}</div>
      <Footer />
    </div>
  );
}

export default Layout;
