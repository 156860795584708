import React from "react";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";

function DividedPage({ leftSection, rightSection, removeParent }) {
  return (
    <div className="flex max-md:flex-col justify-between w-full gap-12 max-md:gap-6 mt-6 pb-20 max-md:pb-10">
      <div className="w-[60%] max-md:w-full text-left">
        {removeParent ? leftSection : <LeftSection>{leftSection}</LeftSection>}
      </div>
      <div className="w-[35%] max-md:w-full text-left">
        <RightSection>{rightSection}</RightSection>
      </div>
    </div>
  );
}

export default DividedPage;
