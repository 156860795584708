import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { update } from "../services/auth-service";
import { initiateReturn } from "../services/returns-services";

function useInitiateReturn() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    mutateAsync: initiateReturnRequest,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (returnsData) => initiateReturn(returnsData),
    onSuccess: (data) => {
      navigate("/my-account");
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    initiateReturnRequest,
    isLoading,
    error,
    data,
  };
}

export default useInitiateReturn;
