import React from "react";

function MyWudifySectionLoading() {
  return (
    <div className="w-[100%] h-[90vh] bg--100 flex gap-1 mywudify-loader">
      <div className="h-full w-1/4 flex flex-col gap-1">
        <div className="h-1/2 w-full bg-gray-100"></div>
        <div className="h-1/2 w-full bg-gray-100 "></div>
      </div>
      <div className="h-full w-1/4 bg-gray-100"></div>
      <div className="h-full w-1/4 flex flex-col gap-1">
        <div className="h-1/2 w-full bg-gray-100"></div>
        <div className="h-1/2 w-full bg-gray-100 "></div>
      </div>
      <div className="h-full w-1/4 bg-gray-100"></div>
    </div>
  );
}

export default MyWudifySectionLoading;
