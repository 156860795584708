import React from "react";
import CustomPriceDisplay from "./CustomPriceDisplay";

function CartPriceItem({ title, price }) {
  return (
    <div className="flex justify-between my-3 font-[500] text-[14px] max-md:text-[12px]">
      <div className="text-[#475367]">{title}</div>
      <CustomPriceDisplay price={price} />
    </div>
  );
}

export default CartPriceItem;
