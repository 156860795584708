import React, { useState } from "react";
import CustomSlider from "../../commons/CustomSlider";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import ProductFilterItem from "../../commons/ProductFilterItem";
import { CURRENCY_SYMBOL, LIGHT_BLUE_COLOR } from "../../constants/texts";
import {
  SET_FILTER_BRANDS_ARRAY,
  SET_FILTER_COLORS_ARRAY,
  SET_FILTER_PRICES_ARRAY,
} from "../../reducers/productFilterReducer/types";
import { AiOutlineCloseCircle } from "react-icons/ai";
import FullRoundedButton from "../../commons/FullRoundedButton";
import { handlePriceChange } from "../../helpers/filter";

function LeftContent({
  id,
  type,
  colorList,
  brandList,
  filters,
  dispatch,
  onClose,
  isFilterModalOpen,
  priceMax,
}) {
  const [brandValue, setBrandValue] = useState("");
  const [colorValue, setColorValue] = useState("");
  const [typeValue, setTypeValue] = useState("");

  const [selectedBrandList, setSelectedBrandList] = useState(
    filters.brandFiltersArray
  );
  const [selectedColorList, setSelectedColorList] = useState(
    filters.colorFiltersArray
  );
  const [selectedTypeList, setSelectedTypeList] = useState(
    filters.typeFiltersArray
  );

  return (
    <div>
      {/* Breadcrumb */}
      <div className="flex justify-between items-center">
        <PageBreadCrumb
          mainText="Home"
          subText={type?.Subcategory?.name}
          otherText={type?.name}
          isActiveIndex={2}
        />
        {isFilterModalOpen && (
          <AiOutlineCloseCircle className="text-[20px]" onClick={onClose} />
        )}
      </div>

      {/* Price Filter */}
      <div className="flex justify-between mt-14 items-center">
        <div
          className={`text-[20px] max-lg:text-[17px] max-md:text-[14px] font-[500] text-[${LIGHT_BLUE_COLOR}]`}
        >
          Price({CURRENCY_SYMBOL})
        </div>
        {/* <div className="text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[500]">
          Apply
        </div> */}
      </div>
      <div className="mt-5 mb-4">
        <CustomSlider
          value={filters.priceFiltersArray}
          setValue={(value) => handlePriceChange(value, dispatch)}
        />
      </div>
      <div className="flex items-center justify-between gap-2 mb-10">
        <div className="relative">
          <input
            type="number"
            min={0}
            value={`${Math.round(filters.priceFiltersArray[0] * priceMax)}`}
            onChange={(e) => {
              dispatch({
                type: SET_FILTER_PRICES_ARRAY,
                payload: [
                  Number(e.target.value) / priceMax,
                  filters.priceFiltersArray[1],
                ],
              });
            }}
            className="outline-none border rounded-lg w-full p-[16px] max-md:p-[8px] text-[15px] max-md:text-[12px] font-[500]"
          />
          <p className="absolute top-[17px] left-[5px] text-[15px] max-md:text-[12px] font-[500]">
            {CURRENCY_SYMBOL}
          </p>
        </div>
        <div className="w-[30px] h-[1.5px] bg-gray-600"></div>
        <div className="relative">
          <input
            type="number"
            min={0}
            value={`${Math.round(filters.priceFiltersArray[1] * priceMax)}`}
            onChange={(e) => {
              dispatch({
                type: SET_FILTER_PRICES_ARRAY,
                payload: [
                  filters.priceFiltersArray[0],
                  Number(e.target.value) / priceMax,
                ],
              });
            }}
            className="outline-none border rounded-lg w-full p-[16px] max-md:p-[8px] text-[15px] max-md:text-[12px] font-[500]"
          />
          <p className="absolute top-[17px] left-[5px] text-[15px] max-md:text-[12px] font-[500]">
            {CURRENCY_SYMBOL}
          </p>
        </div>
      </div>

      <ProductFilterItem
        name={"Brand"}
        inputPlaceholder={"Search Brand"}
        items={brandList?.map((v) => v)}
        value={brandValue}
        inputOnChange={(v) => {
          setBrandValue(v);
        }}
        setCheckedItems={setSelectedBrandList}
        checkedItems={selectedBrandList}
        dispatch={dispatch}
        actionType={SET_FILTER_BRANDS_ARRAY}
        itemKey={"name"}
        itemValue={"id"}
      />

      <ProductFilterItem
        name={"Color"}
        inputPlaceholder={"Search Color"}
        items={colorList?.map((c) => c)}
        value={colorValue}
        inputOnChange={(v) => {
          setColorValue(v);
        }}
        setCheckedItems={setSelectedColorList}
        checkedItems={selectedColorList}
        dispatch={dispatch}
        actionType={SET_FILTER_COLORS_ARRAY}
        itemKey={"name"}
        itemValue={"name"}
      />
      {isFilterModalOpen && (
        <FullRoundedButton
          label="Close filter"
          type="type"
          className="bg-[#FF9D21] text-white"
          onClick={onClose}
        />
      )}

      {/* <ProductFilterItem
        name={"Brand"}
        inputPlaceholder={"Search Brand"}
        items={vendors.map((v) => v.name)}
        inputOnChange={(e) => {}}
      />


      <ProductFilterItem
        name={"Color"}
        inputPlaceholder={"Search Color"}
        items={colors.map((c) => c.name)}
        inputOnChange={(e) => {}}
      /> */}
    </div>
  );
}

export default LeftContent;
