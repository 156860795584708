import React from "react";
import HeadingTitle from "../../commons/HeadingTitle";
import MyWudifySectionLoading from "../../commons/loaders/MyWudifySectionLoading";
import { getFileUrl } from "../../helpers/extractors";
import useMyWudifys from "./../../hooks/useMyWudifys";

function MyWudifySection() {
  const { mywudifys, isLoading } = useMyWudifys();

  return (
    <div className="my-16 w-[95%] mx-auto">
      <HeadingTitle
        title={"#MyWudify"}
        textAlignClass="text-center"
        fontSizeClass="text-[32px] max-lg:text-[26px] max-md:text-[20px]"
      />
      {isLoading && (
        <div className="mt-5">
          <MyWudifySectionLoading />
        </div>
      )}
      {!isLoading && mywudifys && mywudifys?.length > 0 && (
        <div className="flex max-md:flex-col gap-4 max-md:gap-2 mt-5">
          <div className="w-1/2 max-md:w-full flex gap-4 max-md:gap-2">
            <div className="flex flex-col w-1/2 gap-4 max-md:gap-2">
              {mywudifys?.slice(0, 2)?.map((mywudify) => {
                return (
                  <div className="h-1/2 w-full">
                    <img
                      loading="lazy"
                      src={getFileUrl(mywudify?.imagePath)}
                      alt="wudify_instagram_post"
                      className="w-full h-full"
                    />
                  </div>
                );
              })}
            </div>
            <div className="w-1/2 max-sm:hidden">
              {mywudifys?.slice(2, 3)?.map((mywudify) => {
                return (
                  <img
                    loading="lazy"
                    src={getFileUrl(mywudify?.imagePath)}
                    alt="wudify_instagram_post"
                    className="w-full h-full"
                  />
                );
              })}
            </div>

            {/* hidden */}
            <div className="hidden max-sm:flex flex-col w-1/2 gap-4 max-md:gap-2">
              {mywudifys?.slice(3, 5)?.map((mywudify) => {
                return (
                  <div className="h-1/2 w-full">
                    <img
                      loading="lazy"
                      src={getFileUrl(mywudify?.imagePath)}
                      alt="wudify_instagram_post"
                      className="w-full h-full"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-1/2 max-md:w-full flex gap-4 max-md:gap-2">
            <div className="max-sm:hidden flex flex-col w-1/2 gap-4 max-md:gap-2">
              {mywudifys?.slice(3, 5)?.map((mywudify) => {
                return (
                  <div className="h-1/2 w-full">
                    <img
                      loading="lazy"
                      src={getFileUrl(mywudify?.imagePath)}
                      alt="wudify_instagram_post"
                      className="w-full h-full"
                    />
                  </div>
                );
              })}
            </div>

            {/* hidden */}
            <div className="w-1/2 max-sm:block hidden">
              {mywudifys?.slice(2, 3)?.map((mywudify) => {
                return (
                  <img
                    loading="lazy"
                    src={getFileUrl(mywudify?.imagePath)}
                    alt="wudify_instagram_post"
                    className="w-full h-full"
                  />
                );
              })}
            </div>
            <div className="w-1/2">
              {mywudifys?.slice(5, 6)?.map((mywudify) => {
                return (
                  <img
                    loading="lazy"
                    src={getFileUrl(mywudify?.imagePath)}
                    alt="wudify_instagram_post"
                    className="w-full h-full"
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyWudifySection;
