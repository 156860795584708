import React from "react";
import ViewAllButton from "../../commons/ViewAllButton";
import CartPriceItem from "../../commons/CartPriceItem";
import CustomShippingFeeNotAdded from "../../commons/CustomShippingFeeNotAdded";

function BuyNowSummary({ item, isLoading }) {
  return (
    <>
      {!isLoading && (
        <div className="p-5 rounded-lg bg-white shadow-md h-full">
          <div className="mb-8">
            <div className="font-[600] text-[20px] max-lg:text-[16px] max-md:text-[14px]">
              Summary
            </div>
          </div>

          <hr />

          <CartPriceItem
            title="Sub Total"
            price={`N${item?.subTotalPrice ?? 0}`}
          />

          <hr />

          <CustomShippingFeeNotAdded />
          <hr />

          {/* <CartPriceItem title="Total" price={`N${item?.total ?? 0}`} /> */}

          <div className="mt-8">
            <a href={`/buy-now/checkout/${item?.tempReference}`}>
              <ViewAllButton
                className="hover:text-white w-full text-center py-3 items-center hover:bg-[#FF9D21] text-[#FF9D21] bg-white border border-orange-400"
                text="Checkout"
              />
            </a>
          </div>
        </div>
      )}
    </>
  );
}

export default BuyNowSummary;
