import React from "react";

function ColorCircle({ colorHex, getColor, selectedColor }) {
  const selected = selectedColor == colorHex;

  return (
    <div
      onClick={() => getColor(colorHex)}
      style={{ border: selected ? "1px solid orange" : "" }}
      className="w-[34px] h-[34px] max-md:w-[20px] max-md:h-[20px] flex items-center justify-center hover:border hover:border-orange-300 rounded-full cursor-pointer"
    >
      <div
        className={`w-[28px] h-[28px] max-md:w-[15px] max-md:h-[15px] border rounded-full bg-[${colorHex}]`}
        style={{ backgroundColor: colorHex }}
      ></div>
    </div>
  );
}

export default ColorCircle;
