import { useQuery } from '@tanstack/react-query';
import { getFurnitureGuides } from "../services/furniture-guides-services";
import { FURNITURE_GUIDES_QUERY_KEY } from "../constants/queryKeys";



function useFurnitureGuides() {
    let furniture_guides_blog_posts = [];

    const furnitureGuides = useQuery({
        queryKey: [FURNITURE_GUIDES_QUERY_KEY],
        queryFn: async () => {
            const result = await getFurnitureGuides();
            if (result.data.error) {
            }
            return result;
        },
        onError: (error) => {
            console.log("error when fetching furniture guide", error);
        },
    });

    furniture_guides_blog_posts = furnitureGuides?.data?.data?.data ?? [];

    return {
        furnitureGuides: furniture_guides_blog_posts,
        isLoading: furnitureGuides.isLoading
    }
}

export default useFurnitureGuides