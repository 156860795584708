export const getColorArray = (string) => {
  if (!string) {
    return [];
  } else {
    const arr = string.split(",");
    return arr;
  }
};

export const addPaginationUrlQuery = (originalUrl, pageSize, pageNumber) => {
  let url = originalUrl;
  let others = "";
  if (pageSize) {
    others += "pageSize=" + pageSize;
  }
  if (pageNumber) {
    if (others.length > 0) {
      others += "&";
    }
    others += "pageNumber=" + pageNumber;
  }

  if (others.length > 0) {
    url = url + "?" + others;
  }

  return url;
};

export const getFileUrl = (imagePath) => {
  if (imagePath) {
    return process.env.REACT_APP_BACKEND_URL_PATH + "/uploads/" + imagePath;
  }

  return "";
};

export const addOrRemoveFromList = (item, list, addOrRemove) => {
  if (addOrRemove === 1) {
    if (!list.includes(item)) {
      list.push(item);
    }
  } else {
    var index = list.indexOf(item);
    if (index > -1) {
      list.splice(index, 1);
    }
  }
  return list;
};
