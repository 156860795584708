import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { WISH_LIST_QUERY_KEY } from '../constants/queryKeys';
import { fetchUserWishListItems } from '../services/wishlist-services';

function useUserWishList(user, updated, pageSize, pageNumber) {
    const navigate = useNavigate()
    const { data: userWishListItems, isLoading, error } = useQuery({
        queryKey: [WISH_LIST_QUERY_KEY, user, updated, pageSize, pageNumber],
        queryFn: async () => {
            const result = await fetchUserWishListItems(user, pageSize, pageNumber)
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        },
    })

    useEffect(() => {

    }, [userWishListItems])
    return {
        userWishListItems, isLoading, error
    }
}

export default useUserWishList