import React from "react";

function CarouselProgressBar({ numberOfDots = 2, selected, onClick }) {
  const getProgressBar = (n) => {
    let list = [];
    for (let i = 0; i < n; i++) {
      list.push(
        <input
          onClick={() => onClick(i)}
          className="w-[24px] h-[24px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[12px] max-md:h-[12px]"
          key={i}
          name="carousel"
          type="radio"
          checked={selected === i}
          style={{ accentColor: "black" }}
        />
      );
    }

    return list;
  };
  return (
    <>
      {/* Carousel progress bar */}
      {getProgressBar(numberOfDots)}
    </>
  );
}

export default CarouselProgressBar;
