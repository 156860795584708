import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const login = async (data) => {
  return axiosInstance(`/auth/login`, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const logout = async (data) => {
  return axiosInstance(`/auth/logout`, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const deleteAccount = async (data) => {
  return axiosInstance(`/auth/delete-account`, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const googleLogin = async (data) => {
  return axiosInstance(`/auth/google-login`, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const getUser = async (data) => {
  return axiosInstance(`/auth/users/${data.id}`, "GET", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const register = async (data) => {
  return axiosInstance(`/auth/register`, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const update = async (data) => {
  return axiosInstance(
    `/auth/users/${data.guid}`,
    "PUT",
    data,
    data.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const updatePassword = async (data) => {
  return axiosInstance(`/auth/change-password`, "POST", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const forgetPassword = async (data) => {
  return axiosInstance(`/auth/forgot-password`, "POST", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const resetPassword = async (data) => {
  return axiosInstance(`/auth/reset-password/${data.token}`, "PUT", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};
