import React from "react";
import FullRoundedButton from "./FullRoundedButton";
import { PiShoppingCartSimpleFill } from "react-icons/pi";

function AddedToCartButton() {
  return (
    <FullRoundedButton
      label={"Added to cart"}
      bgColor="white"
      textColor={"#FF9D21"}
      iconLeft={true}
      disabled={true}
      className={`bg-[white] text-gray-300 border-1 border-gray-300`}
    >
      <PiShoppingCartSimpleFill className="text-[16px]" />
    </FullRoundedButton>
  );
}

export default AddedToCartButton;
