import { addPaginationUrlQuery } from "../helpers/extractors";
import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";


export const fetchProductSubcategories = async () => {
    return axiosInstance(`/subcategories`, "GET", null, null).then(response => {
        return response;
    }).catch(err => {
        // return err
        getNetworkErrorMessage(err)
    })
}

export const fetchProductSubcategoryBySlug = async (slug, pageSize, pageNumber, productColors, productBrands, productTypes, productPrices, productSortFilter) => {
    let newUrl = addPaginationUrlQuery(`/subcategories/slug/${slug}`, pageSize, pageNumber)
    return axiosInstance(newUrl + `&productColors=${productColors}&productBrands=${productBrands}&productTypes=${productTypes}&productPrices=${productPrices}&productSortFilter=${productSortFilter}`, "GET", null, null).then(response => {
        return response;
    }).catch(err => {
        // return err
        getNetworkErrorMessage(err)
    })
}


