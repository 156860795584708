import React, { useState } from "react";
import { IoTrashBinOutline } from "react-icons/io5";
import { LiaEditSolid } from "react-icons/lia";
import useSelectedAddress from "../hooks/useSelectedAddress";
import CustomRadioButton from "./CustomRadioButton";

function ShippingAddressItem({
  radioName = "check",
  address,
  onEditClick,
  onDeleteClick,
  updateAddress,
  user,
  setIsError,
  setOpenSnackBar,
  setSnackBarMessage,
  isLoading,
  setDefaultAddressId,
  defaultAddressId,
  selectedAddressId,
  setSelectedAddressId,
}) {
  const [isDefault, setIsDefault] = useState(address?.isDefault);

  const handleSetDefault = () => {
    if (!isDefault) {
      setSelectedAddressId(address?.id);
    }
    setIsDefault(!isDefault);

    updateAddress({
      ...address,
      isDefault: !isDefault,
      userId: user?.id,
      accessToken: user?.accessToken,
    })
      .then((res) => {
        if (res.data.data.isDefault) {
          setSnackBarMessage("Address has been set as default");
        } else {
          setSnackBarMessage("Address has been removed as default");
        }
        setIsError(false);
        setOpenSnackBar(true);
        setDefaultAddressId((prev) => ++prev);
      })
      .catch((err) => {
        setSnackBarMessage(err?.message);
        setIsError(true);
        setOpenSnackBar(true);
      })
      .finally(() => {});
  };

  return (
    <div className="flex items-start my-5">
      <div className="w-[5%]">
        <CustomRadioButton
          radioName={radioName}
          checked={selectedAddressId === address?.id}
          onClick={handleSetDefault}
          disabled={isLoading}
        />
      </div>
      <div className="w-[88%] flex flex-col gap-3">
        <div className="font-[600] text-[14px] max-md:text-[12px]">
          {address?.firstName} {address?.lastName}
        </div>
        <div className="flex gap-2 items-center">
          <div>
            <img
              src="/assets/address-icon.png"
              alt="wudify-address-icon"
              loading="lazy"
            />
          </div>
          <div className="text-[#828282] text-[14px] max-md:text-[12px] font-[500]">
            {address?.address}
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div>
            <img
              src="/assets/telephone-icon.png"
              alt="wudify-telephone-icon"
              loading="lazy"
            />
          </div>
          <div className="text-[#828282] text-[14px] max-md:text-[12px] font-[500]">
            {address?.phoneNumber}
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div>
            <img
              src="/assets/email-icon.png"
              alt="wudify-email-icon"
              loading="lazy"
            />
          </div>
          <div className="text-[#828282] text-[14px] max-md:text-[12px] font-[500]">
            {address?.email}
          </div>
        </div>
      </div>
      <div className="flex max-sm:flex-wrap justify-end gap-3">
        <div
          onClick={onEditClick}
          className="flex items-center gap-1 text-[#FF9D21] text-[14px] max-md:text-[12px] font-[600] cursor-pointer"
        >
          <div>
            <LiaEditSolid className="text-[18px] max-lg:text-[15px] max-md:text-[12px]" />
          </div>
          <div>Edit</div>
        </div>
        <div
          onClick={onDeleteClick}
          className="flex items-center gap-1 text-red-600 text-[14px] max-md:text-[12px] font-[600] cursor-pointer"
        >
          <div>
            <IoTrashBinOutline className="text-[18px] max-lg:text-[15px] max-md:text-[12px] text-red-600" />
          </div>
          <div>Delete</div>
        </div>
      </div>
    </div>
  );
}

export default ShippingAddressItem;
