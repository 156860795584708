import { Form, Formik } from "formik";
import React, { useState } from "react";
import CardTitle from "../../commons/CardTitle";
import CustomInputField from "../../commons/CustomInputField";
import CustomSnackBar from "../../commons/CustomSnackBar";
import FullRoundedButton from "../../commons/FullRoundedButton";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import useUpdateUser from "../../hooks/useUpdateUser";
import useUser from "../../hooks/useUser";
import useWithdrawalForm from "../../hooks/useWithdrawalForm";
import { decryptUser } from "../../helpers/encryptor";
import { useQueryClient } from "@tanstack/react-query";
import { USER_QUERY_KEY } from "../../constants/queryKeys";

function AccountProfile() {
  const queryClient = useQueryClient();
  const { updateUser } = useUpdateUser();
  const { user } = useUser();
  const [currentUser, setCurrentUser] = useState(user);

  const [showWithdrawalForm, setShowWithdrawalForm] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const WithdrawalForm = useWithdrawalForm({
    showForm: showWithdrawalForm,
    setShowForm: setShowWithdrawalForm,
  });

  return (
    <div className="shadow-md rounded-lg w-[70%] max-lg:w-full bg-white p-6 text-left">
      <CardTitle title="My Profile" />

      {/* Form */}
      <Formik
        enableReinitialize
        initialValues={{
          firstName: currentUser?.firstName,
          lastName: currentUser?.lastName,
          email: currentUser?.email,
          phoneNumber: currentUser?.phoneNumber,
          guid: currentUser?.guid,
          accessToken: currentUser?.accessToken,
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          updateUser(values)
            .then((res) => {
              setSnackBarMessage("User successfully updated");
              setCurrentUser(decryptUser(res?.data?.data));
              queryClient.setQueryData([USER_QUERY_KEY], res?.data?.data);
              setIsError(false);
              setOpenSnackBar(true);
              resetForm();
            })
            .catch((err) => {
              setSnackBarMessage(err?.message);
              setIsError(true);
              setOpenSnackBar(true);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Email is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.lastName) {
            errors.lastName = "Last name is required";
          }
          if (!values.firstName) {
            errors.firstName = "First name is required";
          }
          if (!values.phoneNumber) {
            errors.phoneNumber = "Mobile number is required";
          }
          return errors;
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="flex max-md:flex-col items-center gap-4 max-md:gap-0 justify-between">
              <div className="w-full">
                <CustomInputField
                  label={"First Name"}
                  type="text"
                  placeholder="Please enter your first name"
                  name="firstName"
                  disabled={isSubmitting}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  label={"Last Name"}
                  type="text"
                  placeholder="Please enter your last name"
                  name="lastName"
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <CustomInputField
              label={"Email Address"}
              type="email"
              placeholder="Please enter your email address"
              name="email"
              disabled={isSubmitting}
            />
            <CustomInputField
              label={"Phone Number"}
              type="text"
              placeholder="Please enter your phone number"
              name="phoneNumber"
              disabled={isSubmitting}
            />
            <div className="w-[30%] max-md:w-1/2 mt-4">
              <FullRoundedButton
                disabled={isSubmitting}
                label={isSubmitting ? "Please wait" : "Update profile"}
                className="text-[14px] max-md:text-[12px] text-white bg-[#FF9D21]"
                type="submit"
              >
                {isSubmitting && (
                  <CustomThreeDotsSpinner
                    height="20"
                    width="20"
                    color="white"
                  />
                )}
              </FullRoundedButton>
            </div>
          </Form>
        )}
      </Formik>

      {/* Withdrawal Form */}
      {WithdrawalForm}

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default AccountProfile;
