import React from "react";
import BlogItem from "../../commons/BlogItem";
import Layout from "../../commons/Layout";
import { useQuery } from "@tanstack/react-query";
import { getFurnitureGuides } from "../../services/furniture-guides-services";
import useFurnitureGuides from "../../hooks/useFurnitureGuides";
import BlogItemLoader from "../../commons/BlogItemLoader";

function FurnitureGuide() {
  const { furnitureGuides, isLoading } = useFurnitureGuides();

  return (
    <Layout>
      <div className="flex justify-between items-center bg-[#FFFAF8] py-24 max-lg:py-18 max-md:py-12">
        <div className="w-[70%] max-lg:w-[80%] max-md:w-[95%] text-center mx-auto ">
          <div className="w-[100%] mx-auto">
            <h4 className="text-[#667185] text-[16px] max-md:text-[12px] font-[500]">
              Furniture Guide
            </h4>
            <div className="text-[54px] max-lg:text-[44px] max-md:text-[30px] font-[600]">
              Unlock the Art of Furniture Selection with Wudify's Guide
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="w-[82%] max-md:w-[95%] mx-auto">
        <h3 className="text-[#667185] text-[20px] max-lg:text-[17px] max-md:text-[13px] font-[500] text-left mt-24 max-md:mt-12 max-md:mb-6 mb-12">
          Browse all furniture guides
        </h3>

        {isLoading && (
          <>
            <BlogItemLoader />
            <div className="my-12"></div>
            <BlogItemLoader reversed />
          </>
        )}

        {furnitureGuides?.map((blog, index) => {
          return (
            <>
              <div className=""></div>
              <BlogItem
                blog={blog}
                reversed={index % 2 !== 0}
                link={`/inspiration/furniture-guides/${blog?.slug}`}
              />
              <div className="mb-28 max-md:mb-14"></div>
            </>
          );
        })}
      </div>
    </Layout>
  );
}

export default FurnitureGuide;
