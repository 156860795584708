import React from "react";
import { addOrRemoveFromList, getFileUrl } from "../helpers/extractors";

function SubCategoryMiniItem({
  subCategory,
  imageKey,
  itemClassWidth,
  selected,
  itemsList,
  dispatch,
  actionType,
}) {
  const addToCheckedItems = (value) => {
    let list = itemsList;
    let newList = [];
    if (list.includes(value.toString())) {
      newList = addOrRemoveFromList(value.toString(), list, -1);
    } else {
      newList = addOrRemoveFromList(value.toString(), list, 1);
    }
    dispatch({ type: actionType, payload: newList });
  };

  return (
    <div
      className={`rounded-sm cursor-pointer min-w-[200px] max-md:min-w-[140px] ${
        selected ? "border border-2 border-gray-500 shadow-md" : ""
      }`}
      onClick={() => addToCheckedItems(subCategory?.id)}
    >
      <img
        alt=""
        src={getFileUrl(subCategory[imageKey]) || "/assets/office.png"}
        className="rounded-sm w-full h-[97px] object-cover"
        loading="lazy"
      />
      <p className="font-[500] mt-2 text-[14px] max-md:text-[12px]">
        {subCategory.name}
      </p>
    </div>
  );
}

export default SubCategoryMiniItem;
