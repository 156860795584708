import React, { useState } from "react";
import CardTitle from "../../commons/CardTitle";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CustomInputField from "../../commons/CustomInputField";
import CustomTextField from "../../commons/CustomTextField";
import FullRoundedButton from "../../commons/FullRoundedButton";
import useNewAddress from "../../hooks/useNewAddress";
import CustomSnackBar from "../../commons/CustomSnackBar";
import useUser from "../../hooks/useUser";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import CustomSelectField from "../../commons/CustomSelectField";
import CustomSelectFieldModified from "../../commons/CustomSelectFieldModified";
import useStates from "../../hooks/useStates";
import useTowns from "../../hooks/useTowns";

function AccountNewAddress({ onBackClick, onAddressAdded }) {
  const { saveNewAddress } = useNewAddress(onAddressAdded);
  const { user } = useUser();

  const { states: stateList } = useStates();
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const { towns } = useTowns(state);

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <div className="shadow-md rounded-lg w-[100%] bg-white p-6 text-left">
      <div className="flex justify-between items-start">
        <div className="">
          <CardTitle title="Add New Address" />
        </div>
        <div
          onClick={onBackClick}
          className=" flex gap-2 text-[16px] font-[600] items-center text-[#318EC9] cursor-pointer"
        >
          <div>
            <MdOutlineArrowBackIos />
          </div>
          <div>Back</div>
        </div>
      </div>

      {/* Form */}
      <div>
        <Formik
          initialValues={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            phoneNumber: user?.phoneNumber,
            address: "",
            city: "",
            zipCode: "",
            state: "",
            region: "",
            email: user?.email,
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            saveNewAddress({
              ...values,
              userId: user?.id,
              accessToken: user?.accessToken,
            })
              .then((res) => {
                setSnackBarMessage("Address added successfully");
                setIsError(false);
                setOpenSnackBar(true);
                setTimeout(() => {
                  onBackClick();
                }, 3000);
                resetForm();
              })
              .catch((err) => {
                setSnackBarMessage(err?.message);
                setIsError(true);
                setOpenSnackBar(true);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.lastName) {
              errors.lastName = "Last name is required";
            }
            if (!values.firstName) {
              errors.firstName = "First name is required";
            }
            if (!values.phoneNumber) {
              errors.phoneNumber = "Mobile number is required";
            }
            if (!values.address) {
              errors.address = "Address is required";
            }
            if (!values.state || values.state === "") {
              setState("");
              errors.state = "State is required";
            } else {
              setState(values.state);
            }
            if (!values.region) {
              errors.region = "Region is required";
            } else {
              setRegion(values.region);
            }
            if (!values.zipCode) {
              errors.zipCode = "Zip code is required";
            }

            return errors;
          }}
        >
          {({ isSubmitting, setFieldError, setFieldValue, values, errors }) => (
            <Form>
              <div className="flex max-md:flex-col items-center justify-between gap-4 max-md:gap-0">
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"First Name"}
                    type="text"
                    placeholder="Please enter your first name"
                    name="firstName"
                    disabled={isSubmitting}
                  />
                </div>
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"Last Name"}
                    type="text"
                    placeholder="Please enter your last name"
                    name="lastName"
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <CustomTextField
                label={"Address"}
                type="text"
                placeholder="e.g 31 Herbert Macaulay road, Yaba"
                name="address"
                disabled={isSubmitting}
              />

              {/* State and Town */}
              <div className="flex max-md:flex-col items-center justify-between gap-4 max-md:gap-0">
                <div className="w-1/2 max-md:w-full">
                  <CustomSelectFieldModified
                    name={"state"}
                    required={true}
                    placeholder="Select state"
                    type="text"
                    label={"State"}
                    valueKey={"state_code"}
                    keyValue={"name"}
                    selectedValue={state}
                    list={stateList?.data ?? []}
                    onChange={(value) => setFieldValue("state", value)}
                    setFieldError={setFieldError}
                  />
                </div>
                <div className="w-1/2 max-md:w-full">
                  <div className="w-full">
                    {!state && (
                      <CustomInputField
                        disabled={true}
                        placeholder="Select region"
                        name="region"
                        type="text"
                        label={"Region"}
                      />
                    )}
                    {state && (
                      <CustomSelectFieldModified
                        name={"region"}
                        required={true}
                        placeholder="Select region"
                        type="text"
                        label={"Region"}
                        valueKey={"name"}
                        keyValue={"name"}
                        selectedValue={region}
                        list={!towns?.data?.message ? towns?.data : []}
                        onChange={(value) => setFieldValue("region", value)}
                        setFieldError={setFieldError}
                        disabled={state == ""}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* State and Town code */}
              <div className="flex items-center justify-between gap-4">
                <div className="w-full">
                  <CustomInputField
                    label={"Zip Code"}
                    type="text"
                    placeholder="Please enter your zip code"
                    name="zipCode"
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              {/* Mobile Number and Email Address */}
              <div className="flex max-md:flex-col items-center justify-between gap-4 max-md:gap-0">
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"Mobile Number"}
                    type="text"
                    placeholder="Please enter your mobile number"
                    name="phoneNumber"
                    disabled={isSubmitting}
                  />
                </div>
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"Email Address"}
                    type="email"
                    placeholder="Please enter your email address"
                    name="email"
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              {/* Submit button */}
              <FullRoundedButton
                label={isSubmitting ? "Please wait" : "Save address"}
                className={"text-white bg-[#FF9D21] hover:bg-[#FF9D21] mt-3"}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <CustomThreeDotsSpinner
                    height="20"
                    width="20"
                    color="white"
                  />
                )}
              </FullRoundedButton>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mb-10 max-md:mb-5"></div>
      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default AccountNewAddress;
