import CryptoJS from "crypto-js";

export const generateRandomIV = () => {
  return CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_PASS);
};

export const IV = generateRandomIV();

export const encryptData = (text, iv = IV) => {
  const keywordArray = CryptoJS.enc.Utf8.parse(
    process.env.REACT_APP_SECRET_PASS
  );

  const data = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(JSON.stringify(text)),
    keywordArray,
    {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  const encryptedData = data.toString();

  return encryptedData;
};

export const decryptData = (text, iv = IV) => {
  try {
    const keywordArray = CryptoJS.enc.Utf8.parse(
      process.env.REACT_APP_SECRET_PASS
    );

    const decrypted = CryptoJS.AES.decrypt(text, keywordArray, {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (err) {}
};

export const decryptUser = (user) => {
  if (!user) {
    return null;
  }

  if (!isValid(user)) {
    return null;
  }

  const decryptedUser = {
    accessToken: decryptData(user.accessToken),
    id: decryptData(user.id),
    guid: decryptData(user.guid),
    email: decryptData(user.email),
    phoneNumber: decryptData(user.phoneNumber),
    firstName: decryptData(user.firstName),
    lastName: decryptData(user.lastName),
    loginType: decryptData(user.loginType),
    Addresses: decryptData(user.Addresses),
  };
  if (isValid(decryptedUser)) {
    return decryptedUser;
  }

  return null;
};

export const decryptObject = (obj) => {
  if (obj) {
    const encryptedObj = {};
    for (const x in obj) {
      encryptedObj[x] = decryptData(obj[x]);
    }

    return encryptedObj;
  }
};

const hasValue = (val) => {
  if (val && val != null && val != "null") {
    return true;
  }

  return false;
};

export const isValid = (user) => {
  return (
    hasValue(user.email) &&
    hasValue(user.id) &&
    hasValue(user.firstName) &&
    hasValue(user.lastName) &&
    hasValue(user.phoneNumber) &&
    hasValue(user.loginType)
  );
};
