import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";
import { addPaginationUrlQuery } from "../helpers/extractors";



export const fetchUserWishListItems = async (data, pageSize, pageNumber) => {
    let newUrl = addPaginationUrlQuery("/wishlists", pageSize, pageNumber)
    return axiosInstance(newUrl, "GET", data, data.accessToken).then(response => {
        return response;
    }).catch(err => {
        getNetworkErrorMessage(err)
    })
}

export const addToWishlist = async (data) => {
    return axiosInstance(`/wishlists`, "POST", data, data.accessToken).then(response => {
        return response;
    }).catch(err => {
        // return err
        getNetworkErrorMessage(err)
    })
}

export const updateWishList = async (data) => {
    return axiosInstance(`/wishlists/${data.id}`, "PUT", data, data.accessToken).then(response => {
        return response;
    }).catch(err => {


        // return err
        getNetworkErrorMessage(err)
    })
}

export const deleteWishList = async (data) => {
    return axiosInstance(`/wishlists/${data.id}`, "DELETE", data, data.accessToken).then(response => {
        return response;
    }).catch(err => {
        // return err
        getNetworkErrorMessage(err)
    })
}

