import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoCartOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CustomNoRecordFound from "../../commons/CustomNoRecordFound";
import CustomSnackBar from "../../commons/CustomSnackBar";
import CartItem from "./CartItem";

function CartDetails({ cartItemsData, setCartUpdated }) {
  const navigate = useNavigate();
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <div className="p-5 rounded-lg bg-white shadow-md">
      <div className="flex justify-between  items-center">
        <div className="flex gap-3 items-center">
          <div className="font-[600] text-[20px] max-lg:text-[17px] max-md:text-[14px]">
            My Cart
          </div>
          <div className="w-[30px] h-[30px] max-md:w-[20px] max-md:h-[20px] rounded-full text-white bg-[#FF9D21] p-2 flex items-center justify-center">
            {cartItemsData?.cartItems.length ?? 0}
          </div>
        </div>
        <div
          className="flex items-center cursor-pointer gap-4 text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[600] text-[#318EC9]"
          onClick={() => navigate("/")}
        >
          <div>Continue shopping</div>
          <div>
            <IoIosArrowForward />
          </div>
        </div>
      </div>

      {cartItemsData?.cartItems?.length === 0 && (
        <div className="mt-12">
          <CustomNoRecordFound
            buttonText={"Shop now"}
            label={"No item has been added to cart yet."}
            moreLabel={"Shop and add items to cart."}
            onButtonClick={() => navigate("/")}
          >
            <IoCartOutline style={{ fontSize: "15em" }} />
          </CustomNoRecordFound>
        </div>
      )}

      {/* Cart items */}
      <div className="mt-8 flex flex-col gap-6">
        {cartItemsData?.cartItems?.map((cartItem) => {
          return (
            <CartItem
              item={cartItem}
              setOpenSnackBar={setOpenSnackBar}
              setIsError={setIsError}
              setSnackBarMessage={setSnackBarMessage}
              setCartUpdated={setCartUpdated}
            />
          );
        })}
      </div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default CartDetails;
