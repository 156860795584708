import axiosInstance from "../utils/axiosInstance";


export const getDecorTips = async (data) => {
    return axiosInstance(`/decor-tips`, "GET", data).then(response => {
        return response;
    })
}

export const getDecorTipBySlug = async (data) => {
    return axiosInstance(`/decor-tips/by/${data.slug}`, "GET", data).then(response => {
        return response;
    })
}