import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { MY_WUDIFY_QUERY_KEY } from '../constants/queryKeys';
import { getMyWudifys } from '../services/mywudify-service';

function useMyWudifys() {
    const navigate = useNavigate()
    const { data: mywudifys, isLoading, error } = useQuery({
        queryKey: [MY_WUDIFY_QUERY_KEY],
        queryFn: async () => {
            const result = await getMyWudifys()
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        mywudifys, isLoading, error
    }
}

export default useMyWudifys