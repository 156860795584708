import React from "react";

function CartItemLoader({ item }) {
  return (
    <div className="flex justify-between gap-6">
      <div className="flex gap-3 w-[70%]">
        <div className="h-[120px] w-[120px] bg-gray-100"></div>
        <div className="flex flex-col justify-around">
          <p className="font-[600] text-[18px] w-[12em] h-[20px] bg-gray-100"></p>
          <p className="font-[600] text-[18px] w-[10em] h-[20px] bg-gray-100"></p>
          <p className="font-[600] text-[18px] w-[8em] h-[20px] bg-gray-100"></p>
        </div>
      </div>

      <div className="flex w-[60%]">
        <div className="flex flex-col justify-around text-right pr-10">
          <p className="font-[500] text-[14px] bg-gray-100 h-[20px] w-[10em]"></p>
          <p className="font-[500] text-[14px] bg-gray-100 h-[20px] w-[10em]"></p>
          <div className="flex gap-3 justify-end">
            <div className="bg-gray-100 h-[2em] w-[6em] rounded-full"></div>
            <div className="bg-gray-100 h-[2em] w-[6em] rounded-full"></div>
          </div>
        </div>
      </div>

      <div className="flex-col flex w-[4%] justify-center">
        <p className="bg-gray-100 h-[20px] w-[2em]"></p>
      </div>
    </div>
  );
}

export default CartItemLoader;
