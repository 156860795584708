import React, { useState } from "react";
import { product_sub_categories } from "../../mock_data/product_categories";
import SubCategoryItem from "../../commons/SubCategoryItem";
import SlidingArrows from "../../commons/SlidingArrows";
import useProductSubcategories from "../../hooks/useProductSubcategories";
import SectionThumbnailLoader from "../../commons/loaders/SectionThumbnailLoader";

function SubCategorySection() {
  const [index, setIndex] = useState(0);

  const { productSubcategories, isLoading } = useProductSubcategories();

  const changeIndex = (step) => {
    if (step > 0) {
      increment();
    } else {
      decrement();
    }
  };

  const increment = () => {
    if (index < productSubcategories?.length - 4) {
      setIndex((prev) => ++prev);
    }
  };

  const decrement = () => {
    if (index > 0) {
      setIndex((prev) => --prev);
    }
  };

  return (
    <>
      {!isLoading && (
        <div
          className="relative w-[95%] mx-auto"
          style={{ overflow: "hidden" }}
        >
          <div
            className="mx-auto gap-4"
            style={{
              width: "100%",
              display: "flex",
              overflow: "",
              transition: "all 1.5s ease",
              transform: `translateX(${index * -315}px)`,
            }}
          >
            {productSubcategories?.map((sub_cat) => {
              return (
                <>
                  <a
                    href={`/sub-categories/${sub_cat?.slug}`}
                    className="hover:opacity-80"
                  >
                    <SubCategoryItem subCategory={sub_cat} />
                  </a>
                </>
              );
            })}
          </div>
          <SlidingArrows
            leftPositionClass="left-0"
            topPositionClass="top-[70px] max-md:top-[50px]"
            showLeftArrow={index > 0}
            showRightArrow={index < productSubcategories?.length - 4}
            widthClass="w-[100%]"
            onLeftArrowClick={() => changeIndex(-1)}
            onRightArrowClick={() => changeIndex(+1)}
          />
        </div>
      )}

      {isLoading && <SectionThumbnailLoader />}
    </>
  );
}

export default SubCategorySection;
