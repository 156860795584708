import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { ADDRESS_BOOK } from '../constants/data';
import { ADDRESS_QUERY_KEY, USER_QUERY_KEY } from '../constants/queryKeys';
import { addNewAddress } from "../services/address-services";

function useNewAddress(callback) {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: saveNewAddress, isLoading, error, data } = useMutation({
        mutationFn: (addressData) => addNewAddress(addressData),
        onSuccess: (data) => {
            queryClient.invalidateQueries(ADDRESS_QUERY_KEY);
            queryClient.invalidateQueries(USER_QUERY_KEY);
            setTimeout(() => {
                if (callback) {
                    callback()
                } else {
                    navigate(`/my-account?tab=${ADDRESS_BOOK}`)
                }
            }, 3000)
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        saveNewAddress, isLoading, error, data
    }
}

export default useNewAddress