export const LIGHT_BLUE_COLOR = "#318EC9";
export const DOTS = "...";
export const MOST_POPULAR = "MOST_POPULAR";
export const NEW_TO_OLD = "NEW_TO_OLD";
export const OLD_TO_NEW = "OLD_TO_NEW";

export const PENDING = "pending";

export const PNG_FILE_TYPE = "image/png";
export const TEXT_FILE_TYPE = "text/plain";
export const JPEG_FILE_TYPE = "image/jpeg";
export const JPG_FILE_TYPE = "image/jpg";
export const WEBP_FILE_TYPE = "image/webp";

export const PDF_FILE_TYPE = "application/pdf";
export const DOCX_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const CURRENCY_SYMBOL = "₦";

export const GENERAL_BLUE_HEX = "#318EC9";
export const GENERAL_GRAY_HEX = "#828282";
export const GENERAL_ORANGE_HEX = "#FF9D21";
