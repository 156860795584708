import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { ORDER_ITEMS_QUERY_KEY } from '../constants/queryKeys';
import { fetchUserOrderItems } from '../services/order-services';

function useUserOrders(user, pageSize, pageNumber) {
    const navigate = useNavigate()
    const { data: userOrderItems, isLoading, error } = useQuery({
        queryKey: [ORDER_ITEMS_QUERY_KEY, pageSize, pageNumber],
        queryFn: async () => {
            const result = await fetchUserOrderItems(user, pageSize, pageNumber)
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        userOrderItems, isLoading, error
    }
}

export default useUserOrders