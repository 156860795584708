import React from "react";

function CustomRadioButton({
  radioName,
  accentColor = "black",
  onClick,
  checked,
  disabled,
}) {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };
  return (
    <div
      name={radioName}
      type="radio"
      checked={checked}
      onClick={handleClick}
      className={`w-[20px] h-[20px] max-md:w-[14px] max-md:h-[14px] border-2 mt-1 flex items-center ${
        !disabled ? "cursor-pointer" : "cursor-not-allowed"
      } justify-center border rounded-full`}
      style={{ accentColor }}
    >
      <div
        className={`w-[12px] h-[12px] max-md:w-[8px] max-md:h-[8px] rounded-full ${
          checked ? "bg-gray-800" : ""
        }`}
      ></div>
    </div>
    // <input
    //   name={radioName}
    //   type="radio"
    //   checked={checked}
    //   onChange={onChange}
    //   className="w-[20px] h-[20px] mt-1"
    //   style={{ accentColor }}
    // />
  );
}

export default CustomRadioButton;
