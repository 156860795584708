import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { PRODUCT_SUB_CATEGORY_QUERY_KEY } from '../constants/queryKeys';
import { fetchProductSubcategoryBySlug } from '../services/product_subcategories-services';

function useProductSubcategory(slug, pageSize, pageNumber, productColors, productBrands, productTypes, productPrices, productSortFilter) {
    const navigate = useNavigate();
    const { data: productSubcategory, isLoading, error } = useQuery({
        queryKey: [PRODUCT_SUB_CATEGORY_QUERY_KEY, slug, pageSize, pageNumber, productColors, productBrands, productTypes, productPrices, productSortFilter],
        queryFn: async () => {
            const result = await fetchProductSubcategoryBySlug(slug, pageSize, pageNumber, productColors, productBrands, productTypes, productPrices, productSortFilter)
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        productSubcategory, isLoading, error
    }
}

export default useProductSubcategory