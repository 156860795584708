import React, { useEffect, useState } from "react";
import CardTitle from "../../commons/CardTitle";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import ShippingAddressItem from "../../commons/ShippingAddressItem";
import useUserAddress from "../../hooks/useUserAddress";
import useUser from "../../hooks/useUser";
import ShippingAddressItemLoader from "../../commons/loaders/ShippingAddressItemLoader";
import AccountEditAddress from "./AccountEditAddress";
import CustomSnackBar from "../../commons/CustomSnackBar";
import useUpdateAddress from "../../hooks/useUpdateAddress";
import GeneralModal from "../../commons/GeneralModal";
import ConfirmationModal from "../../commons/modals/ConfirmationModal";
import FullRoundedButton from "../../commons/FullRoundedButton";
import CustomLabelValue from "../../commons/CustomLabelValue";
import useDeleteAddress from "../../hooks/useDeleteAddress";
import CustomNoRecordFound from "../../commons/CustomNoRecordFound";
import { TiContacts } from "react-icons/ti";

function AccountAddressBook({
  onNewClick,
  onEditClick,
  widthClass = "w-[70%] max-lg:w-full",
}) {
  const { user } = useUser();
  const { updateAddress, isLoading: updateAddressLoading } =
    useUpdateAddress(onEditClick);
  const { deleteAddress, isLoading: deleteAddressLoading } = useDeleteAddress();
  const [defaultAddressId, setDefaultAddressId] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const { userAddresses = [], isLoading } = useUserAddress(
    user,
    selectedAddress,
    defaultAddressId
  );

  const [selectedAddressId, setSelectedAddressId] = useState(0);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteClick = (address) => {
    setSelectedAddress(address);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedAddress(null);
    setIsDeleting(false);
  };

  const handleDeleteAddress = (data) => {
    setIsDeleting(true);
    deleteAddress(data)
      .then((res) => {
        setSnackBarMessage("Address deleted successfully");
        setIsError(false);
        setOpenSnackBar(true);
        handleCloseDeleteModal();
      })
      .catch((err) => {
        setSnackBarMessage(err?.message);
        setIsError(true);
        setOpenSnackBar(true);
      })
      .finally(() => {});
  };

  useEffect(() => {
    setSelectedAddressId(userAddresses?.find((x) => x.isDefault)?.id ?? 0);
  }, [userAddresses]);

  return (
    <>
      {(!selectedAddress || openDeleteModal) && (
        <div
          className={`shadow-md rounded-lg ${widthClass} bg-white p-6 text-left`}
        >
          <div className="flex justify-between items-center mb-4">
            <CardTitle title="My Address Book" className="!mb-0" />
            <div
              onClick={onNewClick}
              className="flex gap-2 text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[600] items-center text-[#318EC9] cursor-pointer"
            >
              <div>Add New Address</div>
              <div>
                <MdOutlineArrowForwardIos />
              </div>
            </div>
          </div>
          {isLoading && (
            <>
              <ShippingAddressItemLoader />
              <hr />
              <ShippingAddressItemLoader />
              <hr />
            </>
          )}
          <div className="mb-10">
            {!isLoading && (
              <>
                {userAddresses?.length > 0 ? (
                  <p className="font-[500] text-[14px] max-md:text-[12px] text-gray-400">
                    Select an address to set as default
                  </p>
                ) : (
                  <CustomNoRecordFound
                    buttonText={"Add new address"}
                    label="You have no addresses yet"
                    moreLabel="You can create one or more addresses"
                    onButtonClick={onNewClick}
                  >
                    <TiContacts className="text-[100px] text-orange-300" />
                  </CustomNoRecordFound>
                )}
                {userAddresses?.map((address) => {
                  return (
                    <>
                      <ShippingAddressItem
                        onEditClick={() => setSelectedAddress(address)}
                        onDeleteClick={() => handleDeleteClick(address)}
                        address={address}
                        updateAddress={updateAddress}
                        isLoading={updateAddressLoading}
                        user={user}
                        setIsError={setIsError}
                        setOpenSnackBar={setOpenSnackBar}
                        setSnackBarMessage={setSnackBarMessage}
                        setDefaultAddressId={setDefaultAddressId}
                        defaultAddressId={defaultAddressId}
                        selectedAddressId={selectedAddressId}
                        setSelectedAddressId={setSelectedAddressId}
                      />
                      <hr />
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      )}
      {selectedAddress && !openDeleteModal && (
        <AccountEditAddress
          address={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          onBackClick={() => setSelectedAddress(null)}
          setIsError={setIsError}
          setOpenSnackBar={setOpenSnackBar}
          setSnackBarMessage={setSnackBarMessage}
          updateAddress={updateAddress}
          user={user}
        />
      )}

      {/* Modal to delete address */}
      <ConfirmationModal
        isOpen={openDeleteModal}
        onClose={handleCloseDeleteModal}
        showCloseButton={true}
        description="Are you sure you want to delete the following address?"
        title="Delete Address"
      >
        <>
          <CustomLabelValue
            label="First name"
            value={selectedAddress?.firstName}
          />
          <CustomLabelValue
            label="Last name"
            value={selectedAddress?.lastName}
          />
          <CustomLabelValue label="Address" value={selectedAddress?.address} />
          <CustomLabelValue label="Email" value={selectedAddress?.email} />
          <CustomLabelValue
            label="Mobile Number"
            value={selectedAddress?.phoneNumber}
          />
          <CustomLabelValue label="City/Town" value={selectedAddress?.city} />
          <CustomLabelValue label="Zip code" value={selectedAddress?.zipCode} />

          <div className="text-right flex justify-end mt-12 gap-3">
            <FullRoundedButton
              label="Cancel"
              type="button"
              className="bg-[#D0D5DD]"
              onClick={handleCloseDeleteModal}
              disabled={isDeleting}
            />
            <FullRoundedButton
              label={isDeleting ? "Please wait" : "Delete address"}
              type="button"
              className="bg-[#FF0000] text-white"
              disabled={deleteAddressLoading}
              onClick={() =>
                handleDeleteAddress({
                  id: selectedAddress?.id,
                  userId: user?.id,
                  accessToken: user?.accessToken,
                })
              }
            />
          </div>
        </>
      </ConfirmationModal>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </>
  );
}

export default AccountAddressBook;
