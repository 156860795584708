import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { ADDRESS_BOOK } from '../constants/data';
import { USER_QUERY_KEY } from '../constants/queryKeys';
import { deleteUserAddress } from "../services/address-services";

function useDeleteAddress(user) {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: deleteAddress, isLoading, error, data } = useMutation({
        mutationFn: (addressData) => deleteUserAddress(addressData),
        onSuccess: (data) => {
            queryClient.invalidateQueries(USER_QUERY_KEY);

            // navigate(`/my-account?tab=${ADDRESS_BOOK}`)
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        deleteAddress, isLoading, error, data
    }
}

export default useDeleteAddress