import { verifyPayment } from "../services/payment-service";
import { currencyFormat } from "./currency";

export const handleCartCheckout = (
  userCartItems,
  user,
  paymentMethod,
  callbackSuccess,
  callbackVerify,
  callbackConfirm,
  callbackFailure,
  onClose
) => {
  const amount = userCartItems?.actualPriceInKobo;
  const componentProps = {
    email: user?.email,
    amount: amount,
    metadata: {
      name: `${user?.firstName} ${user?.lastName}`,
      phone: user?.phoneNumber,
      paymentMethod,
    },
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    text: `Pay ${currencyFormat(userCartItems?.totalPrice)}`,
    onSuccess: (response) => {
      callbackVerify();
      verifyPayment({
        response,
        user: {
          accessToken: user.accessToken,
          email: user.email,
          Addresses: user.Addresses,
          id: user.id,
        },
        userCartItems,
        paymentMethod,
      })
        .then((res) => {
          callbackSuccess(
            `/order-confirmation/${res.data.data.reference_number}`
          );
        })
        .catch((err) => {
          callbackFailure(err);
        })
        .finally(() => {
          callbackConfirm();
        });
    },
    onClose,
    // onError: () => {
    //     alert("error")
    // }

    // onClose: () => alert("Wait! You need this oil, don't go!!!!"),
  };

  return componentProps;
};

export const handleBuyNowCheckout = (
  userBuyNowItem,
  user,
  paymentMethod,
  callbackSuccess,
  callbackVerify,
  callbackConfirm,
  callbackFailure,
  onClose
) => {
  const amount = userBuyNowItem?.actualPriceInKobo;
  const componentProps = {
    email: user?.email,
    amount: amount,
    metadata: {
      name: `${user?.firstName} ${user?.lastName}`,
      phone: user?.phoneNumber,
      paymentMethod,
    },
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    text: `Pay ${currencyFormat(userBuyNowItem?.totalPrice)}`,
    onSuccess: (response) => {
      callbackVerify();
      verifyPayment({
        response,
        user: {
          accessToken: user.accessToken,
          email: user.email,
          Addresses: user.Addresses,
          id: user.id,
        },
        userCartItems: { cartItems: [userBuyNowItem] },
        paymentMethod,
        type: "buy-now",
      })
        .then((res) => {
          callbackSuccess(
            `/order-confirmation/${res.data.data.reference_number}`
          );
        })
        .catch((err) => {
          callbackFailure();
        })
        .finally(() => {
          callbackConfirm();
        });
      //   console.log(result, "result");
    },
    onClose,
    // onClose: () => alert("Wait! You need this oil, don't go!!!!"),
  };

  return componentProps;
};
