import React from "react";

function ProductItemMiniLoader() {
  return (
    <div className="cursor-pointer hover:opacity-70 product-item-mini-loader">
      <div
        className="h-[250px] w-[320px] bg-gray-100 mb-6"
        style={{ overflow: "hidden" }}
      ></div>
      <div className="" style={{ marginTop: "" }}>
        <div className="flex items-center justify-between gap-1">
          <div className="h-[2.5em] w-[150px] bg-gray-100"></div>
          <div className="h-[2.5em] w-[150px] bg-gray-100"></div>
        </div>
        <div className="flex justify-between mt-3 text-gray-500 gap-1">
          <div className="h-[2em] w-[150px] bg-gray-100"></div>
          <div className="h-[2em] w-[150px] bg-gray-100"></div>
        </div>
        <div className="mt-3">
          <div className="h-[2em] w-[320px] bg-gray-100"></div>
        </div>
      </div>
    </div>
  );
}

export default ProductItemMiniLoader;
