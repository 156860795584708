import React from "react";
import ReviewStar from "./ReviewStar";
import { getDateTime } from "../helpers/dateTime";
import { AiOutlineUser } from "react-icons/ai";

function ReviewItem({ review }) {
  return (
    <div className="flex gap-0 mb-6">
      <div className="w-[8%]">
        <AiOutlineUser className="rounded-full border bg-gray-300 p-1 flex items-center justify-center text-[4.5em] max-md:text-[2em]" />
      </div>
      <div className="w-[90%]">
        <div className="md:mb-2">
          <ReviewStar value={review?.rating ?? 0} />
        </div>
        <div className="font-[600] text-[16px] max-lg:text-[14px] max-md:text-[12px] mb-2 max-md:mb-1">
          {review?.User?.firstName} {review?.User?.lastName}
        </div>
        <div className="font-[600] text-[14px] max-md:text-[12px] mb-3 max-md:mb-1 text-[#667185]">
          {review?.title}
        </div>
        <div className="font-[500] text-[13px] max-md:text-[10px] mb-3 max-md:mb-1 text-[#667185]">
          {review?.comment}
        </div>
        <div className="font-[500] text-[12px] max-md:text-[10px] text-[#FF9D21]">
          {getDateTime(review?.createdAt)}
        </div>
      </div>
    </div>
  );
}

export default ReviewItem;
