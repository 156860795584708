function HeadingTitle({
  title,
  colorClass = "text-black",
  textAlignClass = "text-left",
  fontSizeClass = "text-[24px] max-lg:text-[20px] max-md:text-[16px]",
}) {
  return (
    <div className={`${textAlignClass} ${fontSizeClass} font-[600] `}>
      {title}
    </div>
  );
}

export default HeadingTitle;
