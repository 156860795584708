import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoCartOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CustomNoRecordFound from "../../commons/CustomNoRecordFound";
import CustomSnackBar from "../../commons/CustomSnackBar";
import BuyNowItem from "./BuyNowItem";

function BuyNowDetails({ item, setCartUpdated, isLoading }) {
  const navigate = useNavigate();
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <div className="p-5 rounded-lg bg-white shadow-md">
      <div className="flex justify-between  items-center">
        <div className="flex gap-3 items-center">
          <div className="font-[600] text-[20px] max-lg:text-[16px] max-md:text-[14px]">
            Item details
          </div>
          <div className="w-[30px] h-[30px] max-md:w-[20px] max-md:h-[20px] rounded-full text-white bg-[#FF9D21] p-2 flex items-center justify-center">
            {item ? 1 : 0}
          </div>
        </div>
        <div
          className="flex items-center cursor-pointer gap-4 text-[16px] max-md:text-[14px] font-[600] text-[#318EC9]"
          onClick={() => navigate("/")}
        >
          <div>Continue shopping</div>
          <div>
            <IoIosArrowForward />
          </div>
        </div>
      </div>

      {!isLoading && !item && (
        <div className="mt-12">
          <CustomNoRecordFound
            buttonText={"Shop now"}
            label={"No item to buy yet."}
            moreLabel={"Shop now and buy items"}
            onButtonClick={() => navigate("/")}
          >
            <IoCartOutline style={{ fontSize: "15em" }} />
          </CustomNoRecordFound>
        </div>
      )}

      {/* Buy now item */}
      <div className="mt-8 flex flex-col gap-6">
        {!isLoading && item && (
          <BuyNowItem
            item={item}
            setOpenSnackBar={setOpenSnackBar}
            setIsError={setIsError}
            setSnackBarMessage={setSnackBarMessage}
            setCartUpdated={setCartUpdated}
          />
        )}
      </div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default BuyNowDetails;
