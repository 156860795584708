import { useQuery } from '@tanstack/react-query';
import { getFurnitureGuideBySlug } from "../services/furniture-guides-services";
import { FURNITURE_GUIDE_QUERY_KEY } from "../constants/queryKeys";



function useFurnitureGuide({ slug }) {
    let blogDetails = {};

    const furnitureGuide = useQuery({
        queryKey: [FURNITURE_GUIDE_QUERY_KEY],
        queryFn: async () => {
            const result = await getFurnitureGuideBySlug({ slug });
            if (result.data.error) {
            }
            return result;
        },
        onError: (error) => {
            console.log("error when fetching furniture guide", error);
        },
    });

    blogDetails = furnitureGuide?.data?.data?.data ?? {};

    return {
        blogDetails,
        isLoading: furnitureGuide.isLoading
    }
}

export default useFurnitureGuide