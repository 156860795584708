import React from "react";

function SectionThumbnailLoader() {
  return (
    <div className="flex flex-wrap max-sm:justify-center w-[95%] mx-auto justify-between section-thumbnail-loader-container">
      <div className="">
        <div className="h-[200px] w-[295px] bg-gray-100"></div>
        <p className="w-[12em] mx-auto h-[2em] bg-gray-100 mt-4"></p>
      </div>
      <div>
        <div className="h-[200px] w-[295px] bg-gray-100"></div>
        <p className="w-[12em] mx-auto h-[2em] bg-gray-100 mt-4"></p>
      </div>
      <div>
        <div className="h-[200px] w-[295px] bg-gray-100"></div>
        <p className="w-[12em] mx-auto h-[2em] bg-gray-100 mt-4"></p>
      </div>
      <div>
        <div className="h-[200px] w-[295px] bg-gray-100"></div>
        <p className="w-[12em] mx-auto h-[2em] bg-gray-100 mt-4"></p>
      </div>
    </div>
  );
}

export default SectionThumbnailLoader;
