import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { PENDING_REVIEWS } from '../constants/data';
import { PENDING_REVIEWS_QUERY_KEY } from '../constants/queryKeys';
import { addReview } from '../services/order-services';

function useAddReview() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { mutateAsync: addToReview, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => addReview(updateData),
        onSuccess: (data) => {
            queryClient.invalidateQueries(PENDING_REVIEWS_QUERY_KEY);
            navigate(`/my-account?tab=${PENDING_REVIEWS}`)
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        addToReview, isLoading, error, data
    }
}

export default useAddReview