import React from "react";

function PageTopDescription({
  heading,
  description,
  subHeading,
  descriptionClassName = "text-[#667085]",
  subHeadingClassName = "font-[500] text-[50px] max-lg:text-[40px] max-md:text-[30px]",
}) {
  return (
    <div>
      <div className="font-[500] text-[18px] max-md:text-[14px] text-[#FF9D21] mb-2">
        {heading}
      </div>
      <div className={`font-[500] mb-2 ${subHeadingClassName}`}>
        {subHeading}
      </div>
      <div
        className={`text-[16px] max-md:text-[12px] font-[400] mb-2 ${descriptionClassName}`}
      >
        {description}
      </div>
    </div>
  );
}

export default PageTopDescription;
