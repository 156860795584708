import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { PRODUCT_SUB_CATEGORY_QUERY_KEY } from '../constants/queryKeys';
import { fetchProductTypeBySlug } from '../services/product_types-services';

function useProductType(slug, pageSize, pageNumber, productColors, productBrands, productTypes, productPrices, productSortFilter) {
    const navigate = useNavigate();
    const { data: productType, isLoading, error } = useQuery({
        queryKey: [PRODUCT_SUB_CATEGORY_QUERY_KEY, slug, pageSize, pageNumber, productColors, productBrands, productTypes, productPrices, productSortFilter],
        queryFn: async () => {
            const result = await fetchProductTypeBySlug(slug, pageSize, pageNumber, productColors, productBrands, productTypes, productPrices, productSortFilter)
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        productType, isLoading, error
    }
}

export default useProductType