import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { CART_ITEM, CART_ITEM_QUERY_KEY } from '../constants/queryKeys';
import { fetchUserBuyNowByReference } from '../services/buynow-services';

function useUserBuyNowByReference(data) {
    const navigate = useNavigate()
    const { data: userBuyNowItem, isLoading, error } = useQuery({
        queryKey: [data],
        queryFn: async () => {
            const result = await fetchUserBuyNowByReference(data)
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        userBuyNowItem, isLoading, error
    }
}

export default useUserBuyNowByReference