import React, { useState } from "react";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import DividedPage from "../../commons/DividedPage";
import CardTitle from "../../commons/CardTitle";
import CustomRadioButton from "../../commons/CustomRadioButton";
import FullRoundedButton from "../../commons/FullRoundedButton";
import CartPriceItem from "../../commons/CartPriceItem";
import { PaystackButton } from "react-paystack";
import { useNavigate } from "react-router-dom";
import AccountNewAddress from "../account/AccountNewAddress";
import AccountAddressBook from "../account/AccountAddressBook";
import useUser from "../../hooks/useUser";
import useUserBuyNowByReference from "../../hooks/useUserBuyNowByReference";
import { handleBuyNowCheckout } from "../../helpers/checkout";
import { useParams } from "react-router-dom";
import CustomBackdrop from "../../commons/CustomBackdrop";
import { paymentTypes } from "../../constants/data";

function BuyNowCheckout() {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useUser();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [alternative, setAlternative] = useState(false);

  const [paymentType, setPaymentType] = useState(paymentTypes[0]);

  const { slug } = params;

  const { userBuyNowItem, isLoading } = useUserBuyNowByReference({
    ...user,
    userId: user?.id,
    reference: slug,
    computeShipping: true,
  });

  console.log("this should be good")

  const paymentTypeExists = (paymentType) => {
    for (const pType of paymentTypes) {
      if (
        pType.label === paymentType.label &&
        pType.value === paymentType.value
      ) {
        return true;
      }
    }
  };

  const safeToCheckout = (userAddresses) => {
    if (
      paymentTypeExists(paymentType) &&
      userBuyNowItem &&
      userAddresses?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const leftSectionAlternative = () => {
    return (
      <div>
        <AccountNewAddress
          onAddressAdded={() => setAlternative(false)}
          onBackClick={() => {
            setAlternative(false);
          }}
        />
      </div>
    );
  };

  const rightSection = () => {
    return (
      <div>
        <CardTitle title="Order Summary" />
        <div className="my-4">
          <hr />
        </div>
        <div>
          <div className="font-[600] text-[16px] mb-3 mt-5">Pay with</div>
          {paymentTypes.map((pType) => {
            return (
              <div className="flex gap-2 items-center mb-2">
                <CustomRadioButton
                  accentColor="black"
                  radioName={"payment-type"}
                  checked={paymentType.label === pType.label}
                  onClick={() => {
                    setPaymentType(pType);
                  }}
                ></CustomRadioButton>
                <div className="font-[500] text-[16px]">{pType.label}</div>
              </div>
            );
          })}
        </div>
        <div className="my-7">
          <hr />
        </div>
        <div className="font-[600] text-[16px] mb-3 mt-5">Apply discount</div>
        <div className="flex items-center gap-2">
          <div className="w-[68%]">
            <input
              className="py-3 px-5 text-[14px] font-[400] rounded-full w-full bg-gray-100 outline-none"
              placeholder="Enter Coupon Code"
            />
          </div>
          <div className="w-[25%]">
            <FullRoundedButton
              className={
                "bg-white text-[#FF9D21] hover:bg-[#FF9D21] hover:text-white"
              }
              label={"Apply"}
            />
          </div>
        </div>
        <div className="my-7">
          <hr />
        </div>

        {/*  */}
        <CartPriceItem
          title="Sub Total"
          price={`N${userBuyNowItem?.subTotalPrice}`}
        />

        <CartPriceItem
          title="Shipping"
          price={`N${userBuyNowItem?.shipping}`}
        />
        <div className="my-7">
          <hr />
        </div>
        <CartPriceItem title="Total" price={`N${userBuyNowItem?.totalPrice}`} />

        {safeToCheckout(user?.Addresses) ? (
          <PaystackButton
            className={
              "hover:opacity-90 bg-[#FF9D21] text-white w-full mt-10 py-3 rounded-full font-[500] text-[16px]"
            }
            {...handleBuyNowCheckout(
              userBuyNowItem,
              user,
              paymentType.label,
              (url) => {
                navigate(url, { replace: true });
              },
              () => {
                setOpenBackdrop(true);
              },
              () => {
                setOpenBackdrop(false);
              },
              () => {},
              () => {}
            )}
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  if (!userBuyNowItem && !isLoading) {
    navigate("/");
  }

  return (
    <Layout bgClassName="bg-gray-100">
      <>
        {!openBackdrop && (
          <div className="w-[85%] mx-auto pt-8">
            <PageBreadCrumb
              mainText={"Home"}
              subText={"Buy Now"}
              otherText={"Checkout"}
              isActiveIndex={2}
            />
            <DividedPage
              leftSection={
                alternative ? (
                  leftSectionAlternative()
                ) : (
                  <AccountAddressBook
                    onNewClick={() => setAlternative(true)}
                    onEditClick={() => {
                      setAlternative(false);
                    }}
                    widthClass={"w-[100%]"}
                  />
                )
              }
              rightSection={rightSection()}
              removeParent={true}
            ></DividedPage>
          </div>
        )}
        <CustomBackdrop open={openBackdrop} text={"Please wait..."} />
      </>
    </Layout>
  );
}

export default BuyNowCheckout;
