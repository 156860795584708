import { Tooltip } from "@mui/material";
import React from "react";

function CustomOutOfStockDisplay({ quantity, className, inCart = false }) {
  const addToBills = !inCart ? "" : "Not added to your bills. ";
  const title =
    "This product is temporarily out of stock due to high demand. We will replenish it as soon as possible";
  return (
    <>
      {quantity < 1 && (
        <Tooltip title={addToBills + title}>
          <div className={className}>
            <span
              className={`flex flex-col text-[12px] max-md:text-[10px] text-orange-400 text-right font-[500] p-2 ${
                inCart ? "" : "bg-red-100"
              } cursor-pointer`}
            >
              OUT OF STOCK
              {inCart && (
                <span className="text-gray-800 text-[11px] max-md:text-[9px] text-right">
                  Not added
                </span>
              )}
            </span>
          </div>
        </Tooltip>
      )}
    </>
  );
}

export default CustomOutOfStockDisplay;
