import React from "react";

function PaginationInfo({ pageSize, pageNumber, totalRecord }) {
  return (
    <div>
      <div className="">
        Showing{" "}
        <span className="text-orange-300 font-[500]">
          {pageSize * (pageNumber - 1) + 1} -{" "}
          {pageSize * (pageNumber - 1) + pageSize > totalRecord
            ? totalRecord
            : pageSize * (pageNumber - 1) + pageSize}
        </span>{" "}
        of
        <span className="text-orange-300 font-[500]"> {totalRecord}</span>{" "}
        result(s)
      </div>
    </div>
  );
}

export default PaginationInfo;
