import React from "react";
import Layout from "../../commons/Layout";
import PageTopDescription from "../../commons/PageTopDescription";
import QuestionAndAnswer from "../../commons/QuestionAndAnswer";
import SearchInput from "../../commons/SearchInput";
import { useQuery } from "@tanstack/react-query";
import { getFaqs } from "../../services/faqs-service";
import QuestionAndAnswerLoaders from "../../commons/loaders/QuestionAndAnswerLoaders";

function Faq() {
  let faqs_data = [];

  // Queries
  const faqs = useQuery({
    queryKey: ["faqs"],
    queryFn: async () => {
      const result = await getFaqs();
      if (result.data.error) {
      }
      return result;
    },
    onError: (error) => {
      console.log("error when fetching faqs", error);
    },
  });

  faqs_data = faqs?.data?.data?.data;

  return (
    <Layout>
      <div className="w-[70%] max-lg:w-[80%] max-md:w-[90%] mx-auto py-24 max-lg:py-12">
        <PageTopDescription
          heading={"FAQ"}
          subHeading="Ask Us Anything"
          description="Need something cleared up? Here are our most frequently asked questions."
        />

        <div className="w-[35%] max-lg:w-1/2 max-md:w-4/5 mx-auto mt-3 mb-8">
          <SearchInput placeholder={"Search"} className="" />
        </div>
        <div className="font-[600] text-[40px] max-lg:text-[30px] max-md:text-[20px] mb-8">
          Frequently Asked Questions
        </div>

        {faqs.isLoading && <QuestionAndAnswerLoaders />}

        {faqs_data?.map((faq, index) => {
          return (
            <QuestionAndAnswer
              number={index + 1}
              question={faq.question}
              answer={faq.answer}
            />
          );
        })}
      </div>
    </Layout>
  );
}

export default Faq;
