import React, { useState } from "react";
import SearchInput from "./SearchInput";
import { FiHeart } from "react-icons/fi";
import { IoCartOutline } from "react-icons/io5";
import CartModal from "../pages/cart/CartModal";
import "./css/login.css";
import { useLocation } from "react-router-dom";
import { SAVED_ITEMS } from "../constants/data";
import useUser from "../hooks/useUser";
import LoginUserComponent from "./LoginUserComponent";
import useUserCart from "../hooks/useUserCart";
import useUserWishList from "../hooks/useUserWishlist";
import ProductSearchItem from "./ProductSearchItem";
import useSearchProducts from "../hooks/useSearchProducts";
import useDebounce from "../hooks/useDebounce";
import ProductSearchItemLoader from "./loaders/ProductSearchItemLoader";
import ProductSearchItemNotFound from "./ProductSearchItemNotFound";

function TopHeader() {
  const [openModal, setOpenModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearch = useDebounce(searchValue, 500);

  const { products: fetchedProducts, isLoading: searchItemLoading } =
    useSearchProducts(debouncedSearch);

  const location = useLocation();

  const { user } = useUser();
  const { userWishListItems } = useUserWishList(user);
  const { userCartItems, isLoading } = useUserCart(user);

  const handleSearchValueChange = (value) => {
    setSearchValue(value);
  };

  const isHomePage = () => {
    if (location.pathname === "/") {
      return true;
    }

    return false;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        className="flex max-[1000px]:flex-wrap gap-6 max-[1000px]:gap-2 justify-between px-10 max-lg:px-6 max-md:px-3 items-center py-4 fixed top-0 w-full bg-white"
        style={{ zIndex: "10000" }}
      >
        {/* Left */}
        <div className="flex gap-10 max-md:gap-4 items-center w-1/3 max-[1000px]:w-full max-[1000px]:justify-center max-md:justify-between">
          <div className="w-[15em] h-[5em] max-md:w-[10em] max-sm:w-[8em] max-sm:h-[3em] flex items-center">
            {isHomePage() && (
              <img
                alt="Wudify-logo"
                src="/assets/wudify_logo_top_header.png"
                loading="lazy"
                className="cursor-pointer"
                style={{ objectFit: "contain" }}
                onClick={scrollToTop}
              />
            )}
            {!isHomePage() && (
              <a href="/">
                <img
                  alt="Wudify-logo"
                  src="/assets/wudify_logo_top_header.png"
                  loading="lazy"
                  style={{ objectFit: "contain" }}
                />
              </a>
            )}
          </div>
          <a href={process.env.REACT_APP_VENDOR_URL}>
            <span className="font-[500] text-[15px] max-md:text-[12px]">
              Be a Partner
            </span>
          </a>
        </div>

        {/* Center */}
        <div className="w-1/3 max-sm:w-3/5 relative">
          <SearchInput
            placeholder={"Search products"}
            onChange={handleSearchValueChange}
            value={searchValue}
          />

          <div
            className="absolute w-full flex flex-col gap-2 bg-white"
            style={{ maxHeight: "80vh", overflowY: "auto" }}
          >
            {searchItemLoading && <ProductSearchItemLoader />}
            {!searchItemLoading &&
              searchValue.length > 2 &&
              fetchedProducts &&
              fetchedProducts.length === 0 && <ProductSearchItemNotFound />}
            {!searchItemLoading &&
              fetchedProducts &&
              (fetchedProducts ?? [])?.map((product, index) => {
                return <ProductSearchItem product={product} />;
              })}
          </div>
        </div>

        {/* Right */}
        <div className="w-1/3 flex gap-4 justify-end text-[15px] font-[500]">
          <a
            className="flex items-center"
            href={`/my-account?tab=${SAVED_ITEMS}`}
          >
            <div className="relative flex items-center gap-1">
              <FiHeart
                className={`text-[22px] max-md:text-[20px] font-[600]`}
              />
              {userWishListItems?.wishlistItems?.length > 0 && (
                <span className="text-[8px] font-bold absolute left-[13px] text-white top-[-8px] flex items-center justify-center p-2 bg-orange-500 rounded-full w-[0.5em] h-[0.5em]">
                  {userWishListItems?.wishlistItems?.length ?? 0}
                </span>
              )}
              <p className="text-[15px] max-md:text-[12px] max-md:hidden">
                Saved Items
              </p>
            </div>
          </a>

          {/* <a href="/my-cart"> */}

          <div className="relative">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => setOpenModal(true)}
            >
              <div className="relative flex items-center gap-1">
                <IoCartOutline
                  className={`text-[22px] max-md:text-[20px] font-[600]`}
                />
                {userCartItems?.cartItems?.length > 0 && (
                  <span className="text-[8px] font-bold absolute left-[13px] text-white top-[-8px] flex items-center justify-center p-2 bg-orange-500 rounded-full w-[0.5em] h-[0.5em]">
                    {userCartItems?.cartItems?.length ?? 0}
                  </span>
                )}
                <p className="text-[15px] max-md:text-[12px] max-md:hidden">
                  Cart
                </p>
              </div>
              {/* <span className="mr-3">&nbsp;</span> */}
            </div>
            {openModal && (
              <div
                className="absolute min-w-[35vw] max-md:min-w-[60vw] max-h-[500px] overflow-y-auto rounded-lg p-2 bg-white right-[-100px] max-sm:right-[-60px] top-[48px] max-md:top-[45px]"
                style={{ zIndex: "10000" }}
              >
                <CartModal
                  userCartItems={userCartItems}
                  isLoading={isLoading}
                  open={openModal}
                  onClose={() => setOpenModal(false)}
                />
              </div>
            )}
          </div>

          {/* </a> */}
          <LoginUserComponent user={user} />
        </div>
      </div>
    </>
  );
}

export default TopHeader;
