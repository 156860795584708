import React from "react";
import { CiSearch } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";

function SearchInput({
  placeholder,
  onChange,
  className = "bprder-black border-1",
  value = "",
}) {
  return (
    <div className="h-auto">
      <div
        className={`flex ${className} gap-2 w-full mx-auto border rounded-md p-2.5`}
      >
        <span>
          <CiSearch className="text-lg" />
        </span>
        <input
          className="outline-none w-full"
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          value={value}
        />
        <span className="cursor-pointer" onClick={() => onChange("")}>
          <IoMdClose className="text-lg" />
        </span>
      </div>
    </div>
  );
}

export default SearchInput;
