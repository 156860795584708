import React from "react";
import { LiaEditSolid } from "react-icons/lia";
import CustomRadioButton from "../CustomRadioButton";

function ShippingAddressItemLoader({ radioName = "check", address }) {
  return (
    <div className="flex items-start my-5">
      <div className="w-[5%] ">
        <div className="h-[2em] w-[2em] rounded-full bg-gray-100"></div>
      </div>
      <div className="w-[88%] flex flex-col gap-3">
        <div className="font-[600] text-[14px] w-[30%] h-[1.5em] bg-gray-100"></div>
        <div className="flex gap-2 items-center">
          <div className="bg-gray-100 w-[5%] h-[1.5em]"></div>
          <div className="bg-gray-100 w-[60%] h-[1.5em]"></div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="bg-gray-100 w-[5%] h-[1.5em]"></div>
          <div className="bg-gray-100 w-[60%] h-[1.5em]"></div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="bg-gray-100 w-[5%] h-[1.5em]"></div>
          <div className="bg-gray-100 w-[60%] h-[1.5em]"></div>
        </div>
      </div>
      <div className="flex gap-1 text-[#FF9D21] text-[14px] font-[600] cursor-pointer">
        <div className="bg-gray-100 w-[1em] h-[1.5em]"></div>
        <div className="bg-gray-100 w-[3em] h-[1.5em]"></div>
      </div>
    </div>
  );
}

export default ShippingAddressItemLoader;
