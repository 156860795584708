import React from "react";
import { getFileUrl } from "../helpers/extractors";

function SubCategoryItem({ subCategory }) {
  return (
    <div className="rounded-sm shadow-sm w-[300px] max-lg:w-[250px] max-md:w-[200px]">
      <img alt="" src={getFileUrl(subCategory.thumbnailImagePath)} className="rounded-sm" loading="lazy" />
      <p className="font-[500] mt-2 text-[18px] max-lg:text-[14px] max-md:text-[12px]">{subCategory.name}</p>
    </div>
  );
}

export default SubCategoryItem;
