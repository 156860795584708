import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../commons/Layout";
import { BiArrowBack } from "react-icons/bi";
import BlogSection from "./BlogSection";
import { useQuery } from "@tanstack/react-query";
import { getFurnitureGuideBySlug } from "../../services/furniture-guides-services";
import useFurnitureGuide from "../../hooks/useFurnitureGuide";
import { getFileUrl } from "../../helpers/extractors";

function FurnitureGuideDetails() {
  const backLink = "/inspiration/furniture-guides";
  const params = useParams();
  const slug = params?.slug;

  const { blogDetails, isLoading } = useFurnitureGuide({ slug });

  return (
    <Layout>
      <div className="pb-24">
        <div className="bg-[#FFFAF8]">
          <div className="w-[70%] max-lg:w-[80%] max-md:w-[95%] mx-auto pt-12 text-left">
            {/* Back section */}

            <div className="flex font-[500] text-[15px] max-md:text-[12px] text-[#667185] mb-4">
              <a href={backLink}>
                <div className="flex gap-5 items-center cursor-pointer">
                  <BiArrowBack className="text-lg" />
                  <p>Back</p>
                </div>
              </a>
            </div>

            {/* Paage title */}
            <div>
              <h3 className="font-[600] text-[40px] max-lg:text-[32px] max-md:text-[24px] pb-10">
                {blogDetails?.title}
              </h3>
            </div>
          </div>
        </div>
        <div className="pt-24 bg-[#FFFAF8]"></div>
        <div className="" style={{ marginTop: "-8em" }}>
          <div className="mx-auto w-[70%] max-lg:w-[80%] max-md:w-[95%]">
            <img
              src={getFileUrl(blogDetails?.mainImagePath)}
              alt=""
              className="w-[980px] h-[383px] max-md:h-[250px] rounded-md"
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>

        <div className="w-[70%] max-lg:w-[80%] max-md:w-[95%] mx-auto text-left">
          <h3 className="text-[18px] max-md:text-[14px] font-[600] mt-8 mb-3">
            Introduction:
          </h3>
          <p className="text-[14px] max-md:text-[12px] font-[500] text-[#98A2B3]">
            {blogDetails?.introduction}
          </p>

          {/* Sections */}

          {blogDetails?.sections?.map((section) => {
            return (
              <BlogSection
                title={section.title}
                description={section.description}
                images={section.images}
              />
            );
          })}
        </div>
      </div>
      <div className="mb-24 max-md:mb-14"></div>
    </Layout>
  );
}

export default FurnitureGuideDetails;
