import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { VENDORS_QUERY_KEY } from '../constants/queryKeys';
import { getVendors } from '../services/vendors-services';

function useVendors() {
    const navigate = useNavigate()
    const { data: vendors, isLoading, error } = useQuery({
        queryKey: [VENDORS_QUERY_KEY],
        queryFn: async () => {
            const result = await getVendors()
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        vendors, isLoading, error
    }
}

export default useVendors