import React from "react";

function CustomLabelValue({ label, value }) {
  return (
    <div className="flex border text-[14px] max-lg:text-[12px] max-md:text-[10px]">
      <div className="w-[30%] bg-gray-400 text-left px-2 font-[500] py-2">
        {label}
      </div>
      <div className="w-[70%] py-2 text-left px-2">{value}</div>
    </div>
  );
}

export default CustomLabelValue;
