import React, { useState } from "react";
import Layout from "../../commons/Layout";
import CardTitleBig from "../../commons/CardTitleBig";
import CustomInputField from "../../commons/CustomInputField";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { Form, Formik } from "formik";
import CustomSnackBar from "../../commons/CustomSnackBar";
import useForgetPassword from "../../hooks/useForgetPassword";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import { encryptData } from "../../helpers/encryptor";

function ResetPassword() {
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const openSnackbar = (isError, message) => {
    setSnackBarMessage(message);
    setOpenSnackBar(true);
    setIsError(isError ? true : false);
  };

  const { forgetUserPassword } = useForgetPassword(openSnackbar);

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="flex items-center py-20">
        <div className="shadow-md rounded-lg  bg-white p-6 w-[32%] max-lg:w-3/5 max-md:w-4/5 mx-auto">
          <CardTitleBig title="Forgot your password?" />
          <p className="text-[#667185] text-[14px] max-md:text-[12px] font-[500] mb-5">
            Enter your login email and we’ll send you a link to reset your
            password.
          </p>

          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              forgetUserPassword({
                email: encryptData(values.email),
              })
                .then((res) => {
                  openSnackbar(false, "An email has been sent to you");
                  resetForm();
                })
                .catch((err) => {
                  openSnackbar(true, err?.message);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <CustomInputField
                  label={"Email Address"}
                  type="email"
                  placeholder="Please enter your email address"
                  name="email"
                  disabled={isSubmitting}
                />
                <RoundedMdButton
                  label={isSubmitting ? "Please wait" : "Forgot password"}
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-[#FF9D21] text-white hover:bg-orange-600"
                >
                  {isSubmitting && (
                    <CustomThreeDotsSpinner
                      height="20"
                      width="20"
                      color="white"
                    />
                  )}
                </RoundedMdButton>
                <a href="/auth/login">
                  <RoundedMdButton
                    label="Cancel"
                    disabled={isSubmitting}
                    className="bg-white text-black border hover:text-[#FF9D21]"
                  ></RoundedMdButton>
                </a>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </Layout>
  );
}

export default ResetPassword;
