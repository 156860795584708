import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function ProductSearchItemLoader() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex items-center border bg-white gap-4">
        <div className="h-[4.5em] w-[4.5em] bg-gray-100 cursor-pointer"></div>
        <div className="flex flex-col gap-1">
          <p className="bg-gray-100 w-[12em] h-[1em]"></p>
          <div className="flex text-[10px] gap-2 items-center">
            <p className="bg-gray-100 w-[4em] h-[1em]"></p>
            <p className="bg-gray-100 w-[1em] h-[1em]"></p>
            <p className="bg-gray-100 w-[4em] h-[1em]"></p>
            <p className="bg-gray-100 w-[1em] h-[1em]"></p>
            <p className="bg-gray-100 w-[4em] h-[1em]"></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductSearchItemLoader;
