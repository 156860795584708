import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { CART_ITEM_QUERY_KEY } from '../constants/queryKeys';
import { deleteCart } from "../services/cart-services";

function useDeleteCartItem() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { mutateAsync: deleteCartItem, isLoading, error, data } = useMutation({
        mutationFn: (updateData) => deleteCart(updateData),
        onSuccess: (data) => {
            queryClient.invalidateQueries(CART_ITEM_QUERY_KEY)
            // navigate("/my-account")
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        deleteCartItem, isLoading, error, data
    }
}

export default useDeleteCartItem