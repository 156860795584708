import React from "react";

function ContactCard({ title, description, contact }) {
  return (
    <div className="rounded-lg p-5 text-left">
      <div className="w-[20.11px] h-[20px]">
        <img
          src="/assets/contact-us-icon.png"
          className="w-full h-full"
          style={{ objectFit: "cover" }}
          alt="contact-us-icon"
        />
      </div>
      <div className="text-[18px] font-[500] text-[#101828] mt-3">{title}</div>
      <div className="text-[16px] font-[400] text-[#667085] my-2">
        {description}
      </div>
      <div className="text-[#318EC9] text-[16px] font-[500]">{contact}</div>
    </div>
  );
}

export default ContactCard;
