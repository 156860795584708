import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { STATES_QUERY_KEY } from "../constants/queryKeys";
import { getStateList } from "../services/states-service";

function useStates() {
  const navigate = useNavigate();
  const {
    data: states,
    isLoading,
    error,
  } = useQuery({
    queryKey: [STATES_QUERY_KEY],
    queryFn: async () => {
      const result = await getStateList();
      return result?.data?.data;
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    states,
    isLoading,
    error,
  };
}

export default useStates;
