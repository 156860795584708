import React from "react";
import Layout from "../../commons/Layout";
import PageTopDescription from "../../commons/PageTopDescription";
import QuestionAndAnswer from "../../commons/QuestionAndAnswer";
import { useQuery } from "@tanstack/react-query";
import { getShippingReturnsStatements } from "../../services/shipping-return-statements-services";
import QuestionAndAnswerLoaders from "../../commons/loaders/QuestionAndAnswerLoaders";

function ShippingReturns() {
  let statements_data = [];

  // Queries
  const statements = useQuery({
    queryKey: ["statements"],
    queryFn: async () => {
      const result = await getShippingReturnsStatements();
      if (result.data.error) {
      }
      return result;
    },
    onError: (error) => {
      console.log("error when fetching statements", error);
    },
  });

  statements_data = statements?.data?.data?.data;

  const getCategories = (data) => {
    let categories = [];
    data?.forEach((d) => {
      if (!categories.includes(d.category)) {
        categories.push(d.category);
      }
    });

    return categories;
  };

  return (
    <Layout>
      <div className="w-[70%] max-lg:w-4/5 max-md:w-[90%] mx-auto py-24 max-md:py-12">
        <PageTopDescription
          heading={"Shipping & Returns"}
          subHeading="Shipping and Returns Policy for Furniture, Lighting, Home/Space Accessories"
          description="Thank you for choosing Wudify for your home and space needs. We understand the special care required for fragile items such as furniture, lighting, and home accessories. Please review our shipping and returns policy tailored to ensure a safe and satisfactory experience for you:"
          subHeadingClassName="max-lg:!text-[32px] max-md:!text-[24px]"
        />
        <div className="mt-10"></div>
        {statements.isLoading && <QuestionAndAnswerLoaders />}

        {getCategories(statements_data).map((category) => {
          return (
            <div className="mb-8">
              <div className="text-left text-[32px] max-lg:text-[26px] max-md:text-[20px] font-[600]">
                {category}:
              </div>
              {statements_data
                ?.filter((data) => data.category == category)
                ?.map((faq, index) => {
                  return (
                    <QuestionAndAnswer
                      number={index + 1}
                      question={faq.title}
                      answer={faq.answer}
                    />
                  );
                })}
            </div>
          );
        })}

        {/* Contact Information */}
        <div className="text-left mt-8">
          <div className="font-[600] text-[24px] max-lg:text-[20px] max-md:text-[16px] text-[#18181B]">
            Contact Information
          </div>
          <div className="text-[#52525B] font-[400] text-[16px] max-lg:text-[14px] max-md:text-[12px] my-4">
            For any inquiries regarding the shipping and returns of fragile
            items, please contact our specialized customer support team at
            contact@wudify.com.
          </div>
          <div className="text-[#52525B] font-[400] text-[16px] max-lg:text-[14px] max-md:text-[12px]">
            We appreciate your trust in Wudify, and we are committed to ensuring
            that your experience exceeds expectations, especially when it comes
            to your furniture, lighting, and home accessories needs.
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ShippingReturns;
