import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { TYPES_BY_SUBCAT_SLUG_QUERY_KEY } from '../constants/queryKeys';
import { fetchTypesBySubCategory } from '../services/product_types-services';

function useTypesBySubcategory(subcategorySlug) {
    const navigate = useNavigate();
    const { data: types, isLoading, error } = useQuery({
        queryKey: [TYPES_BY_SUBCAT_SLUG_QUERY_KEY, subcategorySlug],
        queryFn: async () => {
            const result = await fetchTypesBySubCategory(subcategorySlug)
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        types, isLoading, error
    }
}

export default useTypesBySubcategory