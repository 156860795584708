import React, { useReducer, useState } from "react";
import Layout from "../../commons/Layout";
import TypeHero from "./TypeHero";
import ContentBodySection from "./ContentBodySection";
import { useParams } from "react-router-dom";
import useProductType from "../../hooks/useProductType";
import { NEW_TO_OLD } from "../../constants/texts";
import { filterReducer } from "../../reducers/productFilterReducer";
import useDebounce from "../../hooks/useDebounce";
import useVendors from "../../hooks/useVendors";
import useColors from "../../hooks/useColors";
import { FaFilter } from "react-icons/fa";

function Type() {
  const params = useParams();
  const { slug } = params;

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const initialState = {
    colorFiltersArray: [],
    brandFiltersArray: [],
    typeFiltersArray: [],
    priceFiltersArray: [0, 100],
    productSortFilter: NEW_TO_OLD,
  };

  const { colors } = useColors();
  const { vendors } = useVendors();

  const [state, dispatch] = useReducer(filterReducer, initialState);
  const deboundedState = useDebounce(state, 1000);

  const { productType, isLoading } = useProductType(
    slug,
    pageSize,
    pageNumber,
    deboundedState.colorFiltersArray,
    deboundedState.brandFiltersArray,
    deboundedState.typeFiltersArray,
    deboundedState.priceFiltersArray,
    deboundedState.productSortFilter
  );

  return (
    <Layout>
      <div>
        <TypeHero name={productType?.name} />
        {/* <ProductSection subcategories={productType?.Subcategory} /> */}
        <div className="hidden max-sm:flex justify-end w-[95%] mx-auto mt-8">
          <div
            onClick={() => setIsFilterModalOpen((prev) => !prev)}
            className="flex items-center gap-2 w-fit border border-gray-400 rounded-md px-4 py-1 text-[14px] max-sm:text-[12px]"
          >
            <FaFilter />
            <p>Filters</p>
          </div>
        </div>
        <ContentBodySection
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalRecord={productType?.totalRecord ?? 0}
          filters={state}
          dispatch={dispatch}
          id={productType?.id}
          type={productType}
          colorList={colors}
          brandList={vendors}
          priceMax={productType?.priceMax ?? 100000}
          isFilterModalOpen={isFilterModalOpen}
          setIsFilterModalOpen={setIsFilterModalOpen}
        />
      </div>
    </Layout>
  );
}

export default Type;
