import React from "react";

function QuestionAndAnswer({ number, question, answer }) {
  return (
    <div className="mt-4">
      <div className="flex gap-2 text-[24px] max-lg:text-[20px] max-md:text-[16px] font-[600] mb-4 text-[#18181B]">
        <div className="">{number}.</div>
        <div>{question}</div>
      </div>
      <div className="text-[#52525B] text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[400] text-left mb-4">
        {answer}
      </div>
      <hr />
    </div>
  );
}

export default QuestionAndAnswer;
