import React from "react";
import { getFileUrl } from "./../helpers/extractors";

function BrandThumbnailItem({ brand }) {
  return (
    <div className="h-[40px] w-[200px] max-md:w-[150px]">
      <img
        className=""
        src={getFileUrl(brand.imagePath)}
        alt={`wudify_with_brand_${brand.name}`}
        loading="lazy"
      />
    </div>
  );
}

export default BrandThumbnailItem;
