import React from "react";
import { BsBox2 } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import { IoIosHeartEmpty } from "react-icons/io";
import { MdOutlineRateReview } from "react-icons/md";
import { PiCaretDownBold } from "react-icons/pi";
import { RxTriangleUp } from "react-icons/rx";
import { TfiLock } from "react-icons/tfi";
import { Link, useNavigate } from "react-router-dom";
import {
  MY_ORDERS,
  MY_PROFILE,
  PENDING_REVIEWS,
  SAVED_ITEMS,
} from "../constants/data";
import AccountLinkItemMini from "./AccountLinkItemMini";
import LoginButton from "./LoginButton";
import { CgProfile } from "react-icons/cg";

function LoginUserComponent({ user }) {
  const navigate = useNavigate();
  return (
    <>
      {!user && (
        <Link to={"/auth/login"}>
          <div className="relative flex gap-1 items-center login-container hover:text-[#FF9D21]">
            <CgProfile className="text-[20px] font-[600] hover:text-[#FF9D21]" />
            <div className="flex gap-1 cursor-pointer hover:text-[#FF9D21] text-[15px] max-md:text-[12px] items-center">
              <span className="hover:text-[#FF9D21] max-sm:hidden">Login</span>
              {/* <PiCaretDownBold className="text-lg font-bold" /> */}
            </div>
            {/* <div
            className="bg-white w-[8em] absolute top-10 max-md:right-0 border p-3 rounded-md login-option"
            style={{ zIndex: "100" }}
          >
            <RxTriangleUp
              className="absolute text-gray-100"
              style={{
                fontSize: "40px",
                fontWeight: "normal",
                top: "-24px",
                left: "26px",
              }}
            />
            <div className="flex gap-2 flex-col">
              <LoginButton
                label={"Customer"}
                onClick={() => (window.location.href = "/auth/login")}
              />
              <span className="font-semibold text-xs">Or</span>
              <LoginButton
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_VENDOR_URL}/auth/login`)
                }
                label={"Vendor"}
              />
            </div>
          </div> */}
          </div>
        </Link>
      )}
      {user && (
        <div
          className="relative flex gap-1 items-center login-container"
          style={{ zIndex: "100" }}
        >
          <CgProfile className="text-[20px] font-[600] hover:text-[#FF9D21]" />
          <div className="flex gap-1 cursor-pointer hover:text-[#FF9D21] items-center">
            <span className="hover:text-[#FF9D21] max-sm:hidden">
              {user?.firstName}
            </span>
            <PiCaretDownBold className="text-lg font-bold" />
          </div>
          <div
            className="bg-white w-[13em] right-0 absolute top-10 border p-3 rounded-md login-option"
            style={{ zIndex: "100" }}
          >
            <div
              className="w-[10em] absolute"
              style={{
                fontSize: "40px",
                fontWeight: "normal",
                top: "-18px",
                right: "0px",
              }}
            >
              <RxTriangleUp
                className="text-gray-100 relative"
                style={{
                  fontSize: "40px",
                  fontWeight: "normal",

                  top: "-6px",
                  right: "-8em",
                }}
              />
            </div>
            <div className="flex gap-2 flex-col items-start ">
              <div className="p-3 bg-white">
                <AccountLinkItemMini
                  label="My Profile"
                  link={`/my-account?tab=${MY_PROFILE}`}
                >
                  <FaRegUser />
                </AccountLinkItemMini>
                <AccountLinkItemMini
                  label="My Orders"
                  link={`/my-account?tab=${MY_ORDERS}`}
                >
                  <BsBox2 />
                </AccountLinkItemMini>
                <AccountLinkItemMini
                  label="Pending Reviews"
                  link={`/my-account?tab=${PENDING_REVIEWS}`}
                >
                  <MdOutlineRateReview />
                </AccountLinkItemMini>
                <AccountLinkItemMini
                  label="My Saved Items"
                  link={`/my-account?tab=${SAVED_ITEMS}`}
                >
                  <IoIosHeartEmpty />
                </AccountLinkItemMini>
                <hr className="mb-5" />

                <AccountLinkItemMini
                  label="Log out"
                  className="text-red-400"
                  link={`/auth/logout`}
                  onClick={() => navigate("/auth/logout")}
                >
                  <TfiLock />
                </AccountLinkItemMini>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginUserComponent;
