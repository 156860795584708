import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import CardTitle from "../../commons/CardTitle";
import CustomInputField from "../../commons/CustomInputField";
import CustomTextField from "../../commons/CustomTextField";
import CustomUploadInput from "../../commons/CustomUploadInput";
import FullRoundedButton from "../../commons/FullRoundedButton";
import GeneralModal from "../../commons/GeneralModal";
import useUserOrders from "../../hooks/useUserOrders";
import useUser from "../../hooks/useUser";
import { BsBox2 } from "react-icons/bs";
import Pagination from "../../commons/Pagination";
import AccountOrderDetails from "./AccountOrderDetails";
import AccountOrdersItem from "./AccountOrdersItem";
import AccountOrdersItemLoader from "../../commons/loaders/AccountOrdersItemLoader";
import CustomNoRecordFound from "../../commons/CustomNoRecordFound";
import { useNavigate } from "react-router-dom";
import ProductItemMini from "../../commons/ProductItemMini";
import { getFileUrl } from "../../helpers/extractors";
import ProductImageUploaderUI from "../../commons/ProductImageUploaderUI";
import useInitiateReturn from "../../hooks/useInitiateReturn";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import CustomBackdrop from "../../commons/CustomBackdrop";

function AccountOrders({ onViewClick }) {
  const navigate = useNavigate();

  const PAGE_SIZE = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [isInitiating, setIsInitiating] = useState(false);

  const { user } = useUser();
  const { userOrderItems, isLoading } = useUserOrders(
    user,
    PAGE_SIZE,
    currentPage
  );

  const { initiateReturnRequest } = useInitiateReturn();
  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [currentPage, selectedItem]);

  return (
    <>
      {!selectedItem && (
        <div className="shadow-md rounded-lg w-[70%] max-lg:w-full bg-white p-6 text-left">
          <CardTitle title="My Orders" />
          {isLoading && <AccountOrdersItemLoader />}
          {!isLoading && userOrderItems?.orders?.length === 0 && (
            <div className="mt-20">
              <CustomNoRecordFound
                buttonText={"Shop now"}
                label={"You don't have any orders yet."}
                moreLabel="Shop now and make your orders."
                onButtonClick={() => navigate("/")}
              >
                <BsBox2 style={{ fontSize: "10em" }} />
              </CustomNoRecordFound>
            </div>
          )}
          {!isLoading &&
            userOrderItems?.orders?.map((orderItem) => {
              return (
                <AccountOrdersItem
                  orderItem={orderItem}
                  onInitiateClick={() => {
                    setSelectedItem(orderItem);
                    setShowReturnModal(true);
                  }}
                  setSelectedItem={(e) => {
                    setSelectedItem(e);
                  }}
                />
              );
            })}

          <Pagination
            totalCount={userOrderItems?.totalRecords ?? 0}
            pageSize={PAGE_SIZE}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </div>
      )}

      <GeneralModal
        isOpen={showReturnModal}
        // widthClass={"w-[50%]"}
        showCloseButton={true}
        onClose={() => setShowReturnModal(false)}
      >
        <div style={{ height: "80vh", overflowY: "auto" }}>
          <CardTitle title="Return Form" className="text-left" />
          <div className="flex mt-4 mb-6 gap-3 items-center">
            <div className="w-[5em]">
              <img src={getFileUrl(selectedItem?.Product?.imageOne)} alt="" />
            </div>
            <div className="">
              <p className="font-[500] text-[14px]">
                {selectedItem?.Product?.name}
              </p>
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              containsFile: true,
              id: selectedItem?.id,
              subject: "",
              message: "",
              imageOne: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setIsInitiating(true);
              const images = [values.imageOne];
              if (values.imageTwo) {
                images.push(values.imageTwo);
              }
              if (values.imageThree) {
                images.push(values.imageThree);
              }
              initiateReturnRequest({
                // ...values,
                orderId: values.id,
                images: values.imageOne,
                reason: values?.message,
                accessToken: user?.accessToken,
              })
                .then((res) => {
                  setInfoDetails({
                    message: res?.data?.message,
                    isError: false,
                  });
                  resetForm();
                  setShowReturnModal(false);
                })
                .catch((err) => {
                  setInfoDetails({
                    message: err?.data?.message,
                    isError: false,
                  });
                })
                .finally(() => {
                  setIsInitiating(false);
                  setSubmitting(false);
                });
            }}
            validate={(values) => {
              const errors = {};
              if (!values.subject) {
                errors.subject = "Subject is required";
              }
              if (!values.imageOne) {
                errors.imageOne = "Image 1 is required";
              }

              return errors;
            }}
          >
            {({
              isSubmitting,
              values,
              setErrors,
              setFieldError,
              setFieldValue,
              errors,
            }) => (
              <Form>
                <CustomInputField
                  type="text"
                  name="subject"
                  label="Subject"
                  placeholder="Please enter subject"
                />
                <CustomTextField
                  type="text"
                  name="message"
                  label="Message"
                  placeholder="Please enter message"
                  rows={6}
                />
                <div className="flex max-sm:flex-col justify-between gap-3 mb-4">
                  <ProductImageUploaderUI
                    label={"Product Image 1"}
                    required
                    name={"imageOne"}
                    setFieldError={setFieldError}
                    onChange={(value) => setFieldValue("imageOne", value)}
                    setErrors={setErrors}
                    errors={errors}
                    file={values.imageOne}
                    disabled={isSubmitting}
                  />
                  <ProductImageUploaderUI
                    label={"Product Image 2"}
                    name={"imageTwo"}
                    setFieldError={setFieldError}
                    onChange={(value) => setFieldValue("imageTwo", value)}
                    setErrors={setErrors}
                    errors={errors}
                    file={values.imageTwo}
                    disabled={isSubmitting}
                  />
                  <ProductImageUploaderUI
                    label={"Product Image 3"}
                    name={"imageThree"}
                    setFieldError={setFieldError}
                    onChange={(value) => setFieldValue("imageThree", value)}
                    setErrors={setErrors}
                    errors={errors}
                    file={values.imageThree}
                    disabled={isSubmitting}
                  />
                </div>
                <div className="text-right flex justify-end">
                  <FullRoundedButton
                    label="Submit"
                    type="submit"
                    className="bg-[#FF9D21] text-white"
                    disabled={isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </GeneralModal>

      {selectedItem && (
        <>
          <AccountOrderDetails
            orderItem={selectedItem}
            onBackClick={() => setSelectedItem(null)}
            onInitiateClick={() => {
              setSelectedItem(selectedItem);
              setShowReturnModal(true);
            }}
          />
        </>
      )}

      {InfoDisplayComponent}

      {/* Spinner */}
      {isInitiating && (
        <CustomBackdrop text={"Please wait..."} open={isInitiating} />
      )}
    </>
  );
}

export default AccountOrders;
