import React from "react";
import { getFileUrl } from "./../../helpers/extractors";

function SaleHero({
  name = "Hot Sales",
  imagePath = "/assets/category_hero_image.jpg",
  text = "Shop from the ongoing sales and enjoy up to 40% discount this Black Friday season.",
}) {
  return (
    <div>
      <div className="h-[225px] relative">
        <img
          className="w-full h-full"
          style={{ objectFit: "cover", opacity: "" }}
          src={getFileUrl(imagePath)}
          alt=""
          loading="lazy"
        />
        {/* Overlay */}
        <div
          className="h-[225px] absolute w-full top-0 flex flex-col gap-4 items-center justify-center text-white"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
        >
          <h3 className="text-[40px] max-lg:text-[32px] max-md:text-[24px] font-[600]">
            {name}
          </h3>
          <p className="text-[15px] max-md:text-[12px] font-[500] w-[35%] max-lg:w-[60%] max-md:w-4/5">
            {text}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SaleHero;
