import React from "react";

function BlogItemLoader({ reversed }) {
  return (
    <div className={`flex ${reversed ? "flex-row-reverse" : ""} gap-[7em]`}>
      <div className="w-[50%] text-left">
        <div className="">
          <div
            className="text-[42px] font-[600] bg-gray-100 h-[1em]"
            style={{ lineHeight: "50.4px" }}
          ></div>
          <p className="text-[15px] font-[500] text-[#828282] my-5 h-[10em] bg-gray-100"></p>
          <button className="w-[8em] h-[3em] bg-gray-100 text-white rounded-md py-3 px-6 font-semibold hover:opacity-80"></button>
        </div>
      </div>
      <div className="w-[50%] bg-gray-100" style={{ height: "350px" }}></div>
    </div>
  );
}

export default BlogItemLoader;
