import React from "react";
import RelatedProductItem from "../../commons/RelatedProductItem";

function RelatedProductsSection({
  relatedProducts,
  mainProduct,
  relatedProductsPerPage,
}) {
  return (
    <div className="grid grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 gap-4 max-md:gap-2 mt-[1.2em]">
      {relatedProducts
        ?.filter((x, index) => index < relatedProductsPerPage)
        ?.map((product, x) => {
          return (
            <RelatedProductItem
              key={x}
              product={product}
              mainProduct={mainProduct}
            />
          );
        })}
    </div>
  );
}

export default RelatedProductsSection;
