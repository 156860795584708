import { SET_FILTER_BRANDS_ARRAY, SET_FILTER_CATEGORIES_ARRAY, SET_FILTER_COLORS_ARRAY, SET_FILTER_PRICES_ARRAY, SET_FILTER_SUBCATEGORIES_ARRAY, SET_FILTER_TYPES_ARRAY, SET_SORT_FILTER } from "./types";

export function filterReducer(state, action) {
    let newState;
    switch (action.type) {
        case SET_FILTER_COLORS_ARRAY:
            newState = { ...state, colorFiltersArray: action.payload };
            break;
        case SET_FILTER_BRANDS_ARRAY:
            newState = { ...state, brandFiltersArray: action.payload };
            break;
        case SET_FILTER_TYPES_ARRAY:
            newState = { ...state, typeFiltersArray: action.payload };
            break;
        case SET_FILTER_CATEGORIES_ARRAY:
            newState = { ...state, categoryFiltersArray: action.payload };
            break;
        case SET_FILTER_SUBCATEGORIES_ARRAY:
            newState = { ...state, subcategoryFiltersArray: action.payload };
            break;
        case SET_FILTER_PRICES_ARRAY:
            newState = { ...state, priceFiltersArray: action.payload };
            break;
        case SET_SORT_FILTER:
            newState = { ...state, productSortFilter: action.payload };
            break;
        default:
            newState = { ...state };
            break;
    }
    return newState;
}