import React from "react";
import ProductItemMiniLoader from "./ProductItemMiniLoader";

function ProductItemMiniLoaders({ number = 3 }) {
  const getLoaders = () => {
    const loaders = [];
    for (let i = 1; i <= number; i++) {
      loaders.push(<ProductItemMiniLoader />);
    }

    return loaders;
  };
  return (
    <div
      className={`grid grid-cols-${number} max-lg:grid-cols-2 max-md:grid-cols-1 gap-4`}
    >
      {getLoaders()}
    </div>
  );
}

export default ProductItemMiniLoaders;
