import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { PRODUCTS_QUERY_KEY } from '../constants/queryKeys';
import { fetchProducts } from '../services/products-services';

function useProducts() {
    const navigate = useNavigate()
    const { data: products, isLoading, error } = useQuery({
        queryKey: [PRODUCTS_QUERY_KEY],
        queryFn: async () => {
            const result = await fetchProducts()
            return result?.data?.data
        },
        onError: (error) => {
            // return error
        }
    })
    return {
        products, isLoading, error
    }
}

export default useProducts