import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { fetchUserBuyNow } from "../services/buynow-services";

function useUserBuyNow(details) {
  const navigate = useNavigate();
  const {
    data: userBuyNowItem,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["user-buy-now", details],
    queryFn: async () => {
      const result = await fetchUserBuyNow(details);
      return result?.data?.data;
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    userBuyNowItem,
    isLoading,
    error,
  };
}

export default useUserBuyNow;
