import React, { useState } from "react";
import CardTitle from "../../commons/CardTitle";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Formik, Form } from "formik";
import CustomTextField from "../../commons/CustomTextField";
import FullRoundedButton from "../../commons/FullRoundedButton";
import CustomSnackBar from "../../commons/CustomSnackBar";
import useUser from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import useDeleteAccount from "../../hooks/useDeleteAccount";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";

function AccountDelete({ onBackClick }) {
  const navigate = useNavigate();

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const { user } = useUser();
  const { deleteUserAccount } = useDeleteAccount();

  return (
    <div className="shadow-md rounded-lg w-[100%] bg-white p-6 text-left">
      <div className="flex justify-between items-start">
        <div className="">
          <CardTitle title="Delete Account" />
        </div>
        <div
          onClick={onBackClick}
          className=" flex gap-2 text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[600] items-center text-[#318EC9] cursor-pointer"
        >
          <div>
            <MdOutlineArrowBackIos />
          </div>
          <div>Back</div>
        </div>
      </div>
      <div>
        <p className="text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[500] my-4">
          Deleting your account will remove all your information from our
          database. This cannot be undone.
          <p className="text-[#828282] text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[500] text-left my-3">
            To confirm this, type "DELETE"
          </p>
        </p>
      </div>

      {/* Form */}
      <div>
        <Formik
          initialValues={{
            deleteText: "",
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            deleteUserAccount({
              deleteText: values.deleteText,
              email: user?.email,
              guid: user?.guid,
              id: user?.id,
            })
              .then((res) => {
                setIsError(false);
                resetForm();
              })
              .catch((err) => {
                setSnackBarMessage(err?.message);
                setIsError(true);
                setOpenSnackBar(true);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
          validate={(values) => {
            const errors = {};
            if (values.deleteText !== "DELETE") {
              errors.deleteText = "Text must match 'DELETE'";
            }

            return errors;
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <CustomTextField
                type="text"
                name="deleteText"
                label=""
                placeholder=""
                rows={2}
              />
              <div className="text-right flex justify-end gap-3">
                <FullRoundedButton
                  label="Cancel"
                  type="button"
                  className="bg-[#D0D5DD]"
                  onClick={onBackClick}
                />
                <FullRoundedButton
                  label={isSubmitting ? "" : "Delete"}
                  type="submit"
                  className="bg-[#FF0000] text-white hover:opacity-70"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <CustomThreeDotsSpinner
                      height="20"
                      width="20"
                      color="white"
                    />
                  )}
                </FullRoundedButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mb-10"></div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default AccountDelete;
