import React, { useReducer, useState } from "react";
import Layout from "../../commons/Layout";
import SaleHero from "./SaleHero";
import SubCategorySection from "./CategorySection";
import useProductCategories from "../../hooks/useProductCategories";
import useFilterProducts from "../../hooks/useFilterProducts";
import ContentBodySection from "./ContentBodySection";
import { NEW_TO_OLD } from "../../constants/texts";
import { filterReducer } from "../../reducers/productFilterReducer";
import useDebounce from "../../hooks/useDebounce";
import useColors from "../../hooks/useColors";
import useVendors from "../../hooks/useVendors";
import useTypes from "../../hooks/useTypes";
import useProductSubcategories from "../../hooks/useProductSubcategories";
import { FaFilter } from "react-icons/fa";

function Sale() {
  const [pageSize, setPageSize] = useState(6);
  const [pageNumber, setPageNumber] = useState(1);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const { productCategories, isLoading } = useProductCategories();
  const initialState = {
    categoryFiltersArray: [],
    subcategoryFiltersArray: [],
    typeFiltersArray: [],
    colorFiltersArray: [],
    brandFiltersArray: [],
    priceFiltersArray: [0, 100],
    productSortFilter: NEW_TO_OLD,
  };

  const { colors } = useColors();
  const { vendors } = useVendors();
  const { types } = useTypes();
  const { productSubcategories } = useProductSubcategories();

  const [state, dispatch] = useReducer(filterReducer, initialState);
  const deboundedState = useDebounce(state, 1000);

  const { products: response } = useFilterProducts(
    pageSize,
    pageNumber,
    deboundedState.categoryFiltersArray,
    deboundedState.subcategoryFiltersArray,
    deboundedState.typeFiltersArray,
    deboundedState.brandFiltersArray,
    deboundedState.colorFiltersArray,
    deboundedState.priceFiltersArray,
    deboundedState.productSortFilter
  );

  const products = response?.records;

  return (
    <Layout>
      <div>
        <SaleHero />
        <SubCategorySection
          isLoading={isLoading}
          categories={productCategories}
          filters={state}
          dispatch={dispatch}
        />
        <div className="hidden max-sm:flex justify-end w-[95%] mx-auto mt-8">
          <div
            onClick={() => setIsFilterModalOpen((prev) => !prev)}
            className="flex items-center gap-2 w-fit border border-gray-400 rounded-md px-4 py-1 text-[14px] max-sm:text-[12px]"
          >
            <FaFilter />
            <p>Filters</p>
          </div>
        </div>
        <ContentBodySection
          isLoading={isLoading}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          dispatch={dispatch}
          filters={state}
          brandList={vendors ?? []}
          colorList={colors ?? []}
          typeList={types ?? []}
          categoryList={productCategories ?? []}
          subcategoryList={productSubcategories ?? []}
          products={products}
          totalRecord={response?.totalRecord}
          priceMax={response.priceMax ?? 100000}
          isFilterModalOpen={isFilterModalOpen}
          setIsFilterModalOpen={setIsFilterModalOpen}
        />
      </div>
    </Layout>
  );
}

export default Sale;
